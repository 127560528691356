import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import {Grid} from '@mui/material';
import { Link } from '@mui/material';

export default function Confirmed() {
    return(
        <Grid container flexDirection={'column'} xs={'auto'} item paddingX={"2em"} gap={'2em'}>
            <img width={'250em'} src={'/pp_logo.png'} alt={"PeoplePartners"} style={{marginBottom: "1em"}}/> 
            <div>
                <Typography gutterBottom variant='h6'>
                Başvurunuz alındı.
                </Typography>
                <Typography mt={'1.5em'} fontSize={'0.8em'} gutterBottom>
                    Yaz dönemi için staj başvurunuz tarafımıza ulaşmıştır.
                    Başvurunuzun sonucu iki gün içinde e-posta adresinize gönderilecektir. 
                </Typography>
                <Grid container display={'grid'}>
                    <Typography mt={'1.5em'} fontSize={'0.8em'} mr={'1em'}>
                        Siemens San. ve Tic. A.Ş.
                    </Typography>
                    <Typography fontSize={'0.8em'} mr={'1em'}>
                        İnsan ve Organizasyon Bölümü
                    </Typography>
                </Grid>
            </div>
        </Grid>
    )
}