const MYODepartments = [
    "Bankacılık ve Sigortacılık",
    "Bilgi Yönetimi",
    "Bilgisayar Programcılığı",
    "Büro Yönetimi ve Yönetici Asistanlığı",
    "İşletme Yönetimi",
    "Mekatronik",
    "Muhasebe ve Vergi Uygulamaları",
    "İş Sağlığı ve Güvenliği",
    "Lojistik",
    "Yerel Yönetimler",
    "Bahçe Tarımı",
    "Harita ve Kadastro",
    "Laborant ve Veteriner Sağlık",
    "Tarla Bitkileri",
    "Turizm ve Seyahat Hizmetleri",
    "Çocuk Gelişimi",
    "İlk ve Acil Yardım",
    "Optisyenlik",
    "Patoloji Laboratuvar Teknikleri",
    "Tıbbi Laboratuvar Teknikleri",
    "Aşçılık",
    "Dış Ticaret",
    "Kooperatifçilik",
    "Özel Güvenlik ve Koruma",
    "Pazarlama",
    "Turizm ve Otel İşletmeciliği",
    "Elektrik",
    "Gıda Teknolojisi",
    "İnşaat Teknolojisi",
    "Kimya Teknolojisi",
    "Makine",
    "Mimari Dekoratif Sanatlar",
    "Mobilya ve Dekorasyon",
    "Sondaj Teknolojisi",
    "Aşçılık (İÖ)",
    "Biyomedikal Cihaz Teknolojisi",
    "Biyomedikal Cihaz Teknolojisi (İÖ)",
    "Büro Yönetimi ve Yönetici Asistanlığı (İÖ)",
    "Çocuk Gelişimi (İÖ)",
    "Elektrik (İÖ)",
    "Elektronik Teknolojisi",
    "Hibrid ve Elektrikli Taşıtlar Teknolojisi",
    "İç Mekan Tasarımı",
    "İç Mekan Tasarımı (İÖ)",
    "İnşaat Teknolojisi (İÖ)",
    "Mermer Teknolojisi",
    "Mimari Restorasyon",
    "Mimari Restorasyon (İÖ)",
    "Muhasebe ve Vergi Uygulamaları (İÖ)",
    "Radyo ve Televizyon Programcılığı",
    "Raylı Sistemler Yol Teknolojisi",
    "İnsan Kaynakları Yönetimi",
    "Laborant ve Veteriner Sağlık (İÖ)",
    "Çağrı Merkezi Hizmetleri",
    "Doğalgaz ve Tesisatı Teknolojisi",
    "Otomotiv Teknolojisi",
    "Ceza İnfaz ve Güvenlik Hizmetleri",
    "Harita ve Kadastro (İÖ)",
    "Yapı Denetimi",
    "İş Makineleri Operatörlüğü",
    "Kuyumculuk ve Takı Tasarımı",
    "Makine Resim ve Konstrüksiyonu",
    "Halkla İlişkiler ve Tanıtım",
    "Sağlık Turizmi İşletmeciliği",
    "Seracılık",
    "İnternet ve Ağ Teknolojileri",
    "Reklamcılık",
    "Bilgisayar Programcılığı (Uzaktan Öğretim)",
    "Bilgisayar Teknolojisi (Uzaktan Öğretim)",
    "Harita ve Kadastro (Uzaktan Öğretim)",
    "Ağız ve Diş Sağlığı",
    "Diyaliz",
    "Elektronörofizyoloji",
    "Fizyoterapi",
    "Fizyoterapi (İÖ)",
    "Hasta Bakımı",
    "Ortopedik Protez ve Ortez",
    "Sağlık Kurumları İşletmeciliği",
    "Sağlık Kurumları İşletmeciliği (İÖ)",
    "Tıbbi Dokümantasyon ve Sekreterlik",
    "Tıbbi Dokümantasyon ve Sekreterlik (İÖ)",
    "Tıbbi Görüntüleme Teknikleri",
    "Tıbbi Laboratuvar Teknikleri (İÖ)",
    "Tıbbi ve Aromatik Bitkiler",
    "Yaşlı Bakımı",
    "Evde Hasta Bakımı",
    "Geleneksel El Sanatları",
    "Grafik Tasarımı",
    "Nükleer Teknoloji ve Radyasyon Güvenliği",
    "Organik Tarım",
    "Posta Hizmetleri",
    "Eczane Hizmetleri",
    "Sivil Savunma ve İtfaiyecilik",
    "Engelli Bakımı ve Rehabilitasyon",
    "Engelli Bakımı ve Rehabilitasyon (İÖ)",
    "İlk ve Acil Yardım (İÖ)",
    "Yaşlı Bakımı (İÖ)",
    "Sivil Hava Ulaştırma İşletmeciliği",
    "Sivil Havacılık Kabin Hizmetleri",
    "Adalet",
    "Turist Rehberliği",
    "Bilgisayar Programcılığı (KKTC Uyruklu)",
    "Tarım Makineleri",
    "Deniz ve Liman İşletmeciliği",
    "Meyve ve Sebze İşleme Teknolojisi",
    "İkram Hizmetleri",
    "Pastacılık ve Ekmekçilik",
    "Elektronik Haberleşme Teknolojisi",
    "Maliye",
    "Mantarcılık",
    "Bilgisayar Programcılığı (İÖ)",
    "İşletme Yönetimi (İÖ)",
    "Turizm ve Otel İşletmeciliği (İÖ)",
    "İlk ve Acil Yardım (KKTC Uyruklu)",
    "Radyoterapi",
    "Çim Alan Tesisi ve Yönetimi",
    "Moda Tasarımı",
    "Peyzaj ve Süs Bitkileri Yetiştiriciliği",
    "Sahne ve Dekor Tasarımı",
    "Tekstil Teknolojisi",
    "Bankacılık ve Sigortacılık (İÖ)",
    "Çağrı Merkezi Hizmetleri (İÖ)",
    "Halkla İlişkiler ve Tanıtım (İÖ)",
    "Medya ve İletişim",
    "Medya ve İletişim (İÖ)",
    "Özel Güvenlik ve Koruma (İÖ)",
    "Pazarlama (İÖ)",
    "Sivil Hava Ulaştırma İşletmeciliği (İÖ)",
    "Sosyal Hizmetler",
    "Sosyal Hizmetler (İÖ)",
    "Turizm ve Otel İşletmeciliği (Uzaktan Öğretim)",
    "Turizm ve Seyahat Hizmetleri (İÖ)",
    "Coğrafi Bilgi Sistemleri",
    "Çevre Koruma ve Kontrol",
    "İklimlendirme ve Soğutma Teknolojisi",
    "İklimlendirme ve Soğutma Teknolojisi (KKTC Uyruklu)",
    "Kontrol ve Otomasyon Teknolojisi",
    "Nükleer Teknoloji ve Radyasyon Güvenliği (İÖ)",
    "Sivil Savunma ve İtfaiyecilik (KKTC Uyruklu)",
    "Yapı Denetimi (İÖ)",
    "Alternatif Enerji Kaynakları Teknolojisi",
    "Geleneksel Tekstillerin Korunması ve Restorasyonu",
    "Süt ve Ürünleri Teknolojisi",
    "Bitki Koruma",
    "Sosyal Güvenlik",
    "Ormancılık ve Orman Ürünleri",
    "Tapu ve Kadastro",
    "Emlak Yönetimi",
    "Emlak Yönetimi (İÖ)",
    "Turizm Animasyonu",
    "Elektrik (Uzaktan Öğretim)",
    "Mekatronik (Uzaktan Öğretim)",
    "Sağlık Bilgi Sistemleri Teknikerliği",
    "Basım ve Yayım Teknolojileri",
    "Çocuk Gelişimi (Uzaktan Öğretim)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Uzaktan Öğretim)",
    "Acil Durum ve Afet Yönetimi",
    "Gıda Kalite Kontrolü ve Analizi",
    "Sivil Savunma ve İtfaiyecilik (İÖ)",
    "Bilgisayar Destekli Tasarım ve Animasyon",
    "Grafik Tasarımı (İÖ)",
    "Raylı Sistemler Elektrik ve Elektronik",
    "Raylı Sistemler İşletmeciliği",
    "Raylı Sistemler Makine Teknolojisi",
    "Ulaştırma ve Trafik Hizmetleri",
    "Acil Durum ve Afet Yönetimi (Açıköğretim)",
    "Aşçılık (Açıköğretim)",
    "Bankacılık ve Sigortacılık (Açıköğretim)",
    "Bilgisayar Programcılığı (Açıköğretim)",
    "Büro Yönetimi ve Yönetici Asistanlığı (Açıköğretim)",
    "Coğrafi Bilgi Sistemleri (Açıköğretim)",
    "Çağrı Merkezi Hizmetleri (Açıköğretim)",
    "Çocuk Gelişimi (Açıköğretim)",
    "Dış Ticaret (Açıköğretim)",
    "Elektrik Enerjisi Üretim, İletim ve Dağıtımı (Açıköğretim)",
    "Emlak Yönetimi (Açıköğretim)",
    "Engelli Bakımı ve Rehabilitasyon (Açıköğretim)",
    "Ev İdaresi (Açıköğretim)",
    "Fotoğrafçılık ve Kameramanlık (Açıköğretim)",
    "Halkla İlişkiler ve Tanıtım (Açıköğretim)",
    "İlahiyat (Önlisans) (Açıköğretim)",
    "İlahiyat (Arapça) (Önlisans) (Açıköğretim)",
    "İnsan Kaynakları Yönetimi (Açıköğretim)",
    "İş Sağlığı ve Güvenliği (Açıköğretim)",
    "İşletme Yönetimi (Açıköğretim)",
    "Kültürel Miras ve Turizm (Açıköğretim)",
    "Laborant ve Veteriner Sağlık (Açıköğretim)",
    "Lojistik (Açıköğretim)",
    "Marka İletişimi (Açıköğretim)",
    "Medya ve İletişim (Açıköğretim)",
    "Menkul Kıymetler ve Sermaye Piyasası (Açıköğretim)",
    "Muhasebe ve Vergi Uygulamaları (Açıköğretim)",
    "Perakende Satış ve Mağaza Yönetimi (Açıköğretim)",
    "Radyo ve Televizyon Programcılığı (Açıköğretim)",
    "Sağlık Kurumları İşletmeciliği (Açıköğretim)",
    "Sivil Hava Ulaştırma İşletmeciliği (Açıköğretim)",
    "Sosyal Hizmetler (Açıköğretim)",
    "Sosyal Medya Yöneticiliği (Açıköğretim)",
    "Spor Yönetimi (Açıköğretim)",
    "Tarım Teknolojisi (Açıköğretim)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Açıköğretim)",
    "Turizm ve Otel İşletmeciliği (Açıköğretim)",
    "Web Tasarımı ve Kodlama (Açıköğretim)",
    "Yaşlı Bakımı (Açıköğretim)",
    "Yerel Yönetimler (Açıköğretim)",
    "Bilgisayar Operatörlüğü",
    "Yapı Ressamlığı",
    "Saç Bakımı ve Güzellik Hizmetleri",
    "Giyim Üretim Teknolojisi",
    "Dijital Fabrika Teknolojileri",
    "Acil Durum ve Afet Yönetimi (Uzaktan Öğretim)",
    "Bankacılık ve Sigortacılık (Uzaktan Öğretim)",
    "Bilgi Yönetimi (Açıköğretim)",
    "Büro Yönetimi ve Yönetici Asistanlığı (Uzaktan Öğretim)",
    "Elektronik Teknolojisi (Uzaktan Öğretim)",
    "Hukuk Büro Yönetimi ve Sekreterliği (Uzaktan Öğretim)",
    "İnternet ve Ağ Teknolojileri (Uzaktan Öğretim)",
    "Muhasebe ve Vergi Uygulamaları (Uzaktan Öğretim)",
    "Sosyal Güvenlik (Uzaktan Öğretim)",
    "Sosyal Güvenlik (KKTC Uyruklu)",
    "Süt ve Besi Hayvancılığı",
    "Ayakkabı Tasarım ve Üretimi",
    "Alternatif Enerji Kaynakları Teknolojisi (İÖ)",
    "Hukuk Büro Yönetimi ve Sekreterliği",
    "Bağcılık ve Bağ Ürünleri Teknolojisi",
    "İş ve Uğraşı Terapisi",
    "Podoloji",
    "Ağız ve Diş Sağlığı (KKTC Uyruklu)",
    "Anestezi",
    "Siber Güvenlik Analistliği ve Operatörlüğü",
    "Atçılık ve Antrenörlüğü",
    "Çocuk Koruma ve Bakım Hizmetleri",
    "Çok Boyutlu Modelleme ve Animasyon",
    "Uçak Teknolojisi",
    "Sahne Işık ve Ses Teknolojileri",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce)",
    "Spor Yönetimi",
    "Avcılık ve Yaban Hayatı",
    "Laboratuvar Teknolojisi",
    "Ormancılık ve Orman Ürünleri (İÖ)",
    "Elektrik Enerjisi Üretim, İletim ve Dağıtımı",
    "Eczane Hizmetleri (İÖ)",
    "İslami İlimler (Önlisans) (Açıköğretim)",
    "Reklamcılık (Açıköğretim)",
    "Yeni Medya ve Gazetecilik (Açıköğretim)",
    "Ameliyathane Hizmetleri",
    "Diş Protez Teknolojisi",
    "Odyometri",
    "Dış Ticaret (İÖ)",
    "Radyo ve Televizyon Programcılığı (İÖ)",
    "Geleneksel El Sanatları (İÖ)",
    "İş Sağlığı ve Güvenliği (İÖ)",
    "Tapu ve Kadastro (İÖ)",
    "Tarımsal İşletmecilik",
    "Anestezi (KKTC Uyruklu)",
    "Çevre Sağlığı",
    "Diyaliz (KKTC Uyruklu)",
    "Polimer Teknolojisi",
    "Aşçılık (KKTC Uyruklu)",
    "Üretimde Kalite Kontrol",
    "Lojistik (İÖ)",
    "Menkul Kıymetler ve Sermaye Piyasası",
    "Evde Hasta Bakımı (İÖ)",
    "Mekatronik (KKTC Uyruklu)",
    "Moda Tasarımı (İÖ)",
    "Otomotiv Teknolojisi (İÖ)",
    "İnsan Kaynakları Yönetimi (İÖ)",
    "Saç Bakımı ve Güzellik Hizmetleri (İÖ)",
    "Maliye (İÖ)",
    "Madencilik Teknolojisi",
    "Zeytincilik ve Zeytin İşleme Teknolojisi",
    "Elektrikli Cihaz Teknolojisi",
    "Et ve Ürünleri Teknolojisi",
    "Gıda Teknolojisi (İÖ)",
    "Gemi İnşaatı",
    "Gemi İnşaatı (İÖ)",
    "Su Altı Teknolojisi",
    "Bilişim Güvenliği Teknolojisi",
    "Web Tasarımı ve Kodlama",
    "Sosyal Güvenlik (İÖ)",
    "Marina ve Yat İşletmeciliği",
    "Optisyenlik (İÖ)",
    "Tıbbi Tanıtım ve Pazarlama",
    "Tıbbi Tanıtım ve Pazarlama (İÖ)",
    "Eser Koruma",
    "Bilgisayar Teknolojisi",
    "Rafineri ve Petro-Kimya Teknolojisi",
    "Fotoğrafçılık ve Kameramanlık",
    "Metalurji",
    "Endüstri Ürünleri Tasarımı",
    "E-Ticaret ve Pazarlama",
    "Halıcılık ve Kilimcilik",
    "Arıcılık",
    "Sulama Teknolojisi",
    "Patoloji Laboratuvar Teknikleri (İÖ)",
    "Seramik ve Cam Tasarımı",
    "Deri Teknolojisi",
    "Kümes Hayvanları Yetiştiriciliği",
    "Anestezi (İÖ)",
    "Fizyoterapi (KKTC Uyruklu)",
    "Nüfus ve Vatandaşlık",
    "Çini Sanatı ve Tasarımı",
    "Tohumculuk Teknolojisi",
    "Yerel Yönetimler (İÖ)",
    "Elektronik Teknolojisi (İÖ)",
    "Endüstriyel Kalıpçılık",
    "Endüstriyel Kalıpçılık (İÖ)",
    "İş Sağlığı ve Güvenliği (KKTC Uyruklu)",
    "Makine (İÖ)",
    "Mekatronik (İÖ)",
    "Peyzaj ve Süs Bitkileri Yetiştiriciliği (KKTC Uyruklu)",
    "Süt ve Ürünleri Teknolojisi (İÖ)",
    "Tekstil Teknolojisi (İÖ)",
    "Spor Yönetimi (İÖ)",
    "Turist Rehberliği (İÖ)",
    "Enerji Tesisleri İşletmeciliği",
    "Biyokimya",
    "Fidan Yetiştiriciliği",
    "Fidan Yetiştiriciliği (KKTC Uyruklu)",
    "Laboratuvar Teknolojisi (İÖ)",
    "Endüstriyel Cam ve Seramik",
    "Elektronik Haberleşme Teknolojisi (Uzaktan Öğretim)",
    "Doğal Yapı Taşları Teknolojisi",
    "Kültürel Miras ve Turizm",
    "Sivil Hava Ulaştırma İşletmeciliği (KKTC Uyruklu)",
    "Biyomedikal Cihaz Teknolojisi (KKTC Uyruklu)",
    "Döküm",
    "Elektrik (KKTC Uyruklu)",
    "Elektronik Haberleşme Teknolojisi (İÖ)",
    "Kimya Teknolojisi (İÖ)",
    "Kuyumculuk ve Takı Tasarımı (İÖ)",
    "Makine Resim ve Konstrüksiyonu (İÖ)",
    "Tarımsal İşletmecilik (İÖ)",
    "Yapı Tesisat Teknolojisi",
    "Yapı Tesisat Teknolojisi (KKTC Uyruklu)",
    "Nükleer Tıp Teknikleri",
    "Nükleer Tıp Teknikleri (KKTC Uyruklu)",
    "Tıbbi Görüntüleme Teknikleri (KKTC Uyruklu)",
    "Geoteknik",
    "Kaynak Teknolojisi",
    "Eczane Hizmetleri (Uzaktan Öğretim)",
    "Engelli Bakımı ve Rehabilitasyon (Uzaktan Öğretim)",
    "Mekatronik (Kız)",
    "Mekatronik (Erkek)",
    "Muhasebe ve Vergi Uygulamaları (İÖ) (Bk.35)",
    "Deniz Ulaştırma ve İşletme",
    "Raylı Sistemler Makinistliği",
    "Raylı Sistemler İşletmeciliği (Uzaktan Öğretim)",
    "Ortopedik Protez ve Ortez (KKTC Uyruklu)",
    "İnsansız Hava Aracı Teknolojisi ve Operatörlüğü",
    "Radyo ve Televizyon Teknolojisi",
    "Otopsi Yardımcılığı",
    "Tıbbi Görüntüleme Teknikleri (İÖ)",
    "Deniz Ulaştırma ve İşletme (İÖ) (Bk.37)",
    "Gemi Makineleri İşletmeciliği (İÖ) (Bk.37)",
    "Patoloji Laboratuvar Teknikleri (KKTC Uyruklu)",
    "Kaynak Teknolojisi (KKTC Uyruklu)",
    "Tekstil ve Halı Makineleri",
    "Un ve Unlu Mamuller Teknolojisi",
    "Fındık Eksperliği",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (İÖ)",
    "Diyaliz (İÖ)",
    "Sivil Havacılık Kabin Hizmetleri (İÖ)",
    "Dezenfeksiyon, Sterilizasyon ve Antisepsi Teknikerliği",
    "Odyometri (KKTC Uyruklu)",
    "Kozmetik Teknolojisi",
    "Silah Sanayi Teknikerliği",
    "Harita ve Kadastro (KKTC Uyruklu)",
    "Çağrı Merkezi Hizmetleri (Uzaktan Öğretim)",
    "Sağlık Bilgi Sistemleri Teknikerliği (Uzaktan Öğretim)",
    "Deniz ve Liman İşletmeciliği (İÖ)",
    "Coğrafi Bilgi Sistemleri (Uzaktan Öğretim)",
    "Dış Ticaret (Uzaktan Öğretim)",
    "E-Ticaret ve Pazarlama (Açıköğretim)",
    "Grafik Tasarımı (Açıköğretim)",
    "Hukuk Büro Yönetimi ve Sekreterliği (Açıköğretim)",
    "İlahiyat (Açıköğretim)",
    "İş Sağlığı ve Güvenliği (Uzaktan Öğretim)",
    "Medya ve İletişim (Uzaktan Öğretim)",
    "Emlak Yönetimi (Uzaktan Öğretim)",
    "İşletme Yönetimi (Uzaktan Öğretim)",
    "Pazarlama (Uzaktan Öğretim)",
    "Grafik Tasarımı (KKTC Uyruklu)",
    "Sanal ve Artırılmış Gerçeklik",
    "Raylı Sistemler Elektrik ve Elektronik (İÖ)",
    "Raylı Sistemler Makinistliği (İÖ)",
    "Ağız ve Diş Sağlığı (İÖ)",
    "Otobüs Kaptanlığı",
    "Gemi Makineleri İşletmeciliği",
    "Atık Yönetimi",
    "Ameliyathane Hizmetleri (İÖ)",
    "Gıda Teknolojisi (KKTC Uyruklu)",
    "Radyo ve Televizyon Teknolojisi (İÖ)",
    "Bağcılık",
    "Geleneksel El Sanatları (İÖ) (Bk.51)",
    "İnşaat Teknolojisi (KKTC Uyruklu)",
    "Kontrol ve Otomasyon Teknolojisi (Uzaktan Öğretim)",
    "Görsel İletişim",
    "Web Tasarımı ve Kodlama (KKTC Uyruklu)",
    "Turizm ve Otel İşletmeciliği (KKTC Uyruklu)",
    "Otomotiv Gövde ve Yüzey İşlem Teknolojileri",
    "Boya Teknolojisi",
    "Çevre Temizliği ve Denetimi",
    "Özel Güvenlik ve Koruma (Kız)",
    "Özel Güvenlik ve Koruma (Erkek)",
    "Özel Güvenlik ve Koruma (Kız) (İÖ)",
    "Özel Güvenlik ve Koruma (Erkek) (İÖ)",
    "Bankacılık ve Sigortacılık (KKTC Uyruklu)",
    "Deniz Ulaştırma ve İşletme (KKTC Uyruklu)",
    "Deniz ve Liman İşletmeciliği (KKTC Uyruklu)",
    "Kontrol ve Otomasyon Teknolojisi (İÖ)",
    "Kontrol ve Otomasyon Teknolojisi (KKTC Uyruklu)",
    "Podoloji (KKTC Uyruklu)",
    "Yapı Yalıtım Teknolojisi",
    "Bilgisayar Operatörlüğü (İÖ)",
    "İnsan Kaynakları Yönetimi (Uzaktan Öğretim)",
    "Dış Ticaret (KKTC Uyruklu)",
    "Sosyal Hizmetler (KKTC Uyruklu)",
    "Saç Bakımı ve Güzellik Hizmetleri (KKTC Uyruklu)",
    "Sosyal Hizmetler (Uzaktan Öğretim)",
    "Diş Protez Teknolojisi (KKTC Uyruklu)",
    "Moda Tasarımı (Uzaktan Öğretim)",
    "Deniz Ulaştırma ve İşletme (İÖ)",
    "Halkla İlişkiler ve Tanıtım (Uzaktan Öğretim)",
    "Ormancılık ve Orman Ürünleri (Uzaktan Öğretim)",
    "Sağlık Kurumları İşletmeciliği (Uzaktan Öğretim)",
    "Tıbbi Laboratuvar Teknikleri (KKTC Uyruklu)",
    "Mobilya ve Dekorasyon (KKTC Uyruklu)",
    "Optisyenlik (KKTC Uyruklu)",
    "Saç Bakımı ve Güzellik Hizmetleri (İÖ) (Bk.52)",
    "Yat Kaptanlığı",
    "Ormancılık ve Orman Ürünleri (KKTC Uyruklu)",
    "Turist Rehberliği (Uzaktan Öğretim)",
    "CNC Programlama ve Operatörlüğü",
    "İş ve Uğraşı Terapisi (İÖ)",
    "Posta Hizmetleri (Uzaktan Öğretim)",
    "Kenevir Dokuma Tezgahtarlığı",
    "Posta Hizmetleri (İÖ)",
    "Maliye (KKTC Uyruklu)",
    "Moda Tasarımı (KKTC Uyruklu)",
    "Otomotiv Teknolojisi (KKTC Uyruklu)",
    "Alternatif Enerji Kaynakları Teknolojisi (KKTC Uyruklu)",
    "Bıçakçılık ve El Aletleri Üretim Teknolojisi",
    "Çay Tarımı ve İşleme Teknolojisi",
    "Ağız ve Diş Sağlığı (Ankara)",
    "Ameliyathane Hizmetleri (Ankara)",
    "Anestezi (Ankara)",
    "Diş Protez Teknolojisi (Ankara)",
    "Eczane Hizmetleri (Ankara)",
    "Elektronörofizyoloji (Ankara)",
    "İlk ve Acil Yardım (Ankara)",
    "Ortopedik Protez ve Ortez (Ankara)",
    "Patoloji Laboratuvar Teknikleri (Ankara)",
    "Sağlık Kurumları İşletmeciliği (Ankara)",
    "Tıbbi Görüntüleme Teknikleri (Ankara)",
    "Tıbbi Laboratuvar Teknikleri (Ankara)",
    "Yaşlı Bakımı (Ankara)",
    "Moda Yönetimi",
    "Basım ve Yayım Teknolojileri (İÖ)",
    "Perakende Satış ve Mağaza Yönetimi",
    "Web Tasarımı ve Kodlama (Uzaktan Öğretim)",
    "Şarap Üretim Teknolojisi",
    "Yağ Endüstrisi",
    "Mobil Teknolojileri",
    "Radyoterapi (KKTC Uyruklu)",
    "Su Ürünleri İşleme Teknolojisi",
    "Marina ve Yat İşletmeciliği (İÖ)",
    "Sivil Savunma ve İtfaiyecilik (Kız)",
    "Sivil Savunma ve İtfaiyecilik (Erkek)",
    "Açık Deniz Sondaj Teknolojisi",
    "Açık Deniz Tabanı Uygulamaları Teknolojisi",
    "Bilgisayar Teknolojisi (İÖ)",
    "Ameliyathane Hizmetleri (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
    "Anestezi (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
    "Eczane Hizmetleri (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
    "İlk ve Acil Yardım (Ankara) (Kız) (İçişleri Bakanlığı Adına)",
    "İlk ve Acil Yardım (Erkek) (Ankara) (İçişleri Bakanlığı Adına)",
    "İlk ve Acil Yardım (Erkek) (Ankara) (Milli Savunma Bakanlığı Adına)",
    "Tıbbi Görüntüleme Teknikleri (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
    "Tıbbi Laboratuvar Teknikleri (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
    "İlk ve Acil Yardım (Erkek) (Milli Savunma Bakanlığı Adına)",
    "Bilgisayar Programcılığı (Uzaktan Öğretim) (Ücretli)",
    "*Uluslararası anlaşmalar ile kurulmuş olan üniversite, özerk statüye sahip ortak devlet üniversitesidir. Eğitim, yurt dışında üniversitenin bulunduğu ülkede sürdürülecektir.",
    "Aşçılık (Burslu)",
    "Aşçılık (%50 İndirimli)",
    "Bilgisayar Programcılığı (Burslu)",
    "Bilgisayar Programcılığı (%50 İndirimli)",
    "Biyomedikal Cihaz Teknolojisi (Burslu)",
    "Biyomedikal Cihaz Teknolojisi (%50 İndirimli)",
    "Ağız ve Diş Sağlığı (Burslu)",
    "Ağız ve Diş Sağlığı (%50 İndirimli)",
    "Ağız ve Diş Sağlığı (İÖ) (Burslu)",
    "Ağız ve Diş Sağlığı (İÖ) (%50 İndirimli)",
    "Ameliyathane Hizmetleri (Burslu)",
    "Ameliyathane Hizmetleri (%50 İndirimli)",
    "Anestezi (Burslu)",
    "Anestezi (%50 İndirimli)",
    "Anestezi (İÖ) (Burslu)",
    "Anestezi (İÖ) (%50 İndirimli)",
    "Diyaliz (Burslu)",
    "Diyaliz (%50 İndirimli)",
    "Elektronörofizyoloji (Burslu)",
    "Elektronörofizyoloji (%50 İndirimli)",
    "Fizyoterapi (Burslu)",
    "Fizyoterapi (%50 İndirimli)",
    "İlk ve Acil Yardım (Burslu)",
    "İlk ve Acil Yardım (%50 İndirimli)",
    "İlk ve Acil Yardım (İÖ) (Burslu)",
    "İlk ve Acil Yardım (İÖ) (%50 İndirimli)",
    "Odyometri (Burslu)",
    "Odyometri (%50 İndirimli)",
    "Optisyenlik (Burslu)",
    "Optisyenlik (%50 İndirimli)",
    "Ortopedik Protez ve Ortez (Burslu)",
    "Ortopedik Protez ve Ortez (%50 İndirimli)",
    "Patoloji Laboratuvar Teknikleri (Burslu)",
    "Patoloji Laboratuvar Teknikleri (%50 İndirimli)",
    "Patoloji Laboratuvar Teknikleri (İÖ) (Burslu)",
    "Patoloji Laboratuvar Teknikleri (İÖ) (%50 İndirimli)",
    "Podoloji (Burslu)",
    "Podoloji (%50 İndirimli)",
    "Podoloji (İÖ) (Burslu)",
    "Podoloji (İÖ) (%50 İndirimli)",
    "Radyoterapi (Burslu)",
    "Radyoterapi (%50 İndirimli)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Burslu)",
    "Tıbbi Dokümantasyon ve Sekreterlik (%50 İndirimli)",
    "Tıbbi Dokümantasyon ve Sekreterlik (İÖ) (Burslu)",
    "Tıbbi Dokümantasyon ve Sekreterlik (İÖ) (%50 İndirimli)",
    "Tıbbi Görüntüleme Teknikleri (Burslu)",
    "Tıbbi Görüntüleme Teknikleri (%50 İndirimli)",
    "Tıbbi Görüntüleme Teknikleri (İÖ) (Burslu)",
    "Tıbbi Görüntüleme Teknikleri (İÖ) (%50 İndirimli)",
    "Tıbbi Laboratuvar Teknikleri (Burslu)",
    "Tıbbi Laboratuvar Teknikleri (%50 İndirimli)",
    "Tıbbi Laboratuvar Teknikleri (İÖ) (Burslu)",
    "Tıbbi Laboratuvar Teknikleri (İÖ) (%50 İndirimli)",
    "Anestezi (%25 İndirimli)",
    "Fizyoterapi (%25 İndirimli)",
    "İlk ve Acil Yardım (%25 İndirimli)",
    "İlk ve Acil Yardım (İÖ) (%25 İndirimli)",
    "Bilgisayar Destekli Tasarım ve Animasyon (Burslu)",
    "Bilgisayar Destekli Tasarım ve Animasyon (%50 İndirimli)",
    "Bilişim Güvenliği Teknolojisi (Burslu)",
    "Bilişim Güvenliği Teknolojisi (%50 İndirimli)",
    "E-Ticaret ve Pazarlama (Burslu)",
    "E-Ticaret ve Pazarlama (%50 İndirimli)",
    "İnsansız Hava Aracı Teknolojisi ve Operatörlüğü (Burslu)",
    "İnsansız Hava Aracı Teknolojisi ve Operatörlüğü (%50 İndirimli)",
    "Adalet (Burslu)",
    "Adalet (%50 İndirimli)",
    "İç Mekan Tasarımı (Burslu)",
    "İç Mekan Tasarımı (%50 İndirimli)",
    "İnşaat Teknolojisi (Burslu)",
    "İnşaat Teknolojisi (%50 İndirimli)",
    "Pazarlama (Burslu)",
    "Pazarlama (%50 İndirimli)",
    "Diş Protez Teknolojisi (Burslu)",
    "Diş Protez Teknolojisi (%50 İndirimli)",
    "Sağlık Turizmi İşletmeciliği (Burslu)",
    "Sağlık Turizmi İşletmeciliği (%50 İndirimli)",
    "Bankacılık ve Sigortacılık (Burslu)",
    "Bankacılık ve Sigortacılık (%50 İndirimli)",
    "Eczane Hizmetleri (Burslu)",
    "Eczane Hizmetleri (%50 İndirimli)",
    "Grafik Tasarımı (Burslu)",
    "Grafik Tasarımı (%50 İndirimli)",
    "İç Mekan Tasarımı (Ücretli)",
    "Mimari Restorasyon (Burslu)",
    "Mimari Restorasyon (%50 İndirimli)",
    "Optisyenlik (Ücretli)",
    "Sivil Havacılık Kabin Hizmetleri (Burslu)",
    "Sivil Havacılık Kabin Hizmetleri (%50 İndirimli)",
    "Sosyal Hizmetler (Burslu)",
    "Sosyal Hizmetler (%50 İndirimli)",
    "Tıbbi ve Aromatik Bitkiler (Burslu)",
    "Tıbbi ve Aromatik Bitkiler (%50 İndirimli)",
    "Turist Rehberliği (Burslu)",
    "Turist Rehberliği (%50 İndirimli)",
    "Uçuş Harekat Yöneticiliği (Burslu)",
    "Uçuş Harekat Yöneticiliği (%50 İndirimli)",
    "Mekatronik (Burslu)",
    "Mekatronik (%50 İndirimli)",
    "Optisyenlik (İÖ) (Burslu)",
    "Optisyenlik (İÖ) (%50 İndirimli)",
    "Harita ve Kadastro (Burslu)",
    "Harita ve Kadastro (%50 İndirimli)",
    "Moda Tasarımı (Burslu)",
    "Moda Tasarımı (%50 İndirimli)",
    "Spor Yönetimi (Burslu)",
    "Spor Yönetimi (%50 İndirimli)",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (Burslu)",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (%50 İndirimli)",
    "Anestezi (Ücretli)",
    "Anestezi (İÖ) (Ücretli)",
    "Çocuk Gelişimi (Burslu)",
    "Çocuk Gelişimi (%50 İndirimli)",
    "Çocuk Gelişimi (İÖ) (Burslu)",
    "Çocuk Gelişimi (İÖ) (%50 İndirimli)",
    "İlk ve Acil Yardım (Ücretli)",
    "İlk ve Acil Yardım (İÖ) (Ücretli)",
    "İş ve Uğraşı Terapisi (Burslu)",
    "İş ve Uğraşı Terapisi (%50 İndirimli)",
    "Odyometri (İÖ) (Burslu)",
    "Odyometri (İÖ) (%50 İndirimli)",
    "Tıbbi Görüntüleme Teknikleri (Ücretli)",
    "Fizyoterapi (Ücretli)",
    "Elektronik Haberleşme Teknolojisi (Burslu)",
    "Elektronik Haberleşme Teknolojisi (%50 İndirimli)",
    "Endüstriyel Kalıpçılık (Burslu)",
    "Endüstriyel Kalıpçılık (%50 İndirimli)",
    "Makine (Burslu)",
    "Makine (%50 İndirimli)",
    "Dış Ticaret (Burslu)",
    "Dış Ticaret (%50 İndirimli)",
    "Elektronik Teknolojisi (Burslu)",
    "Elektronik Teknolojisi (%50 İndirimli)",
    "Endüstriyel Hammaddeler İşleme Teknolojisi (Burslu)",
    "Endüstriyel Hammaddeler İşleme Teknolojisi (%50 İndirimli)",
    "Gıda Kalite Kontrolü ve Analizi (Burslu)",
    "Gıda Kalite Kontrolü ve Analizi (%50 İndirimli)",
    "Gıda Teknolojisi (Burslu)",
    "Gıda Teknolojisi (%50 İndirimli)",
    "İşletme Yönetimi (Burslu)",
    "İşletme Yönetimi (%50 İndirimli)",
    "Kontrol ve Otomasyon Teknolojisi (Burslu)",
    "Kontrol ve Otomasyon Teknolojisi (%50 İndirimli)",
    "Lojistik (Burslu)",
    "Lojistik (%50 İndirimli)",
    "Otomotiv Teknolojisi (Burslu)",
    "Otomotiv Teknolojisi (%50 İndirimli)",
    "İş Sağlığı ve Güvenliği (Burslu)",
    "İş Sağlığı ve Güvenliği (%50 İndirimli)",
    "Patoloji Laboratuvar Teknikleri (Ücretli)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Ücretli)",
    "Tıbbi Görüntüleme Teknikleri (%25 İndirimli)",
    "Adalet (Ücretli)",
    "Aşçılık (%25 İndirimli)",
    "Büro Yönetimi ve Yönetici Asistanlığı (Burslu)",
    "Büro Yönetimi ve Yönetici Asistanlığı (%50 İndirimli)",
    "Çocuk Gelişimi (Ücretli)",
    "Fotoğrafçılık ve Kameramanlık (Burslu)",
    "Fotoğrafçılık ve Kameramanlık (%50 İndirimli)",
    "Muhasebe ve Vergi Uygulamaları (Burslu)",
    "Muhasebe ve Vergi Uygulamaları (%50 İndirimli)",
    "Pastacılık ve Ekmekçilik (Burslu)",
    "Pastacılık ve Ekmekçilik (%25 İndirimli)",
    "Radyo ve Televizyon Programcılığı (Burslu)",
    "Radyo ve Televizyon Programcılığı (%50 İndirimli)",
    "Turizm ve Otel İşletmeciliği (Burslu)",
    "Turizm ve Otel İşletmeciliği (%50 İndirimli)",
    "Bilgisayar Programcılığı (Ücretli)",
    "Bilgisayar Programcılığı (%25 İndirimli)",
    "Bilgisayar Programcılığı (İÖ) (Ücretli)",
    "Bilgisayar Programcılığı (İÖ) (Burslu)",
    "Bilgisayar Programcılığı (İÖ) (%25 İndirimli)",
    "Biyomedikal Cihaz Teknolojisi (Ücretli)",
    "Sağlık Bilgi Sistemleri Teknikerliği (Burslu)",
    "Sağlık Bilgi Sistemleri Teknikerliği (%50 İndirimli)",
    "Deniz ve Liman İşletmeciliği (Burslu)",
    "Deniz ve Liman İşletmeciliği (%50 İndirimli)",
    "Dış Ticaret (Uzaktan Öğretim) (Burslu)",
    "Dış Ticaret (Uzaktan Öğretim) (%50 İndirimli)",
    "Lojistik (Uzaktan Öğretim) (Burslu)",
    "Lojistik (Uzaktan Öğretim) (%50 İndirimli)",
    "Raylı Sistemler İşletmeciliği (Burslu)",
    "Raylı Sistemler İşletmeciliği (%50 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (İngilizce) (Burslu)",
    "Sivil Havacılık Kabin Hizmetleri (İngilizce) (%50 İndirimli)",
    "Ağız ve Diş Sağlığı (Ücretli)",
    "Ameliyathane Hizmetleri (Ücretli)",
    "Eczane Hizmetleri (Ücretli)",
    "Elektronörofizyoloji (Ücretli)",
    "Ortopedik Protez ve Ortez (Ücretli)",
    "Radyoterapi (Ücretli)",
    "Tıbbi Laboratuvar Teknikleri (Ücretli)",
    "Yaşlı Bakımı (Burslu)",
    "Yaşlı Bakımı (%50 İndirimli)",
    "Acil Durum ve Afet Yönetimi (Burslu)",
    "Acil Durum ve Afet Yönetimi (%50 İndirimli)",
    "Ameliyathane Hizmetleri (İÖ) (Burslu)",
    "Ameliyathane Hizmetleri (İÖ) (%50 İndirimli)",
    "Diyaliz (İÖ) (Burslu)",
    "Diyaliz (İÖ) (%50 İndirimli)",
    "Fizyoterapi (İÖ) (Burslu)",
    "Fizyoterapi (İÖ) (%50 İndirimli)",
    "Bilgisayar Programcılığı (İngilizce) (Burslu)",
    "Bilgisayar Programcılığı (İngilizce) (%50 İndirimli)",
    "Dış Ticaret (İngilizce) (Burslu)",
    "Dış Ticaret (İngilizce) (%50 İndirimli)",
    "E-Ticaret ve Pazarlama (İngilizce) (Burslu)",
    "E-Ticaret ve Pazarlama (İngilizce) (%50 İndirimli)",
    "Aşçılık (İÖ) (Burslu)",
    "Aşçılık (İÖ) (%50 İndirimli)",
    "Bilgisayar Teknolojisi (Burslu)",
    "Bilgisayar Teknolojisi (%50 İndirimli)",
    "Dış Ticaret (İÖ) (Burslu)",
    "Dış Ticaret (İÖ) (%50 İndirimli)",
    "Elektrik (Burslu)",
    "Elektrik (%50 İndirimli)",
    "Halkla İlişkiler ve Tanıtım (Burslu)",
    "Halkla İlişkiler ve Tanıtım (%50 İndirimli)",
    "İnsan Kaynakları Yönetimi (Burslu)",
    "İnsan Kaynakları Yönetimi (%50 İndirimli)",
    "Otopsi Yardımcılığı (Burslu)",
    "Otopsi Yardımcılığı (%50 İndirimli)",
    "Ambalaj Tasarımı (Burslu)",
    "Ambalaj Tasarımı (%50 İndirimli)",
    "Engelliler İçin Gölge Öğreticilik (Burslu)",
    "Engelliler İçin Gölge Öğreticilik (%50 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (Ücretli)",
    "Sivil Havacılık Kabin Hizmetleri (%25 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (İÖ) (Burslu)",
    "Sivil Havacılık Kabin Hizmetleri (İÖ) (%25 İndirimli)",
    "Fizyoterapi (İngilizce) (Burslu)",
    "Fizyoterapi (İngilizce) (%50 İndirimli)",
    "Saç Bakımı ve Güzellik Hizmetleri (Burslu)",
    "Saç Bakımı ve Güzellik Hizmetleri (%50 İndirimli)",
    "Adalet (%25 İndirimli)",
    "Diyaliz (Ücretli)",
    "Diyaliz (%25 İndirimli)",
    "İnsansız Hava Aracı Teknolojisi ve Operatörlüğü (Ücretli)",
    "İnsansız Hava Aracı Teknolojisi ve Operatörlüğü (%25 İndirimli)",
    "Bilgisayar Programcılığı (İÖ) (%50 İndirimli)",
    "Dış Ticaret (Ücretli)",
    "Grafik Tasarımı (Ücretli)",
    "Grafik Tasarımı (İÖ) (Ücretli)",
    "Grafik Tasarımı (İÖ) (Burslu)",
    "Grafik Tasarımı (İÖ) (%50 İndirimli)",
    "Optisyenlik (İÖ) (Ücretli)",
    "Tıbbi Görüntüleme Teknikleri (İÖ) (Ücretli)",
    "Ağız ve Diş Sağlığı (%25 İndirimli)",
    "Anestezi (İÖ) (%25 İndirimli)",
    "Aşçılık (Ücretli)",
    "Bankacılık ve Sigortacılık (İÖ) (Burslu)",
    "Bankacılık ve Sigortacılık (İÖ) (%50 İndirimli)",
    "Bilgisayar Destekli Tasarım ve Animasyon (%25 İndirimli)",
    "Çocuk Gelişimi (%25 İndirimli)",
    "Çocuk Gelişimi (İÖ) (Ücretli)",
    "Çocuk Gelişimi (İÖ) (%25 İndirimli)",
    "Diş Protez Teknolojisi (Ücretli)",
    "Diş Protez Teknolojisi (%25 İndirimli)",
    "Elektrik (İÖ) (Burslu)",
    "Elektrik (İÖ) (%50 İndirimli)",
    "Fizyoterapi (İÖ) (Ücretli)",
    "Fizyoterapi (İÖ) (%25 İndirimli)",
    "Grafik Tasarımı (%25 İndirimli)",
    "Grafik Tasarımı (İÖ) (%25 İndirimli)",
    "İnşaat Teknolojisi (İÖ) (Burslu)",
    "İnşaat Teknolojisi (İÖ) (%50 İndirimli)",
    "Mekatronik (Ücretli)",
    "Mekatronik (%25 İndirimli)",
    "Mimari Restorasyon (Ücretli)",
    "Mimari Restorasyon (İÖ) (Ücretli)",
    "Mimari Restorasyon (İÖ) (Burslu)",
    "Mimari Restorasyon (İÖ) (%50 İndirimli)",
    "Optisyenlik (%25 İndirimli)",
    "Optisyenlik (İÖ) (%25 İndirimli)",
    "Radyo ve Televizyon Programcılığı (İÖ) (Burslu)",
    "Radyo ve Televizyon Programcılığı (İÖ) (%50 İndirimli)",
    "Sivil Hava Ulaştırma İşletmeciliği (Burslu)",
    "Sivil Hava Ulaştırma İşletmeciliği (%50 İndirimli)",
    "Sivil Hava Ulaştırma İşletmeciliği (İÖ) (Burslu)",
    "Sivil Hava Ulaştırma İşletmeciliği (İÖ) (%50 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (İÖ) (Ücretli)",
    "Tıbbi Dokümantasyon ve Sekreterlik (İÖ) (Ücretli)",
    "Tıbbi Görüntüleme Teknikleri (İÖ) (%25 İndirimli)",
    "Tıbbi Laboratuvar Teknikleri (%25 İndirimli)",
    "Tıbbi Laboratuvar Teknikleri (İÖ) (Ücretli)",
    "Tıbbi Laboratuvar Teknikleri (İÖ) (%25 İndirimli)",
    "Turist Rehberliği (İÖ) (Burslu)",
    "Turist Rehberliği (İÖ) (%50 İndirimli)",
    "Uçak Teknolojisi (Ücretli)",
    "Uçak Teknolojisi (Burslu)",
    "Uçak Teknolojisi (%25 İndirimli)",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (İÖ) (Burslu)",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (İÖ) (%50 İndirimli)",
    "Bilgisayar Programcılığı (Uzaktan Öğretim) (Burslu)",
    "Biyomedikal Cihaz Teknolojisi (İÖ) (Burslu)",
    "Biyomedikal Cihaz Teknolojisi (İÖ) (%50 İndirimli)",
    "Elektronik Teknolojisi (İÖ) (Burslu)",
    "Elektronik Teknolojisi (İÖ) (%50 İndirimli)",
    "Gıda Teknolojisi (İÖ) (Burslu)",
    "Gıda Teknolojisi (İÖ) (%50 İndirimli)",
    "Halkla İlişkiler ve Tanıtım (İÖ) (Burslu)",
    "Halkla İlişkiler ve Tanıtım (İÖ) (%50 İndirimli)",
    "İnsan Kaynakları Yönetimi (İÖ) (Burslu)",
    "İnsan Kaynakları Yönetimi (İÖ) (%50 İndirimli)",
    "İş Sağlığı ve Güvenliği (İÖ) (Burslu)",
    "İş Sağlığı ve Güvenliği (İÖ) (%50 İndirimli)",
    "İşletme Yönetimi (İngilizce) (Burslu)",
    "İşletme Yönetimi (İngilizce) (%50 İndirimli)",
    "Kuyumculuk ve Takı Tasarımı (Burslu)",
    "Kuyumculuk ve Takı Tasarımı (%50 İndirimli)",
    "Lojistik (İÖ) (Burslu)",
    "Lojistik (İÖ) (%50 İndirimli)",
    "Makine (İÖ) (Burslu)",
    "Makine (İÖ) (%50 İndirimli)",
    "Moda Tasarımı (İÖ) (Burslu)",
    "Moda Tasarımı (İÖ) (%50 İndirimli)",
    "Muhasebe ve Vergi Uygulamaları (İÖ) (Burslu)",
    "Muhasebe ve Vergi Uygulamaları (İÖ) (%50 İndirimli)",
    "Otomotiv Teknolojisi (İÖ) (Burslu)",
    "Otomotiv Teknolojisi (İÖ) (%50 İndirimli)",
    "Pazarlama (İÖ) (Burslu)",
    "Pazarlama (İÖ) (%50 İndirimli)",
    "Saç Bakımı ve Güzellik Hizmetleri (İÖ) (Burslu)",
    "Saç Bakımı ve Güzellik Hizmetleri (İÖ) (%50 İndirimli)",
    "Sivil Hava Ulaştırma İşletmeciliği (İngilizce) (Burslu)",
    "Sivil Hava Ulaştırma İşletmeciliği (İngilizce) (%50 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (İÖ) (%50 İndirimli)",
    "Turist Rehberliği (İngilizce) (Burslu)",
    "Turist Rehberliği (İngilizce) (%50 İndirimli)",
    "Turizm ve Otel İşletmeciliği (İÖ) (Burslu)",
    "Turizm ve Otel İşletmeciliği (İÖ) (%50 İndirimli)",
    "Uçak Teknolojisi (%50 İndirimli)",
    "Uygulamalı İspanyolca Çevirmenlik (İspanyolca) (Burslu)",
    "Uygulamalı İspanyolca Çevirmenlik (İspanyolca) (%50 İndirimli)",
    "Uygulamalı İspanyolca Çevirmenlik (İspanyolca) (İÖ) (Burslu)",
    "Uygulamalı İspanyolca Çevirmenlik (İspanyolca) (İÖ) (%50 İndirimli)",
    "Diş Protez Teknolojisi (İÖ) (Burslu)",
    "Diş Protez Teknolojisi (İÖ) (%50 İndirimli)",
    "Elektronörofizyoloji (İÖ) (Burslu)",
    "Elektronörofizyoloji (İÖ) (%50 İndirimli)",
    "Ortopedik Protez ve Ortez (İÖ) (Burslu)",
    "Ortopedik Protez ve Ortez (İÖ) (%50 İndirimli)",
    "Radyoterapi (İÖ) (Burslu)",
    "Radyoterapi (İÖ) (%50 İndirimli)",
    "Sosyal Hizmetler (İÖ) (Burslu)",
    "Sosyal Hizmetler (İÖ) (%50 İndirimli)",
    "Basım ve Yayım Teknolojileri (Burslu)",
    "Basım ve Yayım Teknolojileri (%50 İndirimli)",
    "Basım ve Yayım Teknolojileri (İÖ) (Burslu)",
    "Basım ve Yayım Teknolojileri (İÖ) (%50 İndirimli)",
    "Çok Boyutlu Modelleme ve Animasyon (Burslu)",
    "Çok Boyutlu Modelleme ve Animasyon (%50 İndirimli)",
    "Sağlık Kurumları İşletmeciliği (Burslu)",
    "Sağlık Kurumları İşletmeciliği (%50 İndirimli)",
    "Tekstil Teknolojisi (Burslu)",
    "Tekstil Teknolojisi (%50 İndirimli)",
    "Turizm ve Seyahat Hizmetleri (Burslu)",
    "Turizm ve Seyahat Hizmetleri (%50 İndirimli)",
    "Bilişim Güvenliği Teknolojisi (İÖ) (Burslu)",
    "Bilişim Güvenliği Teknolojisi (İÖ) (%50 İndirimli)",
    "Halkla İlişkiler ve Tanıtım (İngilizce) (Burslu)",
    "Halkla İlişkiler ve Tanıtım (İngilizce) (%50 İndirimli)",
    "İç Mekan Tasarımı (İÖ) (Burslu)",
    "İç Mekan Tasarımı (İÖ) (%50 İndirimli)",
    "Bilgisayar Destekli Tasarım ve Animasyon (Ücretli)",
    "Bilgisayar Teknolojisi (Ücretli)",
    "Bilişim Güvenliği Teknolojisi (Ücretli)",
    "Ceza İnfaz ve Güvenlik Hizmetleri (Ücretli)",
    "Ceza İnfaz ve Güvenlik Hizmetleri (Burslu)",
    "Ceza İnfaz ve Güvenlik Hizmetleri (%50 İndirimli)",
    "E-Ticaret ve Pazarlama (Ücretli)",
    "İnternet ve Ağ Teknolojileri (Ücretli)",
    "İnternet ve Ağ Teknolojileri (Burslu)",
    "İnternet ve Ağ Teknolojileri (%50 İndirimli)",
    "Kaynak Teknolojisi (Burslu)",
    "Kaynak Teknolojisi (%50 İndirimli)",
    "Kimya Teknolojisi (Burslu)",
    "Kimya Teknolojisi (%50 İndirimli)",
    "Medya ve İletişim (Burslu)",
    "Medya ve İletişim (%50 İndirimli)",
    "Mekatronik (İÖ) (Burslu)",
    "Mekatronik (İÖ) (%50 İndirimli)",
    "Su Altı Teknolojisi (Burslu)",
    "Su Altı Teknolojisi (%50 İndirimli)",
    "Tahribatsız Muayene (Burslu)",
    "Tahribatsız Muayene (%50 İndirimli)",
    "Bilgisayar Teknolojisi (İÖ) (Burslu)",
    "Bilgisayar Teknolojisi (İÖ) (%50 İndirimli)",
    "Deniz ve Liman İşletmeciliği (İÖ) (Burslu)",
    "Deniz ve Liman İşletmeciliği (İÖ) (%50 İndirimli)",
    "Hava Lojistiği (Burslu)",
    "Hava Lojistiği (%50 İndirimli)",
    "Hibrid ve Elektrikli Taşıtlar Teknolojisi (Burslu)",
    "Hibrid ve Elektrikli Taşıtlar Teknolojisi (%50 İndirimli)",
    "Pastacılık ve Ekmekçilik (%50 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (İngilizce) (%25 İndirimli)",
    "Uçak Teknolojisi (İÖ) (Ücretli)",
    "Uçak Teknolojisi (İÖ) (Burslu)",
    "Uçak Teknolojisi (İÖ) (%25 İndirimli)",
    "Web Tasarımı ve Kodlama (Burslu)",
    "Web Tasarımı ve Kodlama (%50 İndirimli)",
    "Ağız ve Diş Sağlığı (İÖ) (%25 İndirimli)",
    "Ameliyathane Hizmetleri (%25 İndirimli)",
    "Diyaliz (İÖ) (Ücretli)",
    "Laboratuvar Teknolojisi (Burslu)",
    "Laboratuvar Teknolojisi (%50 İndirimli)",
    "Saç Bakımı ve Güzellik Hizmetleri (Ücretli)",
    "Alternatif Enerji Kaynakları Teknolojisi (Burslu)",
    "Alternatif Enerji Kaynakları Teknolojisi (%50 İndirimli)",
    "İklimlendirme ve Soğutma Teknolojisi (Burslu)",
    "İklimlendirme ve Soğutma Teknolojisi (%50 İndirimli)",
    "Anestezi (İngilizce) (Burslu)",
    "Anestezi (İngilizce) (%50 İndirimli)",
    "İlk ve Acil Yardım (İngilizce) (Burslu)",
    "İlk ve Acil Yardım (İngilizce) (%50 İndirimli)",
    "Maliye (Burslu)",
    "Maliye (%50 İndirimli)",
    "Aşçılık (İÖ) (Ücretli)",
    "Bankacılık ve Sigortacılık (Ücretli)",
    "Bankacılık ve Sigortacılık (İÖ) (Ücretli)",
    "Deniz Ulaştırma ve İşletme (Ücretli)",
    "Deniz Ulaştırma ve İşletme (Burslu)",
    "Deniz Ulaştırma ve İşletme (%50 İndirimli)",
    "Dış Ticaret (İÖ) (Ücretli)",
    "Elektrik (Ücretli)",
    "Halkla İlişkiler ve Tanıtım (Ücretli)",
    "İnşaat Teknolojisi (Ücretli)",
    "İnşaat Teknolojisi (İÖ) (Ücretli)",
    "İşletme Yönetimi (Ücretli)",
    "İşletme Yönetimi (İÖ) (Ücretli)",
    "İşletme Yönetimi (İÖ) (Burslu)",
    "İşletme Yönetimi (İÖ) (%50 İndirimli)",
    "Lojistik (Ücretli)",
    "Makine (Ücretli)",
    "Moda Tasarımı (Ücretli)",
    "Muhasebe ve Vergi Uygulamaları (Ücretli)",
    "Otomotiv Teknolojisi (Ücretli)",
    "Radyo ve Televizyon Programcılığı (Ücretli)",
    "Sivil Hava Ulaştırma İşletmeciliği (Ücretli)",
    "Sivil Hava Ulaştırma İşletmeciliği (İÖ) (Ücretli)",
    "Spor Yönetimi (Ücretli)",
    "Turist Rehberliği (Ücretli)",
    "Turizm ve Otel İşletmeciliği (Ücretli)",
    "Ağız ve Diş Sağlığı (İÖ) (Ücretli)",
    "Ameliyathane Hizmetleri (İÖ) (Ücretli)",
    "Diş Protez Teknolojisi (İÖ) (Ücretli)",
    "İş Sağlığı ve Güvenliği (Ücretli)",
    "Odyometri (Ücretli)",
    "Odyometri (İÖ) (Ücretli)",
    "Radyoterapi (İÖ) (Ücretli)",
    "Sosyal Hizmetler (Ücretli)",
    "İş Sağlığı ve Güvenliği (Uzaktan Öğretim) (Burslu)",
    "İş Sağlığı ve Güvenliği (Uzaktan Öğretim) (%50 İndirimli)",
    "Mobil Teknolojileri (Burslu)",
    "Mobil Teknolojileri (%50 İndirimli)",
    "Evde Hasta Bakımı (Burslu)",
    "Evde Hasta Bakımı (%50 İndirimli)",
    "Spor Yönetimi (İÖ) (Burslu)",
    "Spor Yönetimi (İÖ) (%50 İndirimli)",
    "Siber Güvenlik (Burslu)",
    "Siber Güvenlik (%50 İndirimli)",
    "Aşçılık (Uzaktan Öğretim) (Burslu)",
    "Aşçılık (Uzaktan Öğretim) (%50 İndirimli)",
    "Bilgisayar Destekli Tasarım ve Animasyon (İÖ) (Burslu)",
    "Bilgisayar Destekli Tasarım ve Animasyon (İÖ) (%50 İndirimli)",
    "Bilgisayar Destekli Tasarım ve Animasyon (Uzaktan Öğretim) (Burslu)",
    "Bilgisayar Destekli Tasarım ve Animasyon (Uzaktan Öğretim) (%50 İndirimli)",
    "Bilgisayar Programcılığı (Uzaktan Öğretim) (%50 İndirimli)",
    "Çocuk Gelişimi (Uzaktan Öğretim) (Burslu)",
    "Çocuk Gelişimi (Uzaktan Öğretim) (%50 İndirimli)",
    "Eczane Hizmetleri (Uzaktan Öğretim) (Burslu)",
    "Eczane Hizmetleri (Uzaktan Öğretim) (%50 İndirimli)",
    "Grafik Tasarımı (Uzaktan Öğretim) (Burslu)",
    "Grafik Tasarımı (Uzaktan Öğretim) (%50 İndirimli)",
    "Grafik Tasarımı (İngilizce) (Burslu)",
    "Grafik Tasarımı (İngilizce) (%50 İndirimli)",
    "Harita ve Kadastro (İÖ) (Burslu)",
    "Harita ve Kadastro (İÖ) (%50 İndirimli)",
    "İnternet ve Ağ Teknolojileri (İÖ) (Burslu)",
    "İnternet ve Ağ Teknolojileri (İÖ) (%50 İndirimli)",
    "İnternet ve Ağ Teknolojileri (Uzaktan Öğretim) (Burslu)",
    "İnternet ve Ağ Teknolojileri (Uzaktan Öğretim) (%50 İndirimli)",
    "Moda Tasarımı (Uzaktan Öğretim) (Burslu)",
    "Moda Tasarımı (Uzaktan Öğretim) (%50 İndirimli)",
    "Turist Rehberliği (Uzaktan Öğretim) (Burslu)",
    "Turist Rehberliği (Uzaktan Öğretim) (%50 İndirimli)",
    "E-Ticaret ve Pazarlama (İÖ) (Burslu)",
    "E-Ticaret ve Pazarlama (İÖ) (%50 İndirimli)",
    "Dış Ticaret (%25 İndirimli)",
    "İç Mekan Tasarımı (%25 İndirimli)",
    "İnşaat Teknolojisi (%25 İndirimli)",
    "İş Sağlığı ve Güvenliği (%25 İndirimli)",
    "Mimari Restorasyon (%25 İndirimli)",
    "Radyo ve Televizyon Programcılığı (%25 İndirimli)",
    "Sivil Hava Ulaştırma İşletmeciliği (%25 İndirimli)",
    "Turizm ve Otel İşletmeciliği (%25 İndirimli)",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (Ücretli)",
    "Uygulamalı İngilizce Çevirmenlik (İngilizce) (%25 İndirimli)",
    "Aşçılık (İÖ) (%25 İndirimli)",
    "Çevre Sağlığı (Burslu)",
    "Çevre Sağlığı (%50 İndirimli)",
    "Sivil Hava Ulaştırma İşletmeciliği (İstanbul) (Burslu)",
    "Sivil Hava Ulaştırma İşletmeciliği (İstanbul) (%50 İndirimli)",
    "Sivil Havacılık Kabin Hizmetleri (İstanbul) (Burslu)",
    "Sivil Havacılık Kabin Hizmetleri (İstanbul) (%25 İndirimli)",
    "Turist Rehberliği (Uzaktan Öğretim) (%25 İndirimli)",
    "Uçak Teknolojisi (İstanbul) (Ücretli)",
    "Uçak Teknolojisi (İstanbul) (Burslu)",
    "Uçak Teknolojisi (İstanbul) (%25 İndirimli)",
    "Uçuş Harekat Yöneticiliği (İstanbul) (Burslu)",
    "Uçuş Harekat Yöneticiliği (İstanbul) (%50 İndirimli)",
    "Ameliyathane Hizmetleri (İÖ) (%25 İndirimli)",
    "Bilgisayar Teknolojisi (%25 İndirimli)",
    "Hibrid ve Elektrikli Taşıtlar Teknolojisi (%25 İndirimli)",
    "Bankacılık ve Sigortacılık (%25 İndirimli)",
    "Halkla İlişkiler ve Tanıtım (%25 İndirimli)",
    "Moda Tasarımı (%25 İndirimli)",
    "Silah Sanayi Teknikerliği (Burslu)",
    "Silah Sanayi Teknikerliği (%50 İndirimli)",
    "Aşçılık (İngilizce) (Ücretli)",
    "Aşçılık (İngilizce) (Burslu)",
    "Aşçılık (İngilizce) (%50 İndirimli)",
    "Deniz Brokerliği (Ücretli)",
    "Deniz Brokerliği (Burslu)",
    "Deniz Brokerliği (%50 İndirimli)",
    "Deniz ve Liman İşletmeciliği (Ücretli)",
    "Gemi İnşaatı (Ücretli)",
    "Gemi İnşaatı (Burslu)",
    "Gemi İnşaatı (%50 İndirimli)",
    "Gemi Makineleri İşletmeciliği (Ücretli)",
    "Gemi Makineleri İşletmeciliği (Burslu)",
    "Gemi Makineleri İşletmeciliği (%50 İndirimli)",
    "Marina ve Yat İşletmeciliği (Ücretli)",
    "Marina ve Yat İşletmeciliği (Burslu)",
    "Marina ve Yat İşletmeciliği (%50 İndirimli)",
    "Su Altı Teknolojisi (İngilizce) (Ücretli)",
    "Su Altı Teknolojisi (İngilizce) (Burslu)",
    "Su Altı Teknolojisi (İngilizce) (%50 İndirimli)",
    "Hava Lojistiği (%25 İndirimli)",
    "Çevre Sağlığı (İÖ) (Burslu)",
    "Çevre Sağlığı (İÖ) (%50 İndirimli)",
    "Çocuk Koruma ve Bakım Hizmetleri (Burslu)",
    "Çocuk Koruma ve Bakım Hizmetleri (%50 İndirimli)",
    "Eczane Hizmetleri (İÖ) (Burslu)",
    "Eczane Hizmetleri (İÖ) (%50 İndirimli)",
    "Engelli Bakımı ve Rehabilitasyon (Burslu)",
    "Engelli Bakımı ve Rehabilitasyon (%50 İndirimli)",
    "Laboratuvar Teknolojisi (İÖ) (Burslu)",
    "Laboratuvar Teknolojisi (İÖ) (%50 İndirimli)",
    "Nükleer Teknoloji ve Radyasyon Güvenliği (Burslu)",
    "Nükleer Teknoloji ve Radyasyon Güvenliği (%50 İndirimli)",
    "Sağlık Kurumları İşletmeciliği (İÖ) (Burslu)",
    "Sağlık Kurumları İşletmeciliği (İÖ) (%50 İndirimli)",
    "Sosyal Güvenlik (Burslu)",
    "Sosyal Güvenlik (%50 İndirimli)",
    "Tıbbi Tanıtım ve Pazarlama (Burslu)",
    "Tıbbi Tanıtım ve Pazarlama (%50 İndirimli)",
    "Gıda Teknolojisi (Ücretli)",
    "Ortopedik Protez ve Ortez (T.C. Vatandaşları) (Ücretli)",
    "Ortopedik Protez ve Ortez (T.C. Vatandaşları) (Burslu)",
    "Ortopedik Protez ve Ortez (T.C. Vatandaşları) (%50 İndirimli)",
    "Pastacılık ve Ekmekçilik (T.C. Vatandaşları) (Ücretli)",
    "Pastacılık ve Ekmekçilik (T.C. Vatandaşları) (Burslu)",
    "Sivil Havacılık Kabin Hizmetleri (İngilizce) (Ücretli)",
    "Turizm ve Otel İşletmeciliği (İngilizce) (Ücretli)",
    "Turizm ve Otel İşletmeciliği (İngilizce) (Burslu)",
    "Bankacılık ve Sigortacılık (İngilizce) (Ücretli)",
    "Bankacılık ve Sigortacılık (İngilizce) (Burslu)",
    "Bankacılık ve Sigortacılık (İngilizce) (%50 İndirimli)",
    "Bilgisayar Programcılığı (İngilizce) (Ücretli)",
    "İşletme Yönetimi (İngilizce) (Ücretli)",
    "Turizm ve Otel İşletmeciliği (İngilizce) (%50 İndirimli)",
    "Tıbbi Tanıtım ve Pazarlama (Ücretli)",
    "Deniz Ulaştırma ve İşletme (İngilizce) (Burslu)",
    "Deniz Ulaştırma ve İşletme (İngilizce) (%50 İndirimli)",
    "Deniz ve Liman İşletmeciliği (İngilizce) (Burslu)",
    "Deniz ve Liman İşletmeciliği (İngilizce) (%50 İndirimli)",
    "Gemi Makineleri İşletmeciliği (İngilizce) (Burslu)",
    "Gemi Makineleri İşletmeciliği (İngilizce) (%50 İndirimli)",
    "Çevre Koruma ve Kontrol (Burslu)",
    "Çevre Koruma ve Kontrol (%50 İndirimli)",
    "Eczane Hizmetleri (T.C. Vatandaşları) (Ücretli)",
    "Eczane Hizmetleri (T.C. Vatandaşları) (Burslu)",
    "Eczane Hizmetleri (T.C. Vatandaşları) (%50 İndirimli)",
    "Sağlık Kurumları İşletmeciliği (Ücretli)",
    "Bilişim Güvenliği Teknolojisi (İngilizce) (Burslu)",
    "Bilişim Güvenliği Teknolojisi (İngilizce) (%50 İndirimli)",
    "Çevre Koruma ve Kontrol (İngilizce) (Burslu)",
    "Çevre Koruma ve Kontrol (İngilizce) (%50 İndirimli)",
    "İnşaat Teknolojisi (İngilizce) (Burslu)",
    "İnşaat Teknolojisi (İngilizce) (%50 İndirimli)",
    "Elektronik Teknolojisi (İngilizce) (Burslu)",
    "Elektronik Teknolojisi (İngilizce) (%50 İndirimli)",
    "Radyo ve Televizyon Teknolojisi (Burslu)",
    "Radyo ve Televizyon Teknolojisi (%50 İndirimli)",
    "Ağız ve Diş Sağlığı (İngilizce) (Burslu)",
    "Ağız ve Diş Sağlığı (İngilizce) (%50 İndirimli)",
    "Laborant ve Veteriner Sağlık (Burslu)",
    "Laborant ve Veteriner Sağlık (%50 İndirimli)"    
];
const NoMYODepartments = [
    "Acil Durum ve Afet Yönetimi",
    "Acil Durum ve Afet Yönetimi (Açıköğretim)",
    "Acil Durum ve Afet Yönetimi (Uzaktan Öğretim)",
    "Adalet",
    "Adalet (Açıköğretim)",
    "Adalet (Uzaktan Öğretim)",
    "Ağız ve Diş Sağlığı",
    "Ameliyathane Hizmetleri",
    "Anestezi",
    "Arıcılık",
    "Aşçılık",
    "Aşçılık (Açıköğretim)",
    "Aşçılık (İngilizce)",
    "Bağcılık",
    "Bahçe Tarımı",
    "Bankacılık ve Sigortacılık",
    "Bankacılık ve Sigortacılık (Açıköğretim)",
    "Bankacılık ve Sigortacılık (İngilizce)",
    "Bankacılık ve Sigortacılık (Uzaktan Öğretim)",
    "Basım ve Yayın Teknolojileri",
    "Basın ve Yayıncılık",
    "Beton Teknolojisi",
    "Bilgisayar Destekli Tasarım ve Animasyon",
    "Bitki Koruma",
    "Biyokimya",
    "Boya Teknolojisi",
    "Coğrafi Bilgi Sistemleri",
    "Coğrafi Bilgi Sistemleri (Uzaktan Öğretim)",
    "Çağrı Merkezi Hizmetleri",
    "Çağrı Merkezi Hizmetleri (Açıköğretim)",
    "Çağrı Merkezi Hizmetleri (Uzaktan Öğretim)",
    "Çay Tarımı ve İşleme Teknolojisi",
    "Çevre Koruma ve Kontrol",
    "Çevre Sağlığı",
    "Çevre Temizliği ve Denetimi",
    "Çim Alan Tesisi ve Yönetimi",
    "Çocuk Gelişimi",
    "Çocuk Gelişimi (Açıköğretim)",
    "Çocuk Gelişimi (İngilizce)",
    "Çocuk Gelişimi (Uzaktan Öğretim)",
    "Çocuk Koruma ve Bakım Hizmetleri",
    "Çorap ve Moda Tasarımı",
    "Deniz Ulaştırma ve İşletme",
    "Deniz Ulaştırma ve İşletme (İngilizce)",
    "Deniz ve Liman İşletmeciliği",
    "Deniz ve Liman İşletmeciliği (İngilizce)",
    "Deri Konfeksiyon",
    "Deri Teknolojisi",
    "Diş Protez Teknolojisi",
    "Diyaliz",
    "Diyaliz (Türkçe)",
    "Doğal Yapı Taşları Teknolojisi",
    "Doğalgaz ve Tesisatı Teknolojisi",
    "Döküm",
    "Eczane Hizmetleri",
    "Eczane Hizmetleri (Uzaktan Öğretim)",
    "Elektronörofizyoloji",
    "Emlak ve Emlak Yönetimi",
    "Emlak ve Emlak Yönetimi (Açıköğretim)",
    "Endüstriyel Bitkiler Yetiştiriciliği",
    "Endüstriyel Cam ve Seramik",
    "Endüstriyel Tavukçuluk",
    "Engelli Bakımı ve Rehabilitasyon",
    "Eser Koruma",
    "Et ve Ürünleri Teknolojisi",
    "Ev İdaresi (Açıköğretim)",
    "Evde Hasta Bakımı",
    "Fındık Eksperliği",
    "Fidan Yetiştiriciliği",
    "Fizyoterapi",
    "Fotoğrafçılık ve Kameramanlık",
    "Fotoğrafçılık ve Kameramanlık (Açıköğretim)",
    "Gaz ve Tesisatı Teknolojisi",
    "Geleneksel El Sanatları",
    "Geleneksel Tekstillerin Konservasyonu ve Restorasyonu",
    "Gemi İnşaatı",
    "Gemi Makineleri İşletme",
    "Gemi Makineleri İşletme (İngilizce)",
    "Geoteknik",
    "Gıda Kalite Kontrolü ve Analizi",
    "Gıda Teknolojisi",
    "Giyim Üretim Teknolojisi",
    "Grafik Tasarımı",
    "Grafik Tasarımı (İngilizce)",
    "Grafik Tasarımı (Uzaktan Öğretim)",
    "Grafik Tasarımı (Uzaktan Öğretim)",
    "Grafik Tasarımı (Uzaktan Öğretim)",
    "Halıcılık ve Kilimcilik",
    "Harita ve Kadastro",
    "Harita ve Kadastro (Uzaktan Öğretim)",
    "Hava Lojistiği",
    "Havacılıkta Yer Hizmetleri Yönetimi",
    "Havacılıkta Yer Hizmetleri Yönetimi (Açıköğretim)",
    "Havacılıkta Yer Hizmetleri Yönetimi (İngilizce)",
    "Hukuk Büro Yönetimi ve Sekreterliği",
    "Hukuk Büro Yönetimi ve Sekreterliği (Açıköğretim)",
    "İç Mekan Tasarımı",
    "İkram Hizmetleri",
    "İlahiyat (Önlisans) (Açıköğretim)",
    "İlk ve Acil Yardım",
    "İnşaat Teknolojisi",
    "İnşaat Teknolojisi (İngilizce)",
    "İş Makineleri Operatörlüğü",
    "İş ve Uğraşı Terapisi",
    "Kağıt Teknolojisi",
    "Kaynak Teknolojisi",
    "Kimya Teknolojisi",
    "Kooperatifçilik",
    "Kozmetik Teknolojisi",
    "Kuruyemiş Üretimi ve Teknolojisi",
    "Kuyumculuk ve Takı Tasarımı",
    "Kültür Balıkçılığı",
    "Kültürel Miras ve Turizm",
    "Kültürel Miras ve Turizm (Açıköğretim)",
    "Kümes Hayvanları Yetiştiriciliği",
    "Laborant ve Veteriner Sağlık",
    "Laborant ve Veteriner Sağlık (Açıköğretim)",
    "Laboratuvar Teknolojisi",
    "Lastik ve Plastik Teknolojisi",
    "Maden Teknolojisi",
    "Mantarcılık",
    "Marina İşletme",
    "Marina ve Yat İşletmeciliği",
    "Marka İletişimi (Açıköğretim)",
    "Menkul Kıymetler ve Sermaye Piyasası",
    "Menkul Kıymetler ve Sermaye Piyasası (Açıköğretim)",
    "Meyve ve Sebze İşleme Teknolojisi",
    "Mimari Dekoratif Sanatlar",
    "Mimari Restorasyon",
    "Mimari Restorasyon (İstanbul)",
    "Mobil Teknolojileri",
    "Mobilya ve Dekorasyon",
    "Moda Tasarımı",
    "Moda Tasarımı (İngilizce) (İstanbul)",
    "Moda Tasarımı (İstanbul)",
    "Moda Tasarımı (Uzaktan Öğretim)",
    "Moda Tasarımı (Uzaktan Öğretim)",
    "Moda Yönetimi",
    "Nükleer Teknoloji ve Radyasyon Güvenliği",
    "Nükleer Tıp Teknikleri",
    "Odyometri",
    "Optisyenlik",
    "Optisyenlik(Ücretli)",
    "Organik Tarım",
    "Ormancılık ve Orman Ürünleri",
    "Ormancılık ve Orman Ürünleri (Uzaktan Öğretim)",
    "Ortopedik Protez ve Ortez",
    "Ortopedik Protez ve Ortez(Ücretli)",
    "Oto Boya ve Karoseri",
    "Otobüs Kaptanlığı",
    "Otomotiv Teknolojisi",
    "Otomotiv Teknolojisi (İngilizce)",
    "Otomotiv Teknolojisi (İngilizce) (UOÖLP-Thames Gateway College (İngiltere))",
    "Otopsi Yardımcılığı",
    "Özel Güvenlik ve Koruma",
    "Özel Güvenlik ve Koruma (Açıköğretim)",
    "Özel Güvenlik ve Koruma (Erkek)",
    "Özel Güvenlik ve Koruma (Kız)",
    "Patoloji Laboratuvar Teknikleri",
    "Perakende Satış ve Mağaza Yönetimi (Açıköğretim)",
    "Perfüzyon Teknikleri",
    "Peyzaj ve Süs Bitkileri",
    "Podoloji",
    "Posta Hizmetleri",
    "Posta Hizmetleri (Uzaktan Öğretim)",
    "Radyo ve Televizyon Programcılığı",
    "Radyo ve Televizyon Programcılığı (Açıköğretim)",
    "Radyo ve Televizyon Programcılığı (İngilizce)",
    "Radyo ve Televizyon Programcılığı (Uzaktan Öğretim)",
    "Radyo ve Televizyon Teknolojisi",
    "Radyoterapi",
    "Rafineri ve Petro-Kimya Teknolojisi",
    "Reklamcılık",
    "Reklamcılık (Açıköğretim)",
    "Saç Bakımı ve Güzellik Hizmetleri",
    "Saç Bakımı ve Güzellik Hizmetleri (Uzaktan Öğretim)",
    "Sağlık Bilgi Sistemleri Teknikerliği",
    "Sağlık Kurumları İşletmeciliği",
    "Sağlık Kurumları İşletmeciliği (Açıköğretim)",
    "Sağlık Kurumları İşletmeciliği (Uzaktan Öğretim)",
    "Sağlık Turizmi İşletmeciliği",
    "Sahne ve Dekor Tasarımı",
    "Sahne ve Gösteri Sanatları Teknolojisi",
    "Seracılık",
    "Seramik ve Cam",
    "Seramik, Cam ve Çinicilik",
    "Sivil Hava Ulaştırma İşletmeciliği",
    "Sivil Hava Ulaştırma İşletmeciliği (Açıköğretim)",
    "Sivil Hava Ulaştırma İşletmeciliği (İngilizce)",
    "Sivil Havacılık Kabin Hizmetleri",
    "Sivil Havacılık Kabin Hizmetleri (İngilizce)",
    "Sivil Savunma ve İtfaiyecilik",
    "Sivil Savunma ve İtfaiyecilik (Erkek)",
    "Sivil Savunma ve İtfaiyecilik (Kız)",
    "Sondaj Teknolojisi",
    "Sosyal Güvenlik",
    "Sosyal Hizmetler",
    "Sosyal Hizmetler (Açıköğretim)",
    "Spor Yönetimi",
    "Spor Yönetimi (Açıköğretim)",
    "Su Altı Teknolojisi",
    "Su Ürünleri İşleme Teknolojisi",
    "Sulama Teknolojisi",
    "Süs Bitkileri Yetiştiriciliği",
    "Süt ve Besi Hayvancılığı",
    "Süt ve Ürünleri Teknolojisi",
    "Şarap Üretim Teknolojisi",
    "Tahribatsız Muayene",
    "Tapu ve Kadastro",
    "Tarım (Açıköğretim)",
    "Tarım Makineleri",
    "Tarımsal İşletmecilik",
    "Tarımsal Ürünler Muhafaza ve Depolama Teknolojisi",
    "Tarla Bitkileri",
    "Tekstil Teknolojisi",
    "Tıbbi Dokümantasyon ve Sekreterlik",
    "Tıbbi Dokümantasyon ve Sekreterlik (Açıköğretim)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Uzaktan Öğretim)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Uzaktan Öğretim)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Uzaktan Öğretim)",
    "Tıbbi Dokümantasyon ve Sekreterlik (Uzaktan Öğretim)",
    "Tıbbi Görüntüleme Teknikleri",
    "Tıbbi Laboratuvar Teknikleri",
    "Tıbbi Tanıtım ve Pazarlama",
    "Tıbbi Tanıtım ve Pazarlama (Uzaktan Öğretim)",
    "Tıbbi ve Aromatik Bitkiler",
    "Tohumculuk",
    "Turist Rehberliği",
    "Turist Rehberliği (Uzaktan Öğretim)",
    "Turizm Animasyonu",
    "Turizm Rehberliği",
    "Turizm Rehberliği (İngilizce)",
    "Turizm ve Otel İşletmeciliği",
    "Turizm ve Otel İşletmeciliği (Açıköğretim)",
    "Turizm ve Otel İşletmeciliği (İngilizce)",
    "Turizm ve Otel İşletmeciliği (Uzaktan Öğretim)",
    "Turizm ve Seyahat Hizmetleri",
    "Turizm ve Seyahat Hizmetleri (Açıköğretim)",
    "Uçak Teknolojisi",
    "Uçuş Harekat Yöneticiliği",
    "Ulaştırma ve Trafik Hizmetleri",
    "Un ve Unlu Mamuller Teknolojisi (Değirmencilik)",
    "Uygulamalı İngilizce ve Çevirmenlik (İngilizce)",
    "Uygulamalı İspanyolca ve Çevirmenlik (İspanyolca)",
    "Uygulamalı Rusça ve Çevirmenlik (Rusça)",
    "Üretimde Kalite Kontrol",
    "Yağ Endüstrisi",
    "Yapı Denetimi",
    "Yapı Ressamlığı",
    "Yapı Tesisat Teknolojisi",
    "Yapı Yalıtım Teknolojisi",
    "Yaşlı Bakımı",
    "Yaşlı Bakımı (Açıköğretim)",
    "Yat İşletme ve Yönetimi",
    "Yat Kaptanlığı",
    "Yerel Yönetimler",
    "Yerel Yönetimler (Açıköğretim)",
    "Yerel Yönetimler (Uzaktan Öğretim)",
    "Zeytincilik ve Zeytin İşleme Teknolojisi"    
];
function IsValidMYODept(dept){
    let valid = true;
    if (dept == "") valid = false;
    if (dept != "" && NoMYODepartments.find(nd => dept.includes(nd))) valid = false;
    console.log("dept", dept, valid);
    return valid;
}

export {MYODepartments, IsValidMYODept}