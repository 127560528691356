import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import {Button, Link, Alert, AlertTitle} from '@mui/material';
import {Dialog, DialogTitle, DialogContent, DialogContentText} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { FormControl, FormLabel, RadioGroup,Radio,FormControlLabel, InputLabel, Select, MenuItem,
  FormGroup, Checkbox, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Form, Link as RouterLink } from "react-router-dom";
import KVKKDialog from './kvkkdialog.tsx';
import { useNavigate, useParams, useLoaderData, useSubmit } from 'react-router-dom';

export default function Profile() {
    let { projectID } = useParams();
    const profile = useLoaderData();
    const submit = useSubmit();
    //console.log("profileID", profileID, profile);
    const [skillTab, setSkillTab] = useState(profile.department ?? "1");    
    const handleTabChange = (event, newValue) => {
      setSkillTab(newValue);
      //console.log(skills["1"][0]);
    };
    const [field, setField] = useState(profile.department); 
    const [position,setPosition] = useState(profile.lastPosition ?? "");
    let navigate = useNavigate();
    const itemExists = (skl, container) => ([skl, profile[container ?? "skills"].includes(skl)]);
    const skills = useRef({
      "1": [
        itemExists("Alacaklar - Borçlar Muhasebesi, Mutabakatlar"),
        itemExists("Banka İşlemleri"),
        itemExists("Sabit Kıymetler"),
        itemExists("Maliyet Muhasebesi"),
        itemExists("Bilanço Gelir Tablosu Hazırlanması"),
        itemExists("Aylık / Sene Sonu Kapanışları"),
        itemExists("Vergi beyannamelerinin hazırlanması"),
        itemExists("Bordroların hazırlanması"),
        itemExists("Amaca özel (Ad hoc) raporların hazırlanması")
      ],
      "2": [
        itemExists("Günlük nakit akışın izlenmesi, yönetimi, yurtiçi ve yurtdışı tahsilatlar, ödemeler"),
        itemExists("Banka ve finansal kurumlarla ilişkilerin sağlanması"),
        itemExists("Kısa ve uzun dönem nakit akış  planlarının hazırlanması, izlenmesi ve revize edilmesi"),
        itemExists("Türev İşlemler - Döviz işlemleri (FX)"),
        itemExists("Türev İşlemler - Emtea işlemleri (LME gibi)"),
        itemExists("Amaca özel (Ad hoc) raporların hazırlanması"),
        itemExists("Müşteri Riskleri")
      ],
      "3": [
        itemExists("Bilanço / Geri Tablosu  kontrolu ve detay raporların hazırlanması"),
        itemExists("Şirket içi veya dışı farklı taraflardan gerekli bilgileri almak üzere işbirliği yapmak"),
        itemExists("Periyodik bütçe hazırlıklarında bulunmak, revize bütçelerin hazırlanması"),
        itemExists("Konsolide bütçelerin hazırlanması"),
        itemExists("CAPEX / Sabit Kıymetler ile ilgili raporlama"),
        itemExists("OPEX / Operasyonlar ile ilgili raporlama"),
        itemExists("Ürün Maliyetleri"),
        itemExists("Karlılık Analizleri (Ürün bazlı /Satış Kanalı Bazlı gibi)"),
        itemExists("Amaca özel (Ad hoc) raporların hazırlanması")
      ],
    }).current;
    
    const positions = useRef([
      "Accountant",
      "Accounting Manager",
      "Accounting Supervisor",
      "Accounting Director",
      "Accounting Vice President",
      "Budget Analyst",
      "Cost Accountant",
      "Financial Accountant",
      "Financial Auditor",
      "Financial Reporting Accountant",
      "Internal Auditor",
      "Senior Accountant",
      "Senior Financial Analyst",
      "Senior Internal Auditor",
      "Senior Tax Accountant",
      "Staff Accountant"
    ]).current;
    
    const lastPositions = useRef([
      "Direktör",
      "Müdür",
      "Şef"
    ]).current;

    const reportingStandarts = useRef([
      itemExists("IFRS", "standarts"),
      itemExists("USGAAP", "standarts"),
      itemExists("German GAAP", "standarts"),
      itemExists("SPK", "standarts")
    ]).current;

    const reportingTools = useRef([
      itemExists("SAP","tools"),
      itemExists("Oracle","tools"),
      itemExists("Logo","tools"),
      itemExists("Netsis","tools")
    ]).current;

    const fieldChanged = (field) => {
      setSkillTab(field);
      setField(field);
    }

    const labelcolor = "#0092d6";
    const [bagliCalisan, setBagliCalisan] = useState(profile.isReportees == "on");

    const [kvkkOpen, setKVKKOpen] = React.useState(false);
    const [kvkkTime,setKvkkTime] = useState(profile.kvkkTimeStamp ?? "");
    const kvkkOK = kvkkTime != "";
    const acceptKVKK = () => {
      setKvkkTime((new Date(Date.now())).toISOString());
      setKVKKOpen(false);
    }
    const [alert, setAlert] = useState("");
    const KvkkAlert = () => {
      return(
        <Dialog onClose={() => setAlert('')} open={alert == "kvkk"}>
          <DialogTitle>KVKK onayı gerekiyor</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bilgilerinizi güncelleyebilmemiz için KVKK onayı vermelisiniz
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )
    }

    const dataForm = useRef();
    const submitForm = () => {   
      let formData = new FormData(dataForm.current);
      ["1","2","3"].forEach(skg => 
        skills[skg].forEach(s => {if (s[1] && !formData.getAll("skills").includes(s[0]) ) 
            formData.append("skills",s[0])}));
      formData.append("id", profile.id);
      formData.append("project_id", projectID);
      formData.append("lists", "skills,standarts,tools");
      //console.log(formData);
      submit(formData, {method:'POST'});
    }

    const skillCheckBox=(skill, name, size) => {
      const [checked, setChecked] = useState(skill[1]);
      const check = () => {
        skill[1] = !checked; 
        setChecked(!checked);
      }
      return (<Grid container flexWrap='nowrap' alignItems='flex-start' key={skill[0]}>
      <Checkbox sx={{paddingTop:"1px"}} size='small' checked={checked} value={skill[0]} 
        name={name} 
        onChange ={check}/>
      <FormLabel key={skill[0]} style={{color:'black', fontSize: size ?? '0.85em'}}
        onClick ={check}>
          {skill[0]}
      </FormLabel> 
      </Grid>)
    }

    return (
      <Grid container xs={6} item paddingLeft={"2em"} paddingBottom={'3em'}>
        {/* <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          This is a warning alert — <strong>check it out!</strong>
        </Alert> */}
        <KvkkAlert/>
        <KVKKDialog onClose={() => setKVKKOpen(false)} open={kvkkOpen} onConfirm={acceptKVKK}/>        
        <Form method='POST' ref={dataForm}
          //onChange={(event) => submit(event.currentTarget)}
          >
          <FormControl margin='normal' sx={{pr:"1em"}} >          
            <img width={'250em'} src={'/pp_logo.png'} alt={"PeoplePartners"} style={{marginBottom: "1em"}}/> 
            <FormLabel id="position-select-label" key='psl' sx={{marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Son çalıştığınız pozisyon:</FormLabel>
            <TextField 
              id="id-lastposition"
              aria-label='Last Position'
              name="lastPosition"
              defaultValue={profile.lastPosition ?? ""}
              style={{marginRight:"1em"}}
              size='small'              
            />
            <FormLabel id="super-select-label" key={'ssl'}
              sx={{marginTop:'1em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>
                Bağlı olduğunuz pozisyon:</FormLabel>
            <Select              
              labelId="position-select-label"
              id="position-select"
              name="reportingPosition"
              defaultValue={profile.reportingPosition ?? ""}
              style={{marginRight:"2em"}}
              size='small'
            >{lastPositions.map((item) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
            </Select>
            <Grid container marginTop='1em' alignItems={'center'} >
              <FormLabel id="bagli-calisan-label"sx={{color:labelcolor, fontSize:'1em'}} >Size bağlı çalışan var mı ?</FormLabel>
              <Checkbox id='bagli-calisan-check' 
                checked={bagliCalisan}
                value={bagliCalisan ? "on": "off"} name='isReportees'
                onChange={(event,checked) => setBagliCalisan(checked)} />
              <TextField 
                id="id-lbaglicalisan-number"
                name="reportees"
                aria-label='Bağlı çalışan sayısı'
                placeholder='Sayı girin'
                defaultValue={profile.reportees ?? ""}
                margin='dense'
                size='small'
                style={{width:"10em"}}
                disabled={!bagliCalisan}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}              
              />
            </Grid>
            <FormLabel key='blm' sx={{marginTop:"1em",color:labelcolor, fontSize:'1em'}} id="radio-buttons-group">Bölümünüz</FormLabel>
            <RadioGroup
              name="department"
              value={field}            
              onChange={(event,newValue) => fieldChanged(newValue)}              
            >
              <FormControlLabel value="1" control={<Radio />} label="Muhasebe" />
              <FormControlLabel value="2" control={<Radio />} label="Hazine" />
              <FormControlLabel value="3" control={<Radio />} label="Bütçe/Raporlama/Kontrol" />
            </RadioGroup>  
            <Grid justifyContent={'flex-start'} marginY ={"1em"} paddingBottom={'1em'}>
              <FormLabel id="skills-label" sx={{color:labelcolor, fontSize:'1em'}}>Yetkinlikler</FormLabel>
              <TabContext value={skillTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="Sectorel Skills">
                    <Tab label="Muhasebe" value="1" />
                    <Tab label="Hazine" value="2" />
                    <Tab label="Bütçe" value="3" />
                  </TabList>
                </Box>
                {["1","2","3"].map((item) => (<TabPanel style={{paddingBottom:'0em'}} value={item} key={item}  > 
                  <FormGroup sx={{rowGap:'5px'}}>
                    {/* {skills[item].map(skill => (
                        <FormControlLabel key={skill[0]} 
                        control={<Checkbox size='small' value={skill[1] ? "on" : "off"} defaultChecked={skill[1]} />} 
                        label={skill[0]} 
                        onChange={(event,checked) => skill[1] = checked}
                      />))} */}
                    {skills[item].map(skill => (skillCheckBox(skill, "skills")))}
                  </FormGroup>
                </TabPanel>))}
              </TabContext>  
              <FormLabel id="skill-warning" key='sw' 
                style={{color:'gray', fontSize:'0.7em', fontStyle:'italic'}}>* Diğer bölümlerden de yetkinlik seçebilirsiniz </FormLabel>       
            </Grid>
            <FormLabel id="standarts-label" key='sl' sx={{color:labelcolor, fontSize:'1em'}}>Bildiğiniz raporlama standartları</FormLabel>
            <FormGroup style={{marginLeft:'1em', marginBottom:'0.5em', paddingTop:"1em"}}>
              {reportingStandarts.map(skill => (skillCheckBox(skill,"standarts",'1em')))}
              {/* {reportingStandarts.map(item => (<FormControlLabel 
                control={<Checkbox size='small' value={item[1] ? "on" : "off"} defaultChecked={item[1]} name='standarts'/>} label={item[0]} 
                  onChange={(event,checked) => item[1] = checked}
                />))} */}
            </FormGroup>
            <TextField 
              id="id-standarts"
              aria-label='Raporlama standartları'
              name='otherStandarts'
              placeholder='Bildiğiniz diğer raporlama standartlarını yazabilirsiniz'
              defaultValue={profile.otherStandarts ?? ""}
              multiline
              rows={4}            
            />
            <FormLabel key='tl' sx={{mt:'1em', color:labelcolor, fontSize:'1em'}} id="tools-label">Kullandığınız ERP / Raporlama Araçları </FormLabel>
            <FormGroup style={{marginLeft:'1em', marginBottom:'0.5em', paddingTop:'1em'}}>
              {reportingTools.map(skill => (skillCheckBox(skill,"tools",'1em')))}
              {/* {reportingTools.map(item => (<FormControlLabel 
                control={<Checkbox size='small' value={item[1] ? "on" : "off"} defaultChecked={item[1]} name='tools'/>} label={item[0]} 
                  onChange={(event,checked) => item[1] = checked}
                />))} */}
            </FormGroup>
            <TextField 
              id="id-tools"
              aria-label='Raporlama araçları'
              name='otherTools'
              placeholder='Kullandığınız diğer raporlama araçlarını yazabilirsiniz'
              defaultValue={profile.otherTools ?? ""}
              multiline
              rows={4}
            />
            <Button sx={{width:'200px', alignSelf:'center', marginBottom:'1em', marginTop:'2em'
              , backgroundColor: kvkkOK ? '.MuiButton-contained.backgroundColor' : 'gray'}} 
              to="/profile" variant="contained" 
              onClick={() => kvkkOK ? submitForm() : setAlert("kvkk")} >
              Güncelle
            </Button>
            <FormControlLabel             
              control={<Checkbox id='kvkk-check' value={kvkkOK} checked={kvkkOK} 
                onChange={() => setKvkkTime("")} disabled={!kvkkOK} />} 
                label={<Typography onClick={() => {
                  setKVKKOpen(true);                  
                }} variant="subtitle2" component={Link}>
                KVKK kapsamında kişisel verilerimin işlenmesi ile ilgili bilgilendirme metnini okuduğumu ve kabul ettiğimi beyan ederim.
              </Typography>}             
            />
            <TextField value={kvkkTime} sx={{display:'none'}} id="kvvk-timestamp" name="kvkkTimeStamp" label="KVKK Timestamp" variant="standard" />
          </FormControl>
        </Form>
      </Grid>
    );
  }