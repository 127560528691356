import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize:'0.9em' }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

export default function KVKKDialog(props) {
  const {onClose, open, onConfirm} = props;
  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll='paper'
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          PeoplePartners Kişisel Verilerin Korunması ve İşlenmesi Açık Rıza Metni
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom fontSize={'0.8em'}>
          PeoplePartners Çalışan Adayı Kişisel Verilerinin Korunması ve İşlenmesi Aydınlatma Metni’ni okudum ve bu kapsamda;
          </Typography>
          <Typography mt={'1.5em'} fontSize={'0.8em'} gutterBottom>
          Özel nitelikli kişisel verilerimin çalışan adaylarının başvuru, seçme ve değerlendirme süreçlerinin planlaması 
          ve / veya yürütülmesi, çalışan adayı ve / veya öğrenci ve / veya stajyer yerleştirilmesi için gerekli iç / dış iletişim 
          aktivitelerinin planlanması ve / veya icrası, iş sağlığı ve / veya güvenliği çerçevesinde gerçekleştirilmesi gereken 
          faaliyetlerin planlanması ve / veya icrası, Şirket faaliyetlerinin Şirket prosedürleri ve / veya ilgili mevzuata uygun 
          olarak yürütülmesinin temini için gerekli operasyonel faaliyetlerinin planlanması ve / veya icrası, hukuk işlerinin 
          takibi ile yetkili kişi ve / veya kuruluşlara mevzuattan kaynaklı bilgi verilmesi amaçlarıyla işlenmesini ve 
          bu amaçlarla tedarikçilerimize, tedarikçilerimizin yurtiçi veya yurtdışında bulundurulan bilgisayar sistemlerine ve kanunen 
          yetkili kamu/özel kurumlarına aktarılmasını kabul ediyorum.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm}>
            Onaylıyorum
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}