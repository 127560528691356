import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error-page";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  redirect,
  useParams
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Profile from './profile';
import Thanks from './thanks';
import Confirm from './confirm';
import Confirmed from './confirmed';
import { green, purple } from '@mui/material/colors';
import {Helmet} from "react-helmet";
import { trTR } from '@mui/material/locale';

let baseApi = `https://peoplepartnersapi.azurewebsites.net`;
//baseApi = 'http://localhost:7071';

async function loadData({params}) {
  console.log("params",params);
  let jsonData = {siemens: {profileID:"asdfhh"}}
  if (params.profileID && params.profileID != "newid")
  {
    const response = await fetch(`${baseApi}/api/getProfile/${params.profileID}/${params.sector}/${params.position}`);
    jsonData = await response.json()    
      .catch(err => {
        console.log(err)
        return null;
      });    
  }  
  //console.log("json",jsonData);
  return jsonData ? jsonData[params.position] : null;
}

const ChooseForm = () => {
  var {sector, position} = useParams();
  if (!position || !sector) return null;
  position = position.replace(' ',"");

  const form = sector && position && require(`./forms/${sector}/${position}.js`).default();  
  return form ?? <Profile/>;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path='/' errorElement={<ErrorPage/>} 
      element={<h1>Home</h1>}/>,
    <Route path='/profile' errorElement={<ErrorPage/>}>
      <Route
        path=":profileID/:projectID/:sector?/:position?"
        element={<ChooseForm/>}        
        loader={loadData}         
        action={async ({ params, request }) => {
          const fdata = await request.formData();
          //console.log("fdata", fdata);
          const formData = Object.fromEntries(fdata);
          const lists = formData.lists.split(',');
          lists.forEach(list => {
            formData[list] = fdata.getAll(list); 
          });
          formData.position = params.position ?? "default";
          formData.sector = params.sector;
          
          //console.log("formData", formData);          
          const response = await fetch(`${baseApi}/api/updateProfile`,{
            body: JSON.stringify(formData),
            method: 'POST',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          });
          //return null;
          return redirect(formData.forward ?? "/confirm");
          //return fakeUpdateSong(params.songId, formData);
        }}            
      />
    </Route>, 
    <Route
      path="/onlineForm"
      element={<Profile/>}        
      loader={loadData}         
      action={async ({ params, request }) => {
        const fdata = await request.formData();
        //console.log("fdata", fdata);
        const formData = Object.fromEntries(fdata);
        formData.skills = fdata.getAll("skills");
        formData.standarts = fdata.getAll("standarts");
        formData.tools = fdata.getAll("tools");
        //console.log("formData", formData);
        const response = await fetch(`${baseApi}/api/updateProfile`,{
          body: JSON.stringify(formData),
          method: 'POST',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        });
        //return null;
        return redirect("/thanks");
        //return fakeUpdateSong(params.songId, formData);
      }}            
    />,        
    <Route path='/thanks' element={<Thanks/>}/>,
    <Route path='/confirm' element={<Confirm/>}/>,
    <Route 
      path='/mailconfirmed/:email/:profileid' 
      element={<Confirmed/>} 
      loader={async ({ params }) => {
        console.log(params);
        const formData = new FormData();
        formData.email = params.email;
        formData.profileid = params.profileid;
        const response = await fetch(`${baseApi}/api/confirmMail`,{
          body: JSON.stringify(formData),
          method: 'POST',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        });
        return response.ok;
      }}            
    />

  ])
);
const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: purple[500],
  //   },
  //   secondary: {
  //     main: green[500],
  //   },
  // },
  typography: {
    fontSize: 13,
  }
},trTR);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Helmet>
        <title>PeoplePartners</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
