const Universities = ["ABDULLAH GÜL ÜNİVERSİTESİ (KAYSERİ) (Devlet Üniversitesi)"
,"ADANA ALPARSLAN TÜRKEŞ BİLİM VE TEKNOLOJİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ADIYAMAN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"AFYON KOCATEPE ÜNİVERSİTESİ (AFYONKARAHİSAR) (Devlet Üniversitesi)"
,"AFYONKARAHİSAR SAĞLIK BİLİMLERİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"AĞRI İBRAHİM ÇEÇEN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"AKDENİZ ÜNİVERSİTESİ (ANTALYA)  (Devlet Üniversitesi)"
,"AKSARAY ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ALANYA ALAADDİN KEYKUBAT ÜNİVERSİTESİ (ANTALYA) (Devlet Üniversitesi)"
,"AMASYA ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ANADOLU ÜNİVERSİTESİ (ESKİŞEHİR)  (Devlet Üniversitesi)"
,"ANKARA HACI BAYRAM VELİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ANKARA SOSYAL BİLİMLER ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ANKARA ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ANKARA YILDIRIM BEYAZIT ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ARDAHAN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ARTVİN ÇORUH ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ATATÜRK ÜNİVERSİTESİ (ERZURUM) (Devlet Üniversitesi)"
,"AYDIN ADNAN MENDERES ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BALIKESİR ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BANDIRMA ONYEDİ EYLÜL ÜNİVERSİTESİ (BALIKESİR) (Devlet Üniversitesi)"
,"BARTIN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BATMAN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BAYBURT ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BİLECİK ŞEYH EDEBALİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BİNGÖL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BİTLİS EREN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BOĞAZİÇİ ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"BOLU ABANT İZZET BAYSAL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BURDUR MEHMET AKİF ERSOY ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BURSA TEKNİK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"BURSA ULUDAĞ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ÇANAKKALE ONSEKİZ MART ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ÇANKIRI KARATEKİN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ÇUKUROVA ÜNİVERSİTESİ (ADANA) (Devlet Üniversitesi)"
,"DİCLE ÜNİVERSİTESİ (DİYARBAKIR) (Devlet Üniversitesi)"
,"DOKUZ EYLÜL ÜNİVERSİTESİ (İZMİR) (Devlet Üniversitesi)"
,"DÜZCE ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"EGE ÜNİVERSİTESİ (İZMİR) (Devlet Üniversitesi)"
,"ERCİYES ÜNİVERSİTESİ (KAYSERİ)  (Devlet Üniversitesi)"
,"ERZİNCAN BİNALİ YILDIRIM ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ERZURUM TEKNİK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ESKİŞEHİR OSMANGAZİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ESKİŞEHİR TEKNİK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"FIRAT ÜNİVERSİTESİ (ELAZIĞ) (Devlet Üniversitesi)"
,"GALATASARAY ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"GAZİ ÜNİVERSİTESİ (ANKARA) (Devlet Üniversitesi)"
,"GAZİANTEP İSLAM BİLİM VE TEKNOLOJİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"GAZİANTEP ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"GEBZE TEKNİK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"GİRESUN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"GÜMÜŞHANE ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"HACETTEPE ÜNİVERSİTESİ (ANKARA)  (Devlet Üniversitesi)"
,"HAKKARİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"HARRAN ÜNİVERSİTESİ (ŞANLIURFA) (Devlet Üniversitesi)"
,"HATAY MUSTAFA KEMAL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"HİTİT ÜNİVERSİTESİ (ÇORUM) (Devlet Üniversitesi)"
,"IĞDIR ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ISPARTA UYGULAMALI BİLİMLER ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İNÖNÜ ÜNİVERSİTESİ (MALATYA) (Devlet Üniversitesi)"
,"İSKENDERUN TEKNİK ÜNİVERSİTESİ (HATAY) (Devlet Üniversitesi)"
,"İSTANBUL MEDENİYET ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İSTANBUL TEKNİK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İSTANBUL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İSTANBUL ÜNİVERSİTESİ-CERRAHPAŞA (Devlet Üniversitesi)"
,"İZMİR BAKIRÇAY ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İZMİR DEMOKRASİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İZMİR KATİP ÇELEBİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İZMİR YÜKSEK TEKNOLOJİ ENSTİTÜSÜ (Devlet Üniversitesi)"
,"KAFKAS ÜNİVERSİTESİ (KARS) (Devlet Üniversitesi)"
,"KAHRAMANMARAŞ İSTİKLAL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KAHRAMANMARAŞ SÜTÇÜ İMAM ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KARABÜK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KARADENİZ TEKNİK ÜNİVERSİTESİ (TRABZON)  (Devlet Üniversitesi)"
,"KARAMANOĞLU MEHMETBEY ÜNİVERSİTESİ (KARAMAN) (Devlet Üniversitesi)"
,"KASTAMONU ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KAYSERİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KIRIKKALE ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KIRKLARELİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KIRŞEHİR AHİ EVRAN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KİLİS 7 ARALIK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KOCAELİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KONYA TEKNİK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KÜTAHYA DUMLUPINAR ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"MALATYA TURGUT ÖZAL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"MANİSA CELÂL BAYAR ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"MARDİN ARTUKLU ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"MARMARA ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"MERSİN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"MİMAR SİNAN GÜZEL SANATLAR ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"MUĞLA SITKI KOÇMAN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"MUNZUR ÜNİVERSİTESİ (TUNCELİ) (Devlet Üniversitesi)"
,"MUŞ ALPARSLAN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"NECMETTİN ERBAKAN ÜNİVERSİTESİ (KONYA) (Devlet Üniversitesi)"
,"NEVŞEHİR HACI BEKTAŞ VELİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"NİĞDE ÖMER HALİSDEMİR ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ONDOKUZ MAYIS ÜNİVERSİTESİ (SAMSUN) (Devlet Üniversitesi)"
,"ORDU ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ORTA DOĞU TEKNİK ÜNİVERSİTESİ (ANKARA) (Devlet Üniversitesi)"
,"OSMANİYE KORKUT ATA ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"PAMUKKALE ÜNİVERSİTESİ (DENİZLİ) (Devlet Üniversitesi)"
,"RECEP TAYYİP ERDOĞAN ÜNİVERSİTESİ (RİZE) (Devlet Üniversitesi)"
,"SAĞLIK BİLİMLERİ ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"SAKARYA UYGULAMALI BİLİMLER ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SAKARYA ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SAMSUN ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SELÇUK ÜNİVERSİTESİ (KONYA)  (Devlet Üniversitesi)"
,"SİİRT ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SİNOP ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SİVAS BİLİM VE TEKNOLOJİ ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SİVAS CUMHURİYET ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"SÜLEYMAN DEMİREL ÜNİVERSİTESİ (ISPARTA) (Devlet Üniversitesi)"
,"ŞIRNAK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"TARSUS ÜNİVERSİTESİ (MERSİN) (Devlet Üniversitesi)"
,"TEKİRDAĞ NAMIK KEMAL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"TOKAT GAZİOSMANPAŞA ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"TRABZON ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"TRAKYA ÜNİVERSİTESİ (EDİRNE) (Devlet Üniversitesi)"
,"TÜRK-ALMAN ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"UŞAK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"VAN YÜZÜNCÜ YIL ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"YALOVA ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"YILDIZ TEKNİK ÜNİVERSİTESİ (İSTANBUL) (Devlet Üniversitesi)"
,"YOZGAT BOZOK ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"ZONGULDAK BÜLENT ECEVİT ÜNİVERSİTESİ (Devlet Üniversitesi)"
,"İÇİŞLERİ BAKANLIĞI VE MİLLİ SAVUNMA BAKANLIĞI ADINA SAĞLIK BİLİMLERİ ÜNİVERSİTESİNDE EĞİTİM ALACAKLAR (Devlet Üniversitesi) "
,"HOCA AHMET YESEVİ ULUSLARARASI TÜRK-KAZAK ÜNİVERSİTESİ (TÜRKİSTAN-KAZAKİSTAN)*"
,"KIRGIZİSTAN-TÜRKİYE MANAS ÜNİVERSİTESİ (BİŞKEK-KIRGIZİSTAN)*"
,"ACIBADEM MEHMET ALİ AYDINLAR ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"ALANYA ÜNİVERSİTESİ (ANTALYA) (Vakıf Üniversitesi) "
,"ALTINBAŞ ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"ANKARA BİLİM ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"ANKARA MEDİPOL ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"ANTALYA BELEK ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"ANTALYA BİLİM ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"ATILIM ÜNİVERSİTESİ (ANKARA)  (Vakıf Üniversitesi) "
,"AVRASYA ÜNİVERSİTESİ (TRABZON) (Vakıf Üniversitesi) "
,"BAHÇEŞEHİR ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"BAŞKENT ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"BEYKOZ ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"BEZM-İ ÂLEM VAKIF ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"BİRUNİ ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"ÇAĞ ÜNİVERSİTESİ (MERSİN) (Vakıf Üniversitesi) "
,"ÇANKAYA ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"DEMİROĞLU BİLİM ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"DOĞUŞ ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"FATİH SULTAN MEHMET VAKIF ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"FENERBAHÇE ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"HALİÇ ÜNİVERSİTESİ (İSTANBUL)  (Vakıf Üniversitesi) "
,"HASAN KALYONCU ÜNİVERSİTESİ (GAZİANTEP) (Vakıf Üniversitesi) "
,"IŞIK ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"İBN HALDUN ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"İHSAN DOĞRAMACI BİLKENT ÜNİVERSİTESİ (ANKARA)   (Vakıf Üniversitesi) "
,"İSTANBUL 29 MAYIS ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL AREL ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL ATLAS ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL AYDIN ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL BEYKENT ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL BİLGİ ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL ESENYURT ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL GALATA ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL GEDİK ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL GELİŞİM ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL KENT ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL KÜLTÜR ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL MEDİPOL ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL NİŞANTAŞI ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL OKAN ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL RUMELİ ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL SABAHATTİN ZAİM ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL SAĞLIK VE TEKNOLOJİ ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL TİCARET ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL TOPKAPI ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTANBUL YENİ YÜZYIL ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İSTİNYE ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"İZMİR EKONOMİ ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"İZMİR TINAZTEPE ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"KADİR HAS ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"KAPADOKYA ÜNİVERSİTESİ (NEVŞEHİR) (Vakıf Üniversitesi) "
,"KOCAELİ SAĞLIK VE TEKNOLOJİ ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"KOÇ ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"KONYA GIDA VE TARIM ÜNİVERSİTESİ (Vakıf Üniversitesi) "
,"KTO KARATAY ÜNİVERSİTESİ (KONYA) (Vakıf Üniversitesi) "
,"LOKMAN HEKİM ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"MALTEPE ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"MEF ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"MUDANYA ÜNİVERSİTESİ (BURSA) (Vakıf Üniversitesi) "
,"NUH NACİ YAZGAN ÜNİVERSİTESİ (KAYSERİ) (Vakıf Üniversitesi) "
,"OSTİM TEKNİK ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"ÖZYEĞİN ÜNİVERSİTESİ (İSTANBUL)  (Vakıf Üniversitesi) "
,"PİRİ REİS ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"SABANCI ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"SANKO ÜNİVERSİTESİ (GAZİANTEP) (Vakıf Üniversitesi) "
,"TED ÜNİVERSİTESİ (ANKARA)  (Vakıf Üniversitesi) "
,"TOBB EKONOMİ VE TEKNOLOJİ ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"TOROS ÜNİVERSİTESİ (MERSİN) (Vakıf Üniversitesi) "
,"TÜRK HAVA KURUMU ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"UFUK ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"ÜSKÜDAR ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"YAŞAR ÜNİVERSİTESİ (İZMİR) (Vakıf Üniversitesi) "
,"YEDİTEPE ÜNİVERSİTESİ (İSTANBUL) (Vakıf Üniversitesi) "
,"YÜKSEK İHTİSAS ÜNİVERSİTESİ (ANKARA) (Vakıf Üniversitesi) "
,"ADA KENT ÜNİVERSİTESİ (KKTC-GAZİMAĞUSA)"
,"AKDENİZ KARPAZ ÜNİVERSİTESİ (KKTC-LEFKOŞA)"
,"ARKIN YARATICI SANATLAR VE TASARIM ÜNİVERSİTESİ (KKTC-GİRNE)"
,"BAHÇEŞEHİR KIBRIS ÜNİVERSİTESİ (KKTC-LEFKOŞA)"
,"DOĞU AKDENİZ ÜNİVERSİTESİ (KKTC-GAZİMAĞUSA)"
,"GİRNE AMERİKAN ÜNİVERSİTESİ (KKTC-GİRNE)"
,"GİRNE ÜNİVERSİTESİ (KKTC-GİRNE)"
,"KIBRIS AMERİKAN ÜNİVERSİTESİ (KKTC-LEFKOŞA)"
,"KIBRIS BATI ÜNİVERSİTESİ (KKTC-GAZİMAĞUSA)"
,"KIBRIS İLİM ÜNİVERSİTESİ (KKTC-GİRNE)"
,"KIBRIS SAĞLIK VE TOPLUM BİLİMLERİ ÜNİVERSİTESİ (KKTC-GÜZELYURT)"
,"LEFKE AVRUPA ÜNİVERSİTESİ (KKTC-LEFKE)"
,"RAUF DENKTAŞ ÜNİVERSİTESİ (KKTC-LEFKOŞA)"
,"ULUSLARARASI FİNAL ÜNİVERSİTESİ (KKTC-GİRNE)"
,"ULUSLARARASI KIBRIS ÜNİVERSİTESİ (KKTC-LEFKOŞA)"
,"YAKIN DOĞU ÜNİVERSİTESİ (KKTC-LEFKOŞA)"
,"AZERBAYCAN DEVLET PEDAGOJİ ÜNİVERSİTESİ (BAKÜ-AZERBAYCAN)"
,"AZERBAYCAN DİLLER ÜNİVERSİTESİ (BAKÜ-AZERBAYCAN)"
,"AZERBAYCAN TIP ÜNİVERSİTESİ (BAKÜ-AZERBAYCAN)"
,"BAKÜ DEVLET ÜNİVERSİTESİ (BAKÜ-AZERBAYCAN)"
,"ULUSLARARASI SARAYBOSNA ÜNİVERSİTESİ (SARAYBOSNA - BOSNA - HERSEK)"
,"ULUSLARARASI BALKAN ÜNİVERSİTESİ (ÜSKÜP-MAKEDONYA)"
]
export {Universities};