import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {Button, Link, Alert, AlertTitle, duration, Input} from '@mui/material';
import {Dialog, DialogTitle, DialogContent, DialogContentText, InputAdornment} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { FormControl, FormLabel, RadioGroup,Radio,FormControlLabel, InputLabel, Select, MenuItem,
  FormGroup, Checkbox, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { Form, Link as RouterLink } from "react-router-dom";
import KVKKDialog from '../../kvkkdialog.tsx';
import { useNavigate, useParams, useLoaderData, useSubmit } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Universities } from '../../data/univs.js';
import { Departments, IsValidDept } from '../../data/depts.js';
import {MYODepartments, IsValidMYODept} from '../../data/myodepts.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

  export default function Profile() {
    let { projectID } = useParams();
    const profile = {
      department: "1",
      skills:[]
    };

    const submit = useSubmit();
    const [langLevel, setLangLevel] = useState(profile?.langLevel ?? ""); 
    const [mandatory,setMandatory] = useState("");
    const [education,setEducation] = useState("");
    const [msoffice,setMsoffice] = useState("");
    const [tekniklise,setTeknikLise] = useState("");
    const [universite,setUniversite] = useState();
    const [bolum,setBolum] = useState("");
    const [myobolum,setMYOBolum] = useState("");
    
    let navigate = useNavigate();
    const itemExists = (skl, container) => ([skl, profile[container] && profile[container ?? "skills"].includes(skl)]);
    
    const mandatories = useRef([
      "Evet, staj zorunluluğum var ve okulum staj boyunca sigortamı (SGK) karşılıyor.",
      "Hayır, staj zorunluluğum yok, ama okulum staj boyunca sigortamı (SGK) karşılıyor.",
      "Hayır, staj zorunluluğum yok, gönüllü staj yapmak istiyorum."
    ]).current;
    const educationLevels = useRef([
      "Yüksek Lisans",
      "Lisans (4 Yıllık Üniversite)",
      "Önlisans (2 Yıllık MYO)",
      "Teknik Lise"
    ]).current;
    const msofficeLevels = useRef([
      "Temel",
      "Orta",
      "İleri",
    ]).current;

    const langLevels = useRef([
      "Temel",
      "Orta",
      "İleri",
    ]).current;

    const langs = useRef([
      "Almanca",
      "Fransızca",
      "İspanyolca",
    ]).current;

    const stajTypes = useRef([
      itemExists("Ofisten (onsite)", "staj"),
      itemExists("Uzaktan (remote)", "staj"),
      itemExists("Hibrit (hybrid)", "staj"),
    ]).current;

    const stajDepts = useRef([
      "Akıllı Altyapılar (SI) - Kartal/Gebze",
      "Bilgi Teknolojileri (IT) – Kartal/Gebze",
      "Dijital Endüstriler (DI) - Kartal/Gebze",
      "Finans (FIN) – Kartal/Gebze",
      "Gayrimenkul Yönetimi (SRE) - Kartal",
      "Küresel İş Servisleri (GBS) - Kartal",
      "İnsan ve Organizasyon (P&O) - Kartal",
      "Kurumsal İletişim (CM) - Kartal",
      "Yazılım ARGE (ADV) – Kartal/ODTÜ Teknokent",
      "Mali İşler & Controlling (CF) Kartal",
      "Sağlık & Emniyet & Çevre (EHS) - Kartal",
      "Tedarik Zinciri Yönetimi (SCM) -Kartal",
      "Vergi (T) - Kartal",
    ]).current;

    const labelcolor = "#0092d6";
    const [bagliCalisan, setBagliCalisan] = useState(profile.isReportees == "on");

    const [kvkkOpen, setKVKKOpen] = React.useState(false);
    const [kvkkTime,setKvkkTime] = useState(profile.kvkkTimeStamp ?? "");
    const kvkkOK = kvkkTime != "";
    const acceptKVKK = () => {
      setKvkkTime((new Date(Date.now())).toISOString());
      setKVKKOpen(false);
    }
    const [alert, setAlert] = useState("");
    const KvkkAlert = () => {
      return(
        <Dialog onClose={() => setAlert('')} open={alert == "kvkk"}>
          <DialogTitle>KVKK onayı gerekiyor</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bilgilerinizi güncelleyebilmemiz için KVKK onayı vermelisiniz
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )
    }

    const [submitted, setSubmitted] = useState(false);
    const [uygun, setUygun] = useState(true);
    const noTercih = useRef(true);
    const errorForm = useRef(false);

    const dataForm = useRef();
    const submitForm = () => {   
      let formData = new FormData(dataForm.current);
      //console.log(formData);
      noTercih.current = formData.get("StajDepartman1") == "" && formData.get("StajDepartman2") == "" && formData.get("StajDepartman3") == ""
      
      let uyg = true;
      const myobolum = formData.get("myobolum")?.toString();
      const bolum = formData.get("bolum")?.toString();
      const bolum2 = formData.get("ciftanadaluniversite")?.toString();

      errorForm.current = nameSurname == '' || email == '' || tcno.length < 11 || phone.length < 10
        || (semester == "_" || parseInt(semester) == 0)
        || (duration == "___" || parseInt(duration) == 0)
        || education == ""
        || mandatory == ""
        || (education == educationLevels[2] && ((myobolum ?? "") == '' || (universite ?? "") == ''))
        || (education == educationLevels[1] && ((bolum ?? "") == '' || (universite ?? "") == ''))
        || (education == educationLevels[0] && ((bolum ?? "") == '' || (universite ?? "") == ''))
        || (education == educationLevels[3] && tekniklise == '')
        || noTercih.current;
      
      //console.log("error", errorForm.current);
      // console.log("duration", duration, parseInt(duration), phone);
      //console.log(bolum, myobolum,education);
      //console.log("valid bolum", education == educationLevels[2] ? IsValidMYODept(myobolum) : education == educationLevels[1] ? IsValidDept(bolum) : "nodept");
      if (nameSurname == '' || email == '' || tcno.length < 11 || phone.length < 10
          || mandatory == mandatories[2]
          || education == educationLevels[0]
          || (education == educationLevels[1] && [0,1,2].includes(parseInt(semester)))
          || parseInt(duration) < 15
          || noTercih.current
          || (education == educationLevels[2] && !IsValidMYODept(myobolum))
          || (education == educationLevels[1] && !(IsValidDept(bolum) || IsValidDept(bolum2)))
      ){
        uyg = false;
      }
      setUygun(uyg);
      setSubmitted(true);

      stajTypes.forEach(s => {if (s[1] && !formData.getAll("staj").includes(s[0])) formData.append("staj",s[0])})

      formData.set("phone", phone);
      formData.set("phone2", phone2);
      formData.set("duration", duration);
      formData.append("id", profile.id);
      formData.append("project_id", projectID);
      formData.append("lists", "staj");
      formData.append("statu", uyg ? "Geçerli" : "Elendi");      
      formData.append("filename", file?.name ?? "");
      formData.delete("cvfile");

      //console.log(formData);

      if (file){
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          formData.append("filecontent", reader.result);
          if (!errorForm.current){
            submit(formData, {method:'POST'});
          }                
          //console.log(reader.result);
        };
        // reader.onerror = function (error) {
        //   console.log('Error: ', error);
        // };        
      } else {
        if (!errorForm.current){
          //console.log("passed");
          submit(formData, {method:'POST'});
        }              
      }
    }

    const skillCheckBox=(skill, name, size) => {
      const [checked, setChecked] = useState(skill[1]);
      const check = () => {
        skill[1] = !checked; 
        setChecked(!checked);
      }
      return (<Grid container flexWrap='nowrap' alignItems='flex-start' key={skill[0]}>
      <Checkbox sx={{paddingTop:"1px"}} size='small' checked={checked} value={skill[0]} 
        name={name} 
        onChange ={check}/>
      <FormLabel key={skill[0]} style={{color:'black', fontSize: size ?? '0.85em'}}
        onClick ={check}>
          {skill[0]}
      </FormLabel> 
      </Grid>)
    }

    const [nameSurname, setNameSurname] = useState('');
    const [email, setEmail] = useState('');
    const handleEmail = ({ target: { value } }) => setEmail(value);
    const [phone, setPhone] = useState('');
    const handlePhoneInput = ({ target: { value } }) => setPhone(value);
    const [phone2, setPhone2] = useState('');
    const handlePhone2Input = ({ target: { value } }) => setPhone2(value);
    const [tcno, setTCNO] = useState('');
    const handleTCNO = ({ target: { value } }) => setTCNO(value);
    const [semester, setSemester] = useState('');
    const handleSemester = ({ target: { value } }) => setSemester(value);
    const [duration, setDuration] = useState('');
    const handleDuration = ({ target: { value } }) => setDuration(value);

    const [file, setFile] = useState();
    const handleFileChange = (e) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
        console.log(e.target.files[0]);
      }
    };
    const handleUpload = async () => {
      if (file) {
        console.log("Uploading file...");
    
        //const formData = new FormData();
        //formData.append("file", file);
    
        try {
          // You can write the URL of your server or any other endpoint used for file upload
          // const result = await fetch("/postfile", {
          //   method: "POST",
          //   body: formData,
          // });
    
          // const data = await result.json();
    
          // console.log(data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const [sdepts,setSDepts] = useState({staj1:"", staj2:"",staj3:""});
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='tr'>
      <Grid container xs={6} item paddingLeft={"2em"} paddingBottom={'3em'}>
        {/* <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          This is a warning alert — <strong>check it out!</strong>
        </Alert> */}
        <KvkkAlert/>
        <KVKKDialog onClose={() => setKVKKOpen(false)} open={kvkkOpen} onConfirm={acceptKVKK}/>        
        <Form method='POST' ref={dataForm} name='siemensform'
          //onChange={(event) => submit(event.currentTarget)}
          >
          <FormControl margin='normal' sx={{pr:"1em"}} >          
            <img width={'250em'} src={'/pp_logo.png'} alt={"PeoplePartners"} style={{marginBottom: "1em"}}/> 
            <input type="hidden" id="elements" name="elements" value="formName=Form Adı,lastPosition=Son Pozisyon,services=Lojistik Hizmetleri,depts=Departmanlar,countries=Ülkeler,langLevel=Yabancı Dil Seviyesi,salary=Ücret Beklentisi" />
            <input type='hidden' id="formName" name="formName" value="Internship-Siemens"/>
            <input type='hidden' id="forward" name="forward" value="/confirm"/>
            <FormLabel id="namesurname-label" key='namesurname-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Adınız Soyadınız</FormLabel>
            <TextField 
              id="namesurname"
              aria-label='Name Surname'
              name="namesurname"
              style={{marginRight:"1em"}}
              size='small'  
              defaultValue={nameSurname}
              onChange={({ target: { value } }) => setNameSurname(value)}
              error = {submitted && nameSurname == ''}              
            />
            <FormLabel id="email-label" key='email-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>E-mail Adresiniz</FormLabel>
            <TextField 
                id="email"
                aria-label='Email'
                name="email"
                style={{marginRight:"1em"}}
                size='small' 
                defaultValue={email} 
                error = {submitted && email == ''} 
                onChange={({ target: { value } }) => setEmail(value)}            
              />
            {/* <InputMask 
              id="email"
              aria-label='Email'
              name="email"
              defaultValue={profile.email ?? ""}
              mask={/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g}
              value={email} 
              onChange={handleEmail}>
              {() => <TextField 
                id="email"
                aria-label='Email'
                name="email"
                defaultValue={profile.email ?? ""}              
                style={{marginRight:"1em"}}
                size='small'              
              />}
            </InputMask> */}

            <FormLabel id="tcno-label" key='tcno-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>TC Kimlik Numaranız</FormLabel>
            <InputMask 
              id="tcno"
              aria-label='TC Kimlik No'
              defaultValue={profile.tcno ?? ""}
              mask='99999999999' 
              alwaysShowMask
              value={tcno}               
              onChange={handleTCNO}>
              {() => <TextField 
                style={{marginRight:"1em"}}
                name="tcno"
                error = {submitted && tcno.length < 11} 
                size='small'              
              />}
            </InputMask>
            <FormLabel id="gsmnumber-label" key='gsmnumber-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Cep Telefonunuz</FormLabel>
            <InputMask 
              id="phone"
              aria-label='GSM Number'                         
              mask='(599) 999 9999' 
              alwaysShowMask
              value={phone} 
              defaultValue={""}            
              onChange={handlePhoneInput}>
              {() => <TextField 
                style={{marginRight:"1em"}}
                name="phone" 
                error = {submitted && phone.length < 10} 
                size='small'              
              />}
            </InputMask>
            <FormLabel id="gsmnumber2-label" key='gsmnumber2-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Varsa size ulaşabileceğimiz 2. telefon numarası</FormLabel>
            <InputMask 
              id="phone2"
              aria-label='GSM Number'                          
              mask='(599) 999 9999' 
              alwaysShowMask
              value={phone2} 
              defaultValue={profile.phone2 ?? ""}            
              onChange={handlePhone2Input}>
              {() => <TextField 
                name="phone2"
                style={{marginRight:"1em"}}
                size='small'              
              />}
            </InputMask>
            <FormLabel id="mandatory-label" key='mandatory-label' error={submitted && mandatory == ''} sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Staj zorunluluğunuz var mı?</FormLabel>
            <RadioGroup
              style={{marginBottom:'1em'}}
              name="mandatory"
              value={mandatory}                          
              onChange={(event,newValue) => setMandatory(newValue)}              
            >
              {mandatories.map(lp => <FormControlLabel key={lp} value={lp} control={<Radio />} label={lp} />)}
            </RadioGroup>  
            <FormLabel id="educationLevel-label" key='educationLevel-label' error={submitted && education == ''}
              sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Hangi öğrenim kurumuna devam etmektesiniz?</FormLabel>
            <RadioGroup
              style={{marginBottom:'1em'}}
              name="education"
              value={education}                          
              onChange={(event,newValue) => setEducation(newValue)}              
            >
              {educationLevels.map(lp => <FormControlLabel key={lp} value={lp} control={<Radio />} label={lp} />)}
            </RadioGroup>  

            <Box display={education == "Teknik Lise" ? 'grid' : "none"}>
              <FormLabel id="tekniklise-label" key='tekniklise-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Lise Adı</FormLabel>
              <TextField 
                  id="tekniklise"
                  aria-label='Teknik Lise'
                  name="tekniklise"
                  style={{marginRight:"1em"}}
                  size='small'  
                  value={tekniklise}   
                  error={tekniklise == ''}
                  onChange={({ target: { value } }) => setTeknikLise(value)}         
              />
            </Box>

            <FormLabel id="semester-label" key='semester-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Devam ettiğiniz okulda 2024 yazında kaçıncı sınıfı bitirmiş olacaksınız?</FormLabel>
            <FormLabel id="semester-exp-label" key='semester-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>(Örneğin 4 yıllık bir bölüme devam eden öğrenci, bu yaz 3. sınıfı tamamlayacak ve yaz tatilinde
staj yapmak istiyorsa 3. sınıf seçeneğini seçmelidir, hazırlığı saymayınız)</FormLabel>
            <InputMask 
              id="semester"
              aria-label='Eğitim Yılı'              
              mask='9' 
              alwaysShowMask
              value={semester} 
              onChange={handleSemester}>
              {() => <TextField 
                style={{marginRight:"1em"}}
                name="semester"
                error = {submitted && (semester == "_" || parseInt(semester) == 0)} 
                size='small'              
              />}
            </InputMask>
            <Box display={education != "Teknik Lise" ? 'grid' : "none"}>
              <FormLabel id="univ-label" key='univ-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Okulunuzun Adı</FormLabel>
              <FormLabel id="univ-exp-label" key='univ-exp-label' sx={{ marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>(Aramayı büyük harflerle yapın)</FormLabel>
              <Autocomplete
                disablePortal
                disableListWrap={true}
                id="univ-combo"              
                options={Universities}
                size='small'
                sx={{ width: 400}}                
                onChange={({target})  => setUniversite(target.innerText)}
                renderInput={(params) => <TextField name='universite' 
                error={submitted && (universite == undefined || universite=='')} {...params} />}
              />
            </Box>
            <Box display={education == "Lisans (4 Yıllık Üniversite)" || education == "Yüksek Lisans" ? 'grid' : "none"}>
              <FormLabel id="dept-label" key='dept-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Hangi bölümde öğrenim görmektesiniz?</FormLabel>
              <Autocomplete
                disablePortal
                disableListWrap={true}
                id="dept-combo"              
                options={Departments}
                sx={{ width: 400 }}
                size='small'
                onChange={({target})  => setBolum(target.innerText)}
                renderInput={(params) => <TextField name='bolum' error={submitted && (bolum == undefined || bolum=='')} {...params} />}
              />
            </Box>
            <Box display={education == "Önlisans (2 Yıllık MYO)" ? 'grid' : "none"}>
              <FormLabel id="myo-label" key='myo-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Hangi bölümde öğrenim görmektesiniz?</FormLabel>
              <Autocomplete
                disablePortal
                disableListWrap={true}
                id="myodept-combo"              
                options={MYODepartments}
                size='small'
                sx={{ width: 400 }}
                onChange={({target})  => setMYOBolum(target.innerText)}
                renderInput={(params) => <TextField  name='myobolum' error={submitted && (!myobolum || myobolum == '')} {...params} />}
              />
            </Box>
            <Box display={education == "Lisans (4 Yıllık Üniversite)" || education == "Yüksek Lisans" ? 'grid' : "none"}>
              <FormLabel id="deptcift-label" key='deptyan-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Varsa çift anadal bölümünüzü belirtiniz:</FormLabel>
              <Autocomplete
                disablePortal
                disableListWrap={true}
                id="deptcift-combo"    
                size='small'          
                options={Departments}
                sx={{ width: 400 }}
                renderInput={(params) => <TextField name='ciftanadaluniversite' {...params} />}
              />
            </Box>
            <FormLabel id="not-label" key='not-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Not ortalamanız</FormLabel>
            <TextField 
                id="not"
                aria-label='Not ortalaması'
                name="not"
                style={{marginRight:"1em"}}
                size='small'              
            />
            <FormLabel id="duration-label" key='duration-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Devam ettiğiniz eğitim kurumunun bu yaz için belirlediği staj zorunluluk gün süresini belirtiniz</FormLabel>
            <InputMask 
              id="duration"
              aria-label='Staj süresi'              
              mask='999' 
              value={duration} 
              onChange={handleDuration}>
              {() => <TextField 
                style={{marginRight:"1em"}}
                name="duration"
                size='small'   
                error = {submitted && duration.length == 0}        
              />}
            </InputMask>
            <FormLabel id="dates-label" key='dates-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Staj yapmanız gereken tarih aralığını belirtiniz.</FormLabel>
            <FormLabel id="dates-exp-label" key='dates-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>(Okulunuz sadece belli tarih aralığında öğrencilerini sigortalıyorsa bu tarihler arasında
staj yapabilirsiniz)</FormLabel>
            <Box sx={{display: 'flex', columnGap: '1em'}}>
              <MobileDatePicker name='stajbaslangic'/>
              <MobileDatePicker name='stajbitis'/>
            </Box>

            <FormLabel id="stajdept-label" key='stajdept-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Stajınızı yapmayı tercih ettiğiniz bölüm ve lokasyonları belirtiniz.</FormLabel>
            <FormLabel id="stajdept-exp-label" key='stajdept-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>(Tercihlerinizi belirtmeden önce web sayfamızdan bölümlerle ilgili bilgi edinmeniz önemlidir. Stajyer
seçme yerleştirme sürecinde tercihleriniz göz önünde bulundurulur ancak kesin olarak bu bölümlerden
birine yerleştirileceğiniz garantisi verilememektedir.)</FormLabel>
            <Autocomplete
              disablePortal
              disableListWrap={true}
              id="stajdept1"              
              options={stajDepts}
              sx={{ width: 400 }}
              size='small'
              onChange={({target})  => setSDepts({...sdepts, staj1: target.innerText ?? ""})}
              renderInput={(params) => <TextField name='StajDepartman1' error={submitted && sdepts.staj1 == "" && sdepts.staj2 == "" && sdepts.staj3 == ""} {...params} placeholder='1. tercihiniz'/>}
            />
            <Autocomplete
              disablePortal
              disableListWrap={true}
              id="stajdept2"              
              options={stajDepts}
              size='small'              
              // ListboxProps={{sx: {}}}
              sx={{ width: 400, mt: '0.5em'}}
              onChange={({target})  => setSDepts({...sdepts, staj2: target.innerText ?? ""})}
              renderInput={(params) => <TextField name='StajDepartman2' error={submitted && sdepts.staj1 == "" && sdepts.staj2 == "" && sdepts.staj3 == ""} {...params} placeholder='2. tercihiniz'/>}
            />
            <Autocomplete
              disablePortal
              disableListWrap={true}
              id="stajdept3"              
              options={stajDepts}
              sx={{ width: 400, mt: '0.5em' }}
              size='small'
              onChange={({target})  => setSDepts({...sdepts, staj1: target.innerText ?? ""})}
              renderInput={(params) => <TextField name='StajDepartman3' error={submitted && sdepts.staj1 == "" && sdepts.staj2 == "" && sdepts.staj3 == ""} {...params} placeholder='3. tercihiniz'/>}
            />
            <TextField 
              sx={{mt: '1em'}}
              id="notlar"
              aria-label='Notlar'
              name='notlar'
              placeholder='Bu alana bu yaz yapmanız gereken stajınızın içeriği/kapsamı hakkında detaylı açıklama yazmanızı
              rica ederiz.'
              multiline
              rows={5}            
            />

            <FormLabel id="stajzorunluluk-label" key='stajzorunluluk-label' sx={{color:labelcolor, fontSize:'1em', mt: '1em'}}>Devam ettiğiniz eğitim kurumunun bu yaz için belirlediği staj zorunluluk şeklini belirtiniz.</FormLabel>
            <FormLabel id="stajzorunluluk-exp-label" key='stajzorunluluk-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>(eğer birden fazla şekilde çalışmak sizin için uygun ise lütfen uygun olan seçenekleri işaretleyiniz)</FormLabel>
            <FormGroup style={{marginLeft:'1em', marginBottom:'0.5em', paddingTop:"1em"}}>
              {stajTypes.map(skill => (skillCheckBox(skill,"staj",'1em')))}
            </FormGroup>

            <FormLabel id="prevstaj-label" key='prevstaj-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Önceki stajlarınız</FormLabel>
            <FormLabel id="prevstaj-exp-label" key='prevstaj-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>Eğer yaptıysanız geçtiğimiz yıllardaki stajlarınızın kurum ve bölüm isimlerini yazınız</FormLabel>
            <TextField 
              id="prevstaj1"
              aria-label='Önceki staj 1'
              name='prevstaj1'
              placeholder='1.Staj'
              multiline
              rows={2}            
            />
            <TextField 
              id="prevstaj2"
              aria-label='Önceki staj 2'
              name='prevstaj2'
              placeholder='2.Staj'
              multiline
              sx={{mt: '0.5em'}}
              rows={2}            
            />
            <TextField 
              id="prevstaj3"
              aria-label='Önceki staj 3'
              name='prevstaj3'
              placeholder='3.Staj'
              multiline
              sx={{mt: '0.5em'}}
              rows={2}            
            />

            <FormLabel id="tercihneden-label" key='tercihneden-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Siemens'i tercih etme nedenleriniz</FormLabel>
            <TextField 
              id="tercihneden"
              aria-label='Tercih nedenleriniz'
              name='tercihneden'
              multiline
              rows={3}            
            />

            <FormLabel key='level' sx={{mt:'1em', color:labelcolor, fontSize:'1em'}} id="tools-label">İngilizce seviyeniz nedir ?</FormLabel>
            <RadioGroup
              name="langLevel"
              value={langLevel}            
              onChange={(event,newValue) => setLangLevel(newValue)}              
            >
              {langLevels.map(lp => <FormControlLabel key={lp} value={lp} control={<Radio />} label={lp} />)}
            </RadioGroup>  

            <FormLabel id="dlanglevel-label" key='dlanglevel-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Diğer yabancı dil bilgileriniz</FormLabel>
            <FormLabel id="dlanglevel-exp-label" key='dlanglevel-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>Lütfen temel, orta, ileri olarak belirtiniz</FormLabel>
              <Box sx={{display:'flex', columnGap:'0.5em'}}>
                <Autocomplete
                  disablePortal
                  disableListWrap={true}
                  id="language1"              
                  options={langs}
                  sx={{ width: 200, mt: '0.5em' }}
                  size='small'
                  renderInput={(params) => <TextField name='language1' size='small' {...params} placeholder='Yabancı Dil'/>}
                />
                <Autocomplete
                  disablePortal
                  disableListWrap={true}
                  id="language1-level"              
                  options={langLevels}
                  sx={{ width: 200, mt: '0.5em' }}
                  size='small'
                  renderInput={(params) => <TextField name='language1level' size='small' {...params} placeholder='Seviye'/>}
                />                
              </Box>
              <Box sx={{display:'flex', columnGap:'0.5em'}}>
              <Autocomplete
                disablePortal
                disableListWrap={true}
                id="language2"              
                options={langs}
                sx={{ width: 200, mt: '0.5em' }}
                size='small'
                renderInput={(params) => <TextField name='language2' size='small' {...params} placeholder='Yabancı Dil'/>}
              />
              <Autocomplete
                  disablePortal
                  disableListWrap={true}
                  id="language2-level"              
                  options={langLevels}
                  sx={{ width: 200, mt: '0.5em' }}
                  size='small'
                  renderInput={(params) => <TextField name='language2level' size='small' {...params} placeholder='Seviye'/>}
                />                
              </Box>
              <Box sx={{display:'flex', columnGap:'0.5em'}}>
              <Autocomplete
                disablePortal
                disableListWrap={true}
                id="language3"              
                options={langs}
                sx={{ width: 200, mt: '0.5em' }}
                size='small'
                renderInput={(params) => <TextField name='language3' size='small' {...params} placeholder='Yabancı Dil'/>}
              />
              <Autocomplete
                  disablePortal
                  disableListWrap={true}
                  id="language3-level"              
                  options={langLevels}
                  sx={{ width: 200, mt: '0.5em' }}
                  size='small'
                  renderInput={(params) => <TextField name='language3level' size='small' {...params} placeholder='Seviye'/>}
                />                
              </Box>
              <TextField 
                id="dlanglevel"
                sx={{mt:'0.5em'}}
                aria-label='Diğer yabancı diller'
                placeholder='Bildiğiniz diğer yabancı diller ve seviyelerini buraya yazabilirsiniz'
                name='dlanglevel'
                multiline
                size='small'
                rows={3}            
              />
            <FormLabel id="msoffice-label" key='msoffice-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Microsoft Office bilgileriniz</FormLabel>
            <RadioGroup
              style={{marginBottom:'1em'}}
              name="msoffice"
              value={msoffice}            
              onChange={(event,newValue) => setMsoffice(newValue)}              
            >
              {msofficeLevels.map(lp => <FormControlLabel key={lp} value={lp} control={<Radio />} label={lp} />)}
            </RadioGroup>  
            <FormLabel id="dcomp-label" key='dcomp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>Diğer bilgisayar bilgileriniz</FormLabel>
            <FormLabel id="dcomp-exp-label" key='dcomp-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>Lütfen temel, orta, ileri olarak belirtiniz</FormLabel>
            <TextField 
              id="dcomp"
              aria-label='Diğer bilgisayar bilgileriniz'
              name='dcomp'
              placeholder='1.bilgisayar bilgisi ve seviyesi, 2.bilgisayar bilgisi ve seviyesi, 3.bilgisayar bilgisi ve seviyesi'
              multiline
              rows={3}            
            />
            <FormLabel id="adres-label" key='adres-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>İkamet ettiğiniz adresiniz</FormLabel>
            <FormLabel id="adres-exp-label" key='adres-exp-label' sx={{marginTop:'0.5em', marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>Yaz döneminde staj yapacağınız sürede ikamet edeceğiz adres</FormLabel>
            <TextField 
              id="adres"
              aria-label='Adres'
              name='adres'
              size='small'
              placeholder='Adres'
              multiline
              rows={2}            
            />
            <TextField 
              id="semt"
              aria-label='Semt'
              name='semt'
              size='small'
              placeholder='Semt'
              sx={{mt: '0.5em'}}
            />
            <TextField 
              id="sehir"
              aria-label='Şehir'
              name='sehir'
              size='small'
              placeholder='Şehir'
              sx={{mt: '0.5em'}}
            />
            <FormLabel id="cv-label" key='cv-label' sx={{marginTop:'1em', marginBottom:"0.6em", color:labelcolor, fontSize:'1em'}}>CV Dosyanız</FormLabel>
            <FormLabel id="cv-exp-label" key='cv-exp-label' sx={{marginBottom:"0.6em", color:'grey', fontSize:'0.7em'}}>(PDF, DOC, DOCX formatında dosya yükleyebilirsiniz)</FormLabel>
            <Box display={'flex'} alignItems={'center'}>
            <FormLabel htmlFor='cvfile'>
              <input
                style={{ display: 'none' }}
                id="cvfile"
                name="cvfile"
                type="file"
                onChange={handleFileChange}
                accept={".doc,.docx,.pdf"}

              />
              <Button sx={{alignSelf:'start', marginBottom:'1em', marginTop:'0.2em', marginRight:'0.5em'}} 
                variant="outlined" component="span">
                DOSYA SEÇİN
              </Button>
            </FormLabel>

              {/* <Input id='cvfile' name='cvfile' type='file' onChange={handleFileChange} accept={".doc,.docx,.pdf"} />
              <input id="cvfile" name="cvfile" type="file" form='siemensform' onChange={handleFileChange} accept='.doc,.docx,.pdf'  /> */}
              {file && <Typography>{file?.name ?? ""}</Typography>}
            </Box>


            <Button sx={{width:'200px', alignSelf:'center', marginBottom:'1em', marginTop:'2em'
              , backgroundColor: kvkkOK ? '.MuiButton-contained.backgroundColor' : 'gray'}} 
              to="/profile" variant="contained" 
              onClick={() => kvkkOK ? submitForm() : setAlert("kvkk")}               >
              Güncelle
            </Button>
            
            {/* <Box sx={{display: submitted ? 'grid' : 'none'}}>
              <Typography sx={{color: uygun ? 'green' : 'red'}}>{uygun ? "GEÇERLİ" : "ELENDİ"}</Typography>
            </Box> */}

            <Box sx={{display: submitted && errorForm.current ? 'grid' : 'none'}}>
              <Typography sx={{color: 'red'}}>{"Doldurmanız gereken alanlar var"}</Typography>
            </Box>

            <FormControlLabel             
              control={<Checkbox id='kvkk-check' value={kvkkOK} checked={kvkkOK} 
                onChange={() => setKvkkTime("")} disabled={!kvkkOK} />} 
                label={<Typography onClick={() => {
                  setKVKKOpen(true);                  
                }} variant="subtitle2" component={Link}>
                KVKK kapsamında kişisel verilerimin işlenmesi ile ilgili bilgilendirme metnini okuduğumu ve kabul ettiğimi beyan ederim.
              </Typography>}             
            />
            <TextField value={kvkkTime} sx={{display:'none'}} id="kvvk-timestamp" name="kvkkTimeStamp" label="KVKK Timestamp" variant="standard" />
          </FormControl>
        </Form>
      </Grid>
      </LocalizationProvider>
    );
  }