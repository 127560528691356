const Departments = ["Psikoloji (İngilizce)",
"Siyaset Bilimi ve Uluslararası İlişkiler (İngilizce)",
"Mimarlık (İngilizce)",
"Bilgisayar Mühendisliği (İngilizce)",
"Elektrik-Elektronik Mühendisliği (İngilizce)",
"Endüstri Mühendisliği (İngilizce)",
"İnşaat Mühendisliği (İngilizce)",
"Makine Mühendisliği (İngilizce)",
"Biyomühendislik (İngilizce)",
"Moleküler Biyoloji ve Genetik (İngilizce)",
"Moleküler Biyoloji ve Genetik (İngilizce) (KKTC Uyruklu)",
"Ekonomi (İngilizce)",
"İşletme (İngilizce)",
"Yapay Zeka Mühendisliği (İngilizce)",
"Yazılım Mühendisliği (İngilizce)",
"Havacılık ve Uzay Mühendisliği (İngilizce)",
"Havacılık ve Uzay Mühendisliği (İngilizce) (KKTC Uyruklu)",
"İngilizce Mütercim ve Tercümanlık",
"Turizm İşletmeciliği (İngilizce)",
"Uluslararası Ticaret ve Finansman (İngilizce)",
"Yönetim Bilişim Sistemleri (İngilizce)",
"Mimarlık",
"Enerji Sistemleri Mühendisliği (İngilizce)",
"Gıda Mühendisliği",
"Malzeme Bilimi ve Mühendisliği (İngilizce)",
"Siyaset Bilimi ve Kamu Yönetimi (İngilizce)",
"Uluslararası İlişkiler (İngilizce)",
"Diş Hekimliği",
"Eczacılık",
"Arapça Öğretmenliği",
"Fen Bilgisi Öğretmenliği",
"İlköğretim Matematik Öğretmenliği",
"Okul Öncesi Öğretmenliği",
"Özel Eğitim Öğretmenliği",
"Rehberlik ve Psikolojik Danışmanlık",
"Sınıf Öğretmenliği",
"Sosyal Bilgiler Öğretmenliği",
"Türkçe Öğretmenliği",
"Arkeoloji",
"Biyoloji",
"İngiliz Dili ve Edebiyatı (İngilizce)",
"Matematik",
"Sanat Tarihi",
"Sosyoloji",
"Tarih",
"Türk Dili ve Edebiyatı",
"İktisat",
"İşletme",
"Siyaset Bilimi ve Kamu Yönetimi",
"İslami İlimler",
"İslami İlimler (M.T.O.K.)",
"Bilgisayar Mühendisliği",
"Elektrik-Elektronik Mühendisliği",
"İnşaat Mühendisliği",
"Makine Mühendisliği",
"Tekstil Mühendisliği",
"Ebelik",
"Hemşirelik",
"Tıp",
"Turizm Rehberliği",
"Bitki Koruma",
"Bankacılık ve Sigortacılık",
"Lojistik Yönetimi",
"Muhasebe ve Finans Yönetimi",
"Endüstriyel Tasarım",
"Sigortacılık",
"İngilizce Öğretmenliği (İngilizce)",
"Coğrafya",
"Coğrafya (İÖ)",
"Çağdaş Türk Lehçeleri ve Edebiyatları",
"Felsefe",
"Kimya",
"Moleküler Biyoloji ve Genetik",
"Psikoloji",
"Sosyoloji (İÖ)",
"Tarih (İÖ)",
"Türk Dili ve Edebiyatı (İÖ)",
"İç Mimarlık ve Çevre Tasarımı",
"İç Mimarlık ve Çevre Tasarımı (İÖ)",
"İç Mimarlık ve Çevre Tasarımı (KKTC Uyruklu)",
"Sinema ve Televizyon",
"Hukuk",
"Maliye",
"Maliye (İÖ)",
"Siyaset Bilimi ve Kamu Yönetimi (İÖ)",
"Uluslararası Ticaret ve Finansman",
"Yönetim Bilişim Sistemleri",
"İslami İlimler (İÖ)",
"İslami İlimler (M.T.O.K.) (İÖ)",
"Biyomedikal Mühendisliği",
"Biyomedikal Mühendisliği (KKTC Uyruklu)",
"Elektrik Mühendisliği",
"Geomatik Mühendisliği",
"Kimya Mühendisliği",
"Maden Mühendisliği",
"Çocuk Gelişimi",
"Sosyal Hizmet",
"Elektrik-Elektronik Mühendisliği (M.T.O.K.)",
"Mekatronik Mühendisliği",
"Otomotiv Mühendisliği",
"Gastronomi ve Mutfak Sanatları",
"Gastronomi ve Mutfak Sanatları (İÖ)",
"Turizm İşletmeciliği",
"Turizm Rehberliği (İÖ)",
"Veteriner",
"Veteriner (KKTC Uyruklu)",
"Beslenme ve Diyetetik",
"Fizyoterapi ve Rehabilitasyon",
"Fizyoterapi ve Rehabilitasyon (İÖ)",
"Sağlık Yönetimi",
"Sağlık Yönetimi (İÖ)",
"Hayvansal Üretim ve Teknolojileri",
"Rus Dili ve Edebiyatı",
"Halkla İlişkiler ve Reklamcılık",
"Beslenme ve Diyetetik (İÖ)",
"İngilizce Mütercim ve Tercümanlık (İÖ)",
"Alman Dili ve Edebiyatı",
"Eski Yunan Dili ve Edebiyatı",
"İngiliz Dili ve Edebiyatı (İngilizce) (İÖ)",
"Latin Dili ve Edebiyatı",
"Rus Dili ve Edebiyatı (KKTC Uyruklu)",
"İngilizce Öğretmenliği",
"Fizik",
"Fizik (KKTC Uyruklu)",
"Kimya (KKTC Uyruklu)",
"Uzay Bilimleri ve Teknolojileri",
"Çalışma Ekonomisi ve Endüstri İlişkileri",
"Ekonometri",
"Uluslararası İlişkiler",
"Uluslararası İlişkiler (İÖ)",
"İlahiyat",
"İlahiyat (M.T.O.K.)",
"Gazetecilik",
"Halkla İlişkiler ve Tanıtım",
"Radyo, Televizyon ve Sinema",
"Reklamcılık",
"Denizcilik İşletmeleri Yönetimi",
"Yönetim Bilişim Sistemleri (KKTC Uyruklu)",
"Rekreasyon Yönetimi",
"İç Mimarlık",
"Peyzaj Mimarlığı",
"Şehir ve Bölge Planlama",
"Bilgisayar Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Çevre Mühendisliği",
"Elektrik-Elektronik Mühendisliği (İÖ)",
"Elektrik-Elektronik Mühendisliği (KKTC Uyruklu)",
"Jeoloji Mühendisliği",
"Gerontoloji",
"Ekonomi ve Finans",
"Spor Yöneticiliği",
"Su Ürünleri Mühendisliği",
"Finans ve Bankacılık",
"Finans ve Bankacılık (KKTC Uyruklu)",
"Pazarlama",
"Pazarlama (İÖ)",
"Uluslararası Ticaret ve Lojistik",
"Uluslararası Ticaret ve Lojistik (İÖ)",
"Yönetim Bilişim Sistemleri (İÖ)",
"Bahçe Bitkileri",
"Tarım Ekonomisi",
"Tarım Ekonomisi (KKTC Uyruklu)",
"Tarım Makineleri ve Teknolojileri Mühendisliği",
"Tarımsal Biyoteknoloji",
"Tarımsal Yapılar ve Sulama",
"Tarla Bitkileri",
"Toprak Bilimi ve Bitki Besleme",
"Zootekni",
"Endüstri Mühendisliği",
"Yazılım Mühendisliği",
"Acil Yardım ve Afet Yönetimi",
"Havacılık Yönetimi",
"İnsan Kaynakları Yönetimi",
"Uluslararası Ticaret",
"Biyosistem Mühendisliği",
"Genetik ve Biyomühendislik (İngilizce)",
"Gıda Mühendisliği (İngilizce)",
"İlahiyat (İÖ)",
"İlahiyat (M.T.O.K.) (İÖ)",
"Kentsel Tasarım ve Peyzaj Mimarlığı",
"Felsefe (Açıköğretim)",
"Görsel İletişim Tasarımı (Açıköğretim)",
"Halkla İlişkiler ve Reklamcılık (Açıköğretim)",
"Sağlık Yönetimi (Açıköğretim)",
"Sosyal Hizmet (Açıköğretim)",
"Sosyoloji (Açıköğretim)",
"Tarih (Açıköğretim)",
"Türk Dili ve Edebiyatı (Açıköğretim)",
"Yönetim Bilişim Sistemleri (Açıköğretim)",
"Rus Dili ve Edebiyatı (Rusça)",
"Almanca Öğretmenliği",
"Bilgisayar ve Öğretim Teknolojileri Öğretmenliği",
"Fransızca Öğretmenliği",
"İngilizce Öğretmenliği (İngilizce) (UOLP-Suny Cortland) (Ücretli)",
"İktisat (İngilizce)",
"İktisat (İngilizce) (UOLP-Suny Cortland) (Ücretli)",
"İşletme (İngilizce) (UOLP-Suny Empire State College) (Ücretli)",
"Maliye (KKTC Uyruklu)",
"Çalışma Ekonomisi ve Endüstri İlişkileri (Açıköğretim)",
"İktisat (Açıköğretim)",
"İktisat (İngilizce) (Açıköğretim)",
"Maliye (Açıköğretim)",
"Siyaset Bilimi ve Kamu Yönetimi (Açıköğretim)",
"Siyaset Bilimi ve Kamu Yönetimi (İngilizce) (Açıköğretim)",
"Uluslararası İlişkiler (Açıköğretim)",
"Uluslararası İlişkiler (İngilizce) (Açıköğretim)",
"İletişim Tasarımı ve Yönetimi",
"Havacılık Yönetimi (Açıköğretim)",
"İşletme (Açıköğretim)",
"İşletme (İngilizce) (Açıköğretim)",
"Uluslararası Ticaret ve Lojistik (Açıköğretim)",
"Dil ve Konuşma Terapisi",
"Dil ve Konuşma Terapisi (KKTC Uyruklu)",
"Turizm Rehberliği (KKTC Uyruklu)",
"Arapça Mütercim ve Tercümanlık (Arapça)",
"Çin Dili ve Edebiyatı",
"Fransız Dili ve Edebiyatı",
"Türk Halkbilimi",
"Fotoğraf ve Video",
"Görsel İletişim Tasarımı",
"Kültür Varlıklarını Koruma ve Onarım",
"Sağlık Yönetimi (KKTC Uyruklu)",
"Gazetecilik (KKTC Uyruklu)",
"El Sanatları",
"El Sanatları (M.T.O.K.)",
"Geleneksel Türk Sanatları (M.T.O.K.)",
"Görsel Sanatlar (M.T.O.K.)",
"Grafik Tasarımı (M.T.O.K.)",
"Kuyumculuk ve Mücevher Tasarımı",
"Moda Tasarımı",
"Moda Tasarımı (M.T.O.K.)",
"Tekstil Tasarımı",
"Tekstil Tasarımı (M.T.O.K.)",
"Emlak ve Emlak Yönetimi",
"Tapu Kadastro",
"Gastronomi ve Mutfak Sanatları (M.T.O.K.)",
"Rekreasyon Yönetimi (M.T.O.K.)",
"Seyahat İşletmeciliği ve Turizm Rehberliği",
"Ekonomi (İngilizce) (KKTC Uyruklu)",
"Sosyoloji (İngilizce)",
"Sosyoloji (İngilizce) (KKTC Uyruklu)",
"Tarih (İngilizce)",
"Tarih (İngilizce) (KKTC Uyruklu)",
"Japonca Mütercim ve Tercümanlık",
"Rusça Mütercim ve Tercümanlık",
"Hukuk (Ücretli)",
"Hukuk (Burslu)",
"Hukuk (%50 İndirimli)",
"İktisat (İngilizce) (Ücretli)",
"İktisat (İngilizce) (Burslu)",
"İktisat (İngilizce) (%50 İndirimli)",
"İlahiyat (Burslu)",
"İngilizce Öğretmenliği (Ücretli)",
"İngilizce Öğretmenliği (Burslu)",
"İngilizce Öğretmenliği (%50 İndirimli)",
"Özel Eğitim Öğretmenliği (Ücretli)",
"Özel Eğitim Öğretmenliği (Burslu)",
"Özel Eğitim Öğretmenliği (%50 İndirimli)",
"Psikoloji (Ücretli)",
"Psikoloji (Burslu)",
"Psikoloji (%50 İndirimli)",
"Rehberlik ve Psikolojik Danışmanlık (Ücretli)",
"Rehberlik ve Psikolojik Danışmanlık (Burslu)",
"Rehberlik ve Psikolojik Danışmanlık (%50 İndirimli)",
"Uluslararası Girişimcilik (İngilizce) (Ücretli)",
"Uluslararası Girişimcilik (İngilizce) (Burslu)",
"Uluslararası Girişimcilik (İngilizce) (%50 İndirimli)",
"Uluslararası Ticaret ve Lojistik (İngilizce) (Ücretli)",
"Uluslararası Ticaret ve Lojistik (İngilizce) (Burslu)",
"Uluslararası Ticaret ve Lojistik (İngilizce) (%50 İndirimli)",
"Arap Dili ve Edebiyatı (Uzaktan Öğretim)",
"Coğrafya (Uzaktan Öğretim)",
"Elektronik Ticaret ve Yönetimi (Açıköğretim)",
"Gazetecilik (Uzaktan Öğretim)",
"Halkla İlişkiler ve Tanıtım (Uzaktan Öğretim)",
"İngiliz Dili ve Edebiyatı (İngilizce) (Uzaktan Öğretim)",
"Radyo, Televizyon ve Sinema (Uzaktan Öğretim)",
"Rus Dili ve Edebiyatı (Rusça) (Uzaktan Öğretim)",
"Sanat Tarihi (Uzaktan Öğretim)",
"Sinoloji (Uzaktan Öğretim)",
"Sosyal Hizmet (Uzaktan Öğretim)",
"Alman Dili ve Edebiyatı (Almanca)",
"Amerikan Kültürü ve Edebiyatı (İngilizce)",
"Antropoloji",
"Arap Dili ve Edebiyatı",
"Arkeoloji (İngilizce)",
"Bilgi ve Belge Yönetimi",
"Bulgar Dili ve Edebiyatı (Bulgarca)",
"Coğrafya (İngilizce)",
"Çağdaş Yunan Dili ve Edebiyatı",
"Çağdaş Yunan Dili ve Edebiyatı (KKTC Uyruklu)",
"Dilbilimi",
"Ermeni Dili ve Kültürü (Ermenice)",
"Fars Dili ve Edebiyatı",
"Felsefe (İngilizce)",
"Fransız Dili ve Edebiyatı (Fransızca)",
"Halkbilimi",
"Hindoloji",
"Hititoloji",
"Hungaroloji",
"İspanyol Dili ve Edebiyatı (İspanyolca)",
"İtalyan Dili ve Edebiyatı (İtalyanca)",
"İtalyan Dili ve Edebiyatı (İtalyanca) (KKTC Uyruklu)",
"Japon Dili ve Edebiyatı",
"Klasik Arkeoloji",
"Kore Dili ve Edebiyatı",
"Kore Dili ve Edebiyatı (KKTC Uyruklu)",
"Leh Dili ve Edebiyatı (Lehçe)",
"Protohistorya ve Ön Asya Arkeolojisi",
"Sanat Tarihi (KKTC Uyruklu)",
"Sinoloji",
"Sümeroloji",
"Tarih Öncesi Arkeolojisi",
"Urdu Dili ve Edebiyatı",
"Yunan Dili ve Edebiyatı",
"Yunan Dili ve Edebiyatı (KKTC Uyruklu)",
"Diş Hekimliği (İngilizce)",
"Eczacılık (İngilizce)",
"Astronomi ve Uzay Bilimleri",
"Astronomi ve Uzay Bilimleri (KKTC Uyruklu)",
"Bilgisayar Bilimleri",
"Biyoloji (İngilizce)",
"İstatistik",
"Kimya (İngilizce)",
"Matematik (İngilizce)",
"İlahiyat (İngilizce)",
"Bilgisayar Mühendisliği (İngilizce) (UOLP-Azerbaycan Teknik Üniversitesi) (Ücretli)",
"Biyomedikal Mühendisliği (İngilizce)",
"Fizik Mühendisliği (İngilizce)",
"Jeofizik Mühendisliği (İngilizce)",
"Jeoloji Mühendisliği (İngilizce)",
"Kimya Mühendisliği (İngilizce)",
"Yapay Zeka ve Veri Mühendisliği",
"Ortez ve Protez",
"Politika ve Ekonomi (İngilizce)",
"Tıp (İngilizce)",
"Aktüerya Bilimleri",
"Gayrimenkul Geliştirme ve Yönetimi",
"Veteriner (İngilizce)",
"Veteriner (İngilizce) (KKTC Uyruklu)",
"Su Ürünleri Mühendisliği (İngilizce)",
"Süt Teknolojisi",
"Arapça Mütercim ve Tercümanlık",
"Finans ve Bankacılık (İngilizce)",
"Uluslararası Ticaret ve İşletmecilik (İngilizce)",
"Matematik (İngilizce) (KKTC Uyruklu)",
"Metalurji ve Malzeme Mühendisliği (İngilizce)",
"Yazılım Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Odyoloji",
"Maliye (İngilizce)",
"Tıp (KKTC Uyruklu)",
"Tıp (İngilizce) (KKTC Uyruklu)",
"Beden Eğitimi ve Spor Öğretmenliği",
"Orman Mühendisliği",
"Çocuk Gelişimi (Açıköğretim)",
"Egzersiz ve Spor Bilimleri (Açıköğretim)",
"Grafik Sanatlar (Açıköğretim)",
"Halkla İlişkiler ve Tanıtım (Açıköğretim)",
"Kamu Yönetimi (Açıköğretim)",
"Reklamcılık (Açıköğretim)",
"Rekreasyon (Açıköğretim)",
"Tekstil ve Moda Tasarımı (Açıköğretim)",
"Amerikan Kültürü ve Edebiyatı",
"Arap Dili ve Edebiyatı (İÖ)",
"Bilgi ve Belge Yönetimi (İÖ)",
"Sanat Tarihi (İÖ)",
"Fotoğraf",
"Hemşirelik (İngilizce)",
"Kamu Yönetimi",
"Kamu Yönetimi (İÖ)",
"İlahiyat (Arapça)",
"İlahiyat (Arapça) (M.T.O.K.)",
"Gazetecilik (İÖ)",
"Halkla İlişkiler ve Tanıtım (İÖ)",
"Radyo, Televizyon ve Sinema (İÖ)",
"Biyoloji Öğretmenliği",
"Coğrafya Öğretmenliği",
"Felsefe Grubu Öğretmenliği",
"Kimya Öğretmenliği",
"Matematik Öğretmenliği",
"Matematik Öğretmenliği (KKTC Uyruklu)",
"Tarih Öğretmenliği",
"Türk Dili ve Edebiyatı Öğretmenliği",
"Bilgisayar Mühendisliği (İÖ)",
"Bilişim Sistemleri ve Teknolojileri",
"Bitki Koruma (KKTC Uyruklu)",
"İktisat (İÖ)",
"Uluslararası İlişkiler (İngilizce) (İÖ)",
"Halkla İlişkiler ve Reklamcılık (İÖ)",
"Çalışma Ekonomisi ve Endüstri İlişkileri (İÖ)",
"Uluslararası Ticaret ve Finansman (İÖ)",
"Ekonomi",
"İnsan Kaynakları Yönetimi (İÖ)",
"Uluslararası Ticaret ve İşletmecilik",
"Seyahat İşletmeciliği",
"Yiyecek ve İçecek İşletmeciliği",
"Finans ve Bankacılık (İÖ)",
"Uluslararası Ticaret (İÖ)",
"Endüstri Mühendisliği (İÖ)",
"Makine Mühendisliği (İÖ)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce)",
"Denizcilik İşletmeleri Yönetimi (İÖ)",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği",
"Gemi Makineleri İşletme Mühendisliği",
"Yeni Medya ve İletişim",
"Yeni Medya ve İletişim (İÖ)",
"Sosyal Hizmet (İÖ)",
"Grafik Tasarımı",
"Grafik Tasarımı (KKTC Uyruklu)",
"Orman Endüstrisi Mühendisliği",
"Bilgisayar Teknolojisi ve Bilişim Sistemleri",
"Biyoteknoloji",
"Rekreasyon",
"İngiliz Dili ve Edebiyatı",
"Petrol ve Doğalgaz Mühendisliği (İngilizce)",
"Gümrük İşletme",
"İstatistik ve Bilgisayar Bilimleri",
"İstatistik ve Bilgisayar Bilimleri (KKTC Uyruklu)",
"Biyomühendislik",
"Ziraat Mühendisliği Programları",
"İngiliz Dili ve Edebiyatı (İÖ)",
"Kürt Dili ve Edebiyatı",
"Zaza Dili ve Edebiyatı",
"İş Sağlığı ve Güvenliği",
"Bilgisayar ve Öğretim Teknolojileri Öğretmenliği (İngilizce)",
"Fen Bilgisi Öğretmenliği (İngilizce)",
"Fizik Öğretmenliği (İngilizce)",
"Fizik Öğretmenliği (İngilizce) (KKTC Uyruklu)",
"İlköğretim Matematik Öğretmenliği (İngilizce)",
"Kimya Öğretmenliği (İngilizce)",
"Matematik Öğretmenliği (İngilizce)",
"Okul Öncesi Öğretmenliği (İngilizce)",
"Rehberlik ve Psikolojik Danışmanlık (İngilizce)",
"Çeviribilimi (İngilizce)",
"Çeviribilimi (İngilizce) (KKTC Uyruklu)",
"Dilbilimi (İngilizce)",
"Fizik (İngilizce)",
"Fizik (İngilizce) (KKTC Uyruklu)",
"Türk Dili ve Edebiyatı (İngilizce)",
"Kimya Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Uluslararası Ticaret (İngilizce)",
"Özel Eğitim Öğretmenliği (KKTC Uyruklu)",
"Biyoloji (İngilizce) (KKTC Uyruklu)",
"İktisat (KKTC Uyruklu)",
"Bilgisayar Mühendisliği (KKTC Uyruklu)",
"Kanatlı Hayvan Yetiştiriciliği",
"Tohum Bilimi ve Teknolojisi",
"Hemşirelik (İÖ)",
"Bilişim Sistemleri Mühendisliği",
"Bilişim Sistemleri Mühendisliği (M.T.O.K.)",
"Yazılım Mühendisliği (M.T.O.K.)",
"Nanobilim ve Nanoteknoloji",
"Nanobilim ve Nanoteknoloji (KKTC Uyruklu)",
"Acil Yardım ve Afet Yönetimi (İÖ)",
"Çizgi Film ve Animasyon",
"Spor Yöneticiliği (İÖ)",
"Kimya Mühendisliği (KKTC Uyruklu)",
"Metalurji ve Malzeme Mühendisliği",
"Polimer Malzeme Mühendisliği",
"Almanca Öğretmenliği (KKTC Uyruklu)",
"Moleküler Biyoloji ve Genetik (KKTC Uyruklu)",
"Ekonometri (İÖ)",
"İşletme (İÖ)",
"Biyosistem Mühendisliği (KKTC Uyruklu)",
"İş Sağlığı ve Güvenliği (KKTC Uyruklu)",
"Enerji Yönetimi",
"Gıda Teknolojisi",
"Coğrafya Öğretmenliği (KKTC Uyruklu)",
"Japonca Öğretmenliği",
"Çevre Mühendisliği (İngilizce)",
"Harita Mühendisliği",
"Acil Yardım ve Afet Yönetimi (KKTC Uyruklu)",
"İktisat (İngilizce) (İÖ)",
"İşletme (İngilizce) (İÖ)",
"Ergoterapi",
"Tekstil ve Moda Tasarımı",
"Fransızca Öğretmenliği (KKTC Uyruklu)",
"İstatistik (KKTC Uyruklu)",
"Grafik",
"Ekonometri (İngilizce)",
"Siyaset Bilimi ve Uluslararası İlişkiler",
"İletişim Bilimleri",
"İç Mimarlık (KKTC Uyruklu)",
"Bilgisayar Mühendisliği (İngilizce) (İÖ)",
"Elektrik-Elektronik Mühendisliği (İngilizce) (İÖ)",
"Makine Mühendisliği (İngilizce) (İÖ)",
"Makine Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Tarla Bitkileri (KKTC Uyruklu)",
"Biyoloji Öğretmenliği (KKTC Uyruklu)",
"Fizik Öğretmenliği",
"Denizcilik İşletmeleri Yönetimi (İngilizce)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Lojistik Yönetimi (İngilizce)",
"Arkeoloji (İÖ)",
"Karşılaştırmalı Edebiyat",
"Müzecilik",
"Biyoloji (UOLP-Gence Devlet Üniversitesi) (Ücretli)",
"Film Tasarımı ve Yazarlığı",
"Film Tasarımı ve Yazarlığı (KKTC Uyruklu)",
"Film Tasarımı ve Yönetmenliği",
"Kurgu, Ses ve Görüntü Yönetimi",
"Kurgu, Ses ve Görüntü Yönetimi (KKTC Uyruklu)",
"Ekonometri (KKTC Uyruklu)",
"İktisat (İngilizce) (UOLP-New York Eyalet Üniversitesi (Suny Albany)) (Ücretli)",
"İşletme (İngilizce) (UOLP-New York Eyalet Üniversitesi (Suny Albany)) (Ücretli)",
"Uluslararası İlişkiler (İngilizce) (UOLP-New York Eyalet Üniversitesi (Suny Albany)) (Ücretli)",
"İnşaat Mühendisliği (İÖ)",
"Jeofizik Mühendisliği",
"Turizm İşletmeciliği (KKTC Uyruklu)",
"Çerkez Dili ve Kültürü",
"Gürcü Dili ve Edebiyatı",
"Sigortacılık ve Sosyal Güvenlik",
"Sigortacılık ve Sosyal Güvenlik (İÖ)",
"Ağaç İşleri Endüstri Mühendisliği",
"Tiyatro Eleştirmenliği ve Dramaturji",
"Almanca Mütercim ve Tercümanlık",
"Felsefe (KKTC Uyruklu)",
"Biyokimya",
"Biyokimya (KKTC Uyruklu)",
"Matematik (İÖ)",
"Moda Tasarımı (KKTC Uyruklu)",
"Deri Mühendisliği (İngilizce)",
"Gıda Mühendisliği (UOLP-Azerbaycan Devlet İktisat Üniversitesi) (Ücretli)",
"Tekstil Mühendisliği (İngilizce)",
"Su Ürünleri Mühendisliği (KKTC Uyruklu)",
"Zootekni (KKTC Uyruklu)",
"Çin Dili ve Edebiyatı (KKTC Uyruklu)",
"Ermeni Dili ve Kültürü",
"İbrani Dili ve Kültürü",
"Japon Dili ve Edebiyatı (KKTC Uyruklu)",
"Kore Dili ve Edebiyatı (İÖ)",
"Rus Dili ve Edebiyatı (İÖ)",
"Havacılık Elektrik ve Elektroniği",
"Havacılık Elektrik ve Elektroniği (İÖ)",
"Havacılık Yönetimi (İÖ)",
"Havacılık Yönetimi (KKTC Uyruklu)",
"Uçak Gövde ve Motor Bakımı",
"Uçak Gövde ve Motor Bakımı (İÖ)",
"Uçak Mühendisliği",
"Uçak Mühendisliği (KKTC Uyruklu)",
"Uzay Mühendisliği",
"Endüstriyel Tasarım Mühendisliği",
"Endüstriyel Tasarım Mühendisliği (KKTC Uyruklu)",
"Mekatronik Mühendisliği (İÖ)",
"Uçak Bakım ve Onarım",
"Havacılık ve Uzay Mühendisliği",
"Matematik ve Bilgisayar Bilimleri",
"Endüstri Mühendisliği (KKTC Uyruklu)",
"Makine Mühendisliği (KKTC Uyruklu)",
"Uçak Mühendisliği (İngilizce)",
"Uçak Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Turizm İşletmeciliği (İÖ)",
"Pilotaj",
"Uçak Gövde ve Motor Bakımı (KKTC Uyruklu)",
"Çevre Mühendisliği (KKTC Uyruklu)",
"Adli Bilişim Mühendisliği",
"Adli Bilişim Mühendisliği (İÖ)",
"Adli Bilişim Mühendisliği (M.T.O.K.)",
"Adli Bilişim Mühendisliği (KKTC Uyruklu)",
"Enerji Sistemleri Mühendisliği",
"Yazılım Mühendisliği (İÖ)",
"Yazılım Mühendisliği (M.T.O.K.) (İÖ)",
"Yazılım Mühendisliği (İngilizce) (UOLP-Sam Houston State) (Ücretli)",
"Felsefe (Fransızca)",
"Matematik (Fransızca)",
"Sosyoloji (Fransızca)",
"Hukuk (Fransızca)",
"İktisat (Fransızca)",
"İşletme (Fransızca)",
"Siyaset Bilimi (Fransızca)",
"Siyaset Bilimi (Fransızca) (KKTC Uyruklu)",
"Uluslararası İlişkiler (Fransızca)",
"İletişim (Fransızca)",
"İletişim (Fransızca) (KKTC Uyruklu)",
"Bilgisayar Mühendisliği (Fransızca)",
"Endüstri Mühendisliği (Fransızca)",
"Matematik (Uzaktan Öğretim)",
"Şehir ve Bölge Planlama (KKTC Uyruklu)",
"Endüstri Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Bilgisayar Mühendisliği (M.T.O.K.)",
"Elektrik-Elektronik Mühendisliği (UOLP-Azerbaycan Teknik Üniversitesi) (Ücretli)",
"Endüstriyel Tasarım Mühendisliği (M.T.O.K.)",
"Enerji Sistemleri Mühendisliği (M.T.O.K.)",
"Enerji Sistemleri Mühendisliği (KKTC Uyruklu)",
"İmalat Mühendisliği",
"İnşaat Mühendisliği (M.T.O.K.)",
"İnşaat Mühendisliği (KKTC Uyruklu)",
"Metalurji ve Malzeme Mühendisliği (M.T.O.K.)",
"Otomotiv Mühendisliği (M.T.O.K.)",
"Fotonik",
"İslami İlimler (Arapça)",
"İslami İlimler (Arapça) (M.T.O.K.)",
"Havacılık Yönetimi (İngilizce)",
"Şehir ve Bölge Planlama (İngilizce)",
"Endüstri Mühendisliği (İngilizce) (İÖ)",
"İnşaat Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Optik ve Akustik Mühendisliği (İngilizce)",
"Elektronik Mühendisliği",
"Malzeme Bilimi ve Mühendisliği",
"Alman Dili ve Edebiyatı (KKTC Uyruklu)",
"Fransızca Mütercim ve Tercümanlık",
"Fransızca Mütercim ve Tercümanlık (KKTC Uyruklu)",
"İngiliz Dilbilimi (İngilizce)",
"Almanca Öğretmenliği (Almanca)",
"Aile ve Tüketici Bilimleri",
"İletişim Bilimleri (KKTC Uyruklu)",
"Fizik Mühendisliği",
"Geomatik Mühendisliği (İngilizce)",
"Hidrojeoloji Mühendisliği",
"Maden Mühendisliği (İngilizce)",
"Nükleer Enerji Mühendisliği (İngilizce)",
"Nükleer Enerji Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Yapay Zeka Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Ergoterapi (KKTC Uyruklu)",
"Odyoloji (KKTC Uyruklu)",
"Arap Dili ve Edebiyatı (Arapça)",
"Antrenörlük Eğitimi",
"Antrenörlük Eğitimi (İÖ)",
"Gastronomi ve Mutfak Sanatları ",
"Beslenme ve Diyetetik ",
"Dil ve Konuşma Terapisi ",
"Gerontoloji ",
"Deniz Ulaştırma İşletme Mühendisliği",
"Petrol ve Doğalgaz Mühendisliği",
"İspanyol Dili ve Edebiyatı",
"İspanyol Dili ve Edebiyatı (KKTC Uyruklu)",
"İktisat (İngilizce) (KKTC Uyruklu)",
"Bilişim Sistemleri Mühendisliği (İngilizce) (UOLP-Suny Binghamton) (Ücretli)",
"Yapay Zeka ve Veri Mühendisliği (İngilizce)",
"Yapay Zeka ve Veri Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (UOLP-Suny Maritime) (Ücretli)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (UOLP-Suny Maritime) (%50 İndirimli)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (UOLP-Suny Maritime) (Ücretli)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (UOLP-Suny Maritime) (%50 İndirimli)",
"Elektrik Mühendisliği (İngilizce)",
"Elektronik ve Haberleşme Mühendisliği",
"Elektronik ve Haberleşme Mühendisliği (İngilizce)",
"Elektronik ve Haberleşme Mühendisliği (İngilizce) (UOLP-New Jersey Institute Of Technology) (Ücretli)",
"Elektronik ve Haberleşme Mühendisliği (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Elektronik ve Haberleşme Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Kontrol ve Otomasyon Mühendisliği",
"Kontrol ve Otomasyon Mühendisliği (İngilizce)",
"Matematik Mühendisliği",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (KKTC Uyruklu)",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (UOLP-Strathclyde) (İngilizce) (UOLP)",
"Gemi ve Deniz Teknolojisi Mühendisliği",
"Geomatik Mühendisliği (KKTC Uyruklu)",
"İnşaat Mühendisliği (İngilizce) (UOLP-Suny Buffalo) (Ücretli)",
"İnşaat Mühendisliği (İngilizce) (UOLP-Azerbaycan Mimarlık ve İnşaat Üniversitesi) (Ücretli)",
"Ekonomi (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Endüstri Mühendisliği (İngilizce) (UOLP-Suny Binghamton) (Ücretli)",
"İşletme (İngilizce) (UOLP-Suny New Paltz) (Ücretli)",
"İşletme (İngilizce) (UOLP-Suny New Paltz) (%50 İndirimli)",
"İşletme Mühendisliği (İngilizce)",
"Biyomühendislik (UOLP-Montana State Üniversitesi) (İngilizce) (UOLP) (Ücretli)",
"Cevher Hazırlama Mühendisliği",
"Petrol ve Doğalgaz Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Makine Mühendisliği (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Endüstriyel Tasarım (İngilizce)",
"Mimarlık (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Peyzaj Mimarlığı (İngilizce)",
"Meteoroloji Mühendisliği",
"Uzay Mühendisliği (İngilizce)",
"Bilgisayar Mühendisliği (İngilizce) (Ücretli)",
"Bilgisayar Mühendisliği (İngilizce) (Burslu)",
"Bilgisayar Mühendisliği (İngilizce) (%50 İndirimli)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (Ücretli)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (Burslu)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (%50 İndirimli)",
"Denizcilik İşletmeleri Yönetimi (İngilizce) (Ücretli)",
"Denizcilik İşletmeleri Yönetimi (İngilizce) (Burslu)",
"Denizcilik İşletmeleri Yönetimi (İngilizce) (%50 İndirimli)",
"Ekonomi ve Finans (İngilizce) (Ücretli)",
"Ekonomi ve Finans (İngilizce) (Burslu)",
"Ekonomi ve Finans (İngilizce) (%50 İndirimli)",
"Elektrik-Elektronik Mühendisliği (İngilizce) (Ücretli)",
"Elektrik-Elektronik Mühendisliği (İngilizce) (Burslu)",
"Elektrik-Elektronik Mühendisliği (İngilizce) (%50 İndirimli)",
"Endüstri Mühendisliği (İngilizce) (Ücretli)",
"Endüstri Mühendisliği (İngilizce) (Burslu)",
"Endüstri Mühendisliği (İngilizce) (%50 İndirimli)",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (İngilizce) (Ücretli)",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (İngilizce) (Burslu)",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (İngilizce) (%50 İndirimli)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (Ücretli)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (Burslu)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (%50 İndirimli)",
"İç Mimarlık (İngilizce) (Ücretli)",
"İç Mimarlık (İngilizce) (Burslu)",
"İç Mimarlık (İngilizce) (%50 İndirimli)",
"Mimarlık (İngilizce) (Ücretli)",
"Mimarlık (İngilizce) (Burslu)",
"Mimarlık (İngilizce) (%50 İndirimli)",
"Coğrafya (Açıköğretim)",
"İnsan Kaynakları Yönetimi (Açıköğretim)",
"İş Sağlığı ve Güvenliği (Açıköğretim)",
"Kamu Yönetimi (Uzaktan Öğretim)",
"Siyaset Bilimi ve Uluslararası İlişkiler (Açıköğretim)",
"Bilim Tarihi",
"Çin Dili ve Edebiyatı (Çince)",
"İtalyan Dili ve Edebiyatı",
"Kore Dili ve Edebiyatı (Korece)",
"Leh Dili ve Edebiyatı",
"Ukrayna Dili ve Edebiyatı",
"Hukuk (UOLP-Hamburg Üniversitesi) (Ücretli)",
"İktisat (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"İstanbul Tıp",
"İstanbul Tıp (İngilizce)",
"İstanbul Tıp (İngilizce) (KKTC Uyruklu)",
"İşletme (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Su Bilimleri ve Mühendisliği",
"Cerrahpaşa Tıp",
"Cerrahpaşa Tıp (İngilizce)",
"Fransızca Öğretmenliği (Fransızca)",
"Jeofizik Mühendisliği (KKTC Uyruklu)",
"Jeoloji Mühendisliği (KKTC Uyruklu)",
"Orman Mühendisliği (KKTC Uyruklu)",
"İnsan Kaynakları Yönetimi (KKTC Uyruklu)",
"Egzersiz ve Spor Bilimleri",
"Fizyoterapi ve Rehabilitasyon (İngilizce)",
"Harita Mühendisliği (İngilizce)",
"Harita Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Mekatronik Mühendisliği (İngilizce)",
"Medya ve İletişim",
"Medya ve İletişim (KKTC Uyruklu)",
"Türk İslam Arkeolojisi",
"Fotonik (İngilizce)",
"Kimya (İngilizce) (KKTC Uyruklu)",
"Azerbaycan Türkçesi ve Edebiyatı",
"İngilizce, Fransızca Mütercim ve Tercümanlık",
"Elektronik Ticaret ve Yönetimi",
"Dijital Oyun Tasarımı",
"Otomotiv Mühendisliği (KKTC Uyruklu)",
"Raylı Sistemler Mühendisliği",
"Raylı Sistemler Mühendisliği (İngilizce)",
"Tıp Mühendisliği",
"Elektronik ve Haberleşme Mühendisliği (KKTC Uyruklu)",
"Yaban Hayatı Ekolojisi ve Yönetimi",
"Balıkçılık Teknolojisi Mühendisliği",
"Deniz Ulaştırma İşletme Mühendisliği (KKTC Uyruklu)",
"Yeni Medya",
"Muhasebe ve Finans Yönetimi (İÖ)",
"Farsça Mütercim ve Tercümanlık",
"İngilizce Mütercim ve Tercümanlık (KKTC Uyruklu)",
"Hukuk (İÖ)",
"Yazılım Mühendisliği (KKTC Uyruklu)",
"Çocuk Gelişimi (İÖ)",
"Ebelik (KKTC Uyruklu)",
"Elektrik Mühendisliği (İÖ)",
"Elektrik Mühendisliği (KKTC Uyruklu)",
"Elektronik ve Haberleşme Mühendisliği (İÖ)",
"Sosyal Hizmet (KKTC Uyruklu)",
"Rekreasyon (İÖ)",
"Bilişim Sistemleri Mühendisliği (KKTC Uyruklu)",
"Biyomedikal Mühendisliği (M.T.O.K.)",
"Görsel İletişim Tasarımı (İÖ)",
"Takı Tasarımı ve İmalatı",
"Siyaset Bilimi ve Uluslararası İlişkiler (İÖ)",
"Muhasebe ve Finans Yönetimi (KKTC Uyruklu)",
"Sigortacılık ve Risk Yönetimi",
"İç Mimarlık (İÖ)",
"Adli Bilimler",
"Adli Bilimler (KKTC Uyruklu)",
"Uluslararası İşletme Yönetimi",
"Mekatronik Mühendisliği (M.T.O.K.)",
"Mekatronik Mühendisliği (M.T.O.K.) (İÖ)",
"Siyaset Bilimi ve Kamu Yönetimi (Uzaktan Öğretim)",
"Siyaset Bilimi ve Uluslararası İlişkiler (İngilizce) (İÖ)",
"Ekonomi ve Finans (KKTC Uyruklu)",
"Gıda Mühendisliği (KKTC Uyruklu)",
"Tütün Eksperliği",
"Sermaye Piyasası",
"Süryani Dili ve Edebiyatı",
"Tarih (Arapça)",
"İngilizce Öğretmenliği (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Kimya Öğretmenliği (KKTC Uyruklu)",
"Aktüerya Bilimleri (KKTC Uyruklu)",
"Bankacılık",
"Film Tasarımı ve Yönetimi",
"Hukuk (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Almanca Mütercim ve Tercümanlık (KKTC Uyruklu)",
"Bilgi ve Belge Yönetimi (KKTC Uyruklu)",
"İşletme (Almanca)",
"Yönetim Bilişim Sistemleri (Almanca)",
"Bilgisayar Mühendisliği (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Biyomühendislik (İngilizce) (UOLP-Uluslararası Saraybosna Üniversitesi) (Ücretli)",
"Biyomühendislik (İngilizce) (KKTC Uyruklu)",
"Siyaset Bilimi ve Kamu Yönetimi (Fransızca)",
"Yerel Yönetimler",
"Makine Mühendisliği (M.T.O.K.)",
"Tekstil Mühendisliği (M.T.O.K.)",
"Basım Teknolojileri",
"Sosyoloji (KKTC Uyruklu)",
"Harita Mühendisliği (KKTC Uyruklu)",
"Takı Tasarımı",
"Dijital Oyun Tasarımı (KKTC Uyruklu)",
"Bahçe Bitkileri ",
"Bitki Koruma ",
"Ağaç İşleri Endüstri Mühendisliği (M.T.O.K.)",
"Bitkisel Üretim ve Teknolojileri",
"Geleneksel Türk Sanatları",
"Uzay ve Uydu Mühendisliği",
"Peyzaj Mimarlığı (KKTC Uyruklu)",
"Bitkisel Üretim ve Teknolojileri (İngilizce)",
"Biyosistem Mühendisliği (İngilizce)",
"Hayvansal Üretim ve Teknolojileri (İngilizce)",
"Tarımsal Genetik Mühendisliği (İngilizce)",
"Sigortacılık ve Aktüerya Bilimleri",
"İletişim ve Tasarımı",
"İngilizce Öğretmenliği (İngilizce) (UOLP-Suny New Paltz) (Ücretli)",
"İngilizce Öğretmenliği (İngilizce) (UOLP-Suny New Paltz) (%50 İndirimli)",
"Kimya Öğretmenliği (İngilizce) (KKTC Uyruklu)",
"Felsefe (İngilizce) (KKTC Uyruklu)",
"İstatistik (İngilizce)",
"İşletme (İngilizce) (UOLP-Suny Binghamton) (Ücretli)",
"İşletme (İngilizce) (UOLP-Suny Binghamton) (%50 İndirimli)",
"Küresel Siyaset ve Uluslararası İlişkiler (İngilizce) (UOLP-Suny Binghamton) (Ücretli)",
"Küresel Siyaset ve Uluslararası İlişkiler (İngilizce) (UOLP-Suny Binghamton) (%50 İndirimli)",
"Gıda Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Havacılık ve Uzay Mühendisliği (İngilizce) (Ücretli)",
"Havacılık ve Uzay Mühendisliği (İngilizce) (Burslu)",
"Havacılık ve Uzay Mühendisliği (İngilizce) (%50 İndirimli)",
"İngilizce Öğretmenliği (İngilizce) (Ücretli)",
"İngilizce Öğretmenliği (İngilizce) (Burslu)",
"İngilizce Öğretmenliği (İngilizce) (%50 İndirimli)",
"İnşaat Mühendisliği (İngilizce) (Ücretli)",
"İnşaat Mühendisliği (İngilizce) (Burslu)",
"İnşaat Mühendisliği (İngilizce) (%50 İndirimli)",
"İşletme (İngilizce) (Ücretli)",
"İşletme (İngilizce) (Burslu)",
"İşletme (İngilizce) (%50 İndirimli)",
"Makine Mühendisliği (İngilizce) (Ücretli)",
"Makine Mühendisliği (İngilizce) (Burslu)",
"Makine Mühendisliği (İngilizce) (%50 İndirimli)",
"Psikoloji (İngilizce) (Ücretli)",
"Psikoloji (İngilizce) (Burslu)",
"Psikoloji (İngilizce) (%50 İndirimli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (İngilizce) (Ücretli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (İngilizce) (Burslu)",
"Siyaset Bilimi ve Uluslararası İlişkiler (İngilizce) (%50 İndirimli)",
"Yazılım Mühendisliği (İngilizce) (Ücretli)",
"Yazılım Mühendisliği (İngilizce) (Burslu)",
"Yazılım Mühendisliği (İngilizce) (%50 İndirimli)",
"Organik Tarım İşletmeciliği",
"Biyoloji (KKTC Uyruklu)",
"Ekonomi (İÖ)",
"Ekonomi (İngilizce) (İÖ)",
"Ekonomi (İngilizce) (UOLP-Suny Cortland) (Ücretli)",
"Siyaset Bilimi ve Kamu Yönetimi (İngilizce) (UOLP-Suny Cortland) (Ücretli)",
"Coğrafya (KKTC Uyruklu)",
"Biyomedikal Mühendisliği (İÖ)",
"Otomotiv Mühendisliği (İÖ)",
"Diş Hekimliği (Ankara)",
"Eczacılık (Ankara)",
"Fizyoterapi ve Rehabilitasyon (Ankara)",
"Hemşirelik (Ankara)",
"Beslenme ve Diyetetik (Ankara)",
"Çocuk Gelişimi (Ankara)",
"Ebelik (Ankara)",
"Ergoterapi (Ankara)",
"Odyoloji (Ankara)",
"Ortez ve Protez (Ankara)",
"Sağlık Yönetimi (Ankara)",
"Sosyal Hizmet (Ankara)",
"Gülhane Tıp (Ankara)",
"Uluslararası Tıp",
"Elektrik-Elektronik Mühendisliği (M.T.O.K.) (İÖ)",
"Makine Mühendisliği (M.T.O.K.) (İÖ)",
"Almanca Mütercim ve Tercümanlık (İÖ)",
"Metalurji ve Malzeme Mühendisliği (İÖ)",
"İslam İktisadı ve Finans",
"Havacılık ve Uzay Mühendisliği (KKTC Uyruklu)",
"Meteoroloji Mühendisliği (KKTC Uyruklu)",
"Alman Dili ve Edebiyatı (Almanca) (İÖ)",
"Arap Dili ve Edebiyatı (Arapça) (İÖ)",
"Reklamcılık (İÖ)",
"Ayakkabı Tasarımı ve Üretimi",
"Bilgisayar Mühendisliği (M.T.O.K.) (İÖ)",
"Nükleer Enerji Mühendisliği",
"Nanoteknoloji Mühendisliği (İngilizce)",
"Arnavut Dili ve Edebiyatı",
"Boşnak Dili ve Edebiyatı",
"Bulgarca Mütercim ve Tercümanlık",
"Bankacılık ve Sigortacılık (KKTC Uyruklu)",
"Mimarlık (İngilizce) (UOLP-Uluslararası Balkan Üniversitesi) (Ücretli)",
"Genetik ve Biyomühendislik",
"Genetik ve Biyomühendislik (KKTC Uyruklu)",
"Enerji Bilimi ve Teknolojileri (Almanca)",
"Malzeme Bilimi ve Teknolojileri (Almanca)",
"Moleküler Biyoteknoloji (Almanca)",
"İktisat (Almanca)",
"Siyaset Bilimi ve Uluslararası İlişkiler (Almanca)",
"Kültür ve İletişim Bilimleri",
"Bilgisayar Mühendisliği (Almanca)",
"Elektrik-Elektronik Mühendisliği (Almanca)",
"Endüstri Mühendisliği (Almanca)",
"İnşaat Mühendisliği (Almanca)",
"Makine Mühendisliği (Almanca)",
"Mekatronik Mühendisliği (Almanca)",
"Mekatronik Mühendisliği (Almanca) (KKTC Uyruklu)",
"Yeni Medya ve İletişim (KKTC Uyruklu)",
"Matematik Mühendisliği (KKTC Uyruklu)",
"Matematik Mühendisliği (İngilizce)",
"Mekatronik Mühendisliği (İngilizce) (KKTC Uyruklu)",
"Kültür Varlıklarını Koruma ve Onarım (KKTC Uyruklu)",
"Sanat ve Kültür Yönetimi",
"Psikoloji (İÖ)",
"Diş Hekimliği (Ankara) (Kız) (İçişleri Bakanlığı Adına)",
"Diş Hekimliği (Ankara) (Erkek) (İçişleri Bakanlığı Adına)",
"Diş Hekimliği (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
"Eczacılık (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
"Gülhane Tıp (Ankara) (Kız) (İçişleri Bakanlığı Adına)",
"Gülhane Tıp (Ankara) (Erkek) (İçişleri Bakanlığı Adına)",
"Gülhane Tıp (Ankara) (Kız) (Milli Savunma Bakanlığı Adına)",
"Gülhane Tıp (Ankara) (Erkek) (Milli Savunma Bakanlığı Adına)",
"Tıp (İstanbul) (Erkek) (Milli Savunma Bakanlığı Adına)",
"Diş Hekimliği Fakültesi",
"Rus Dili ve Edebiyatı Öğretmenliği",
"Yönetim Bilişim Sistemleri (Uzaktan Öğretim) (Ücretli)",
"*Uluslararası anlaşmalar ile kurulmuş olan üniversite, özerk statüye sahip ortak devlet üniversitesidir. Eğitim, yurt dışında üniversitenin bulunduğu ülkede sürdürülecektir.",
"Batı Dilleri (İngiliz Dili ve Edebiyatı)",
"Doğu Dilleri (Rus Dili ve Edebiyatı)",
"Doğu Dilleri (Çin Dili ve Edebiyatı)",
"Mütercim-Tercümanlık (Türkçe-Rusça)",
"Türkoloji",
"İslam Bilimleri",
"Eczacılık (İngilizce) (Ücretli)",
"Eczacılık (İngilizce) (Burslu)",
"Psikoloji (%25 İndirimli)",
"Psikoloji (İngilizce) (%25 İndirimli)",
"Sosyoloji (Burslu)",
"Sosyoloji (%50 İndirimli)",
"Biyomedikal Mühendisliği (İngilizce) (Burslu)",
"Biyomedikal Mühendisliği (İngilizce) (%50 İndirimli)",
"Moleküler Biyoloji ve Genetik (İngilizce) (Burslu)",
"Moleküler Biyoloji ve Genetik (İngilizce) (%50 İndirimli)",
"Beslenme ve Diyetetik (Burslu)",
"Beslenme ve Diyetetik (%25 İndirimli)",
"Beslenme ve Diyetetik (İngilizce) (Burslu)",
"Beslenme ve Diyetetik (İngilizce) (%25 İndirimli)",
"Fizyoterapi ve Rehabilitasyon (Burslu)",
"Fizyoterapi ve Rehabilitasyon (%50 İndirimli)",
"Hemşirelik (Burslu)",
"Hemşirelik (%50 İndirimli)",
"Hemşirelik (İngilizce) (Burslu)",
"Hemşirelik (İngilizce) (%50 İndirimli)",
"Sağlık Yönetimi (Burslu)",
"Sağlık Yönetimi (%50 İndirimli)",
"Tıp (İngilizce) (Burslu)",
"Tıp (İngilizce) (%50 İndirimli)",
"Turizm İşletmeciliği (İngilizce) (Burslu)",
"Turizm İşletmeciliği (İngilizce) (%50 İndirimli)",
"Çizgi Film ve Animasyon (Burslu)",
"Çizgi Film ve Animasyon (%50 İndirimli)",
"Gastronomi ve Mutfak Sanatları (Burslu)",
"Gastronomi ve Mutfak Sanatları (%50 İndirimli)",
"Gastronomi ve Mutfak Sanatları (İngilizce) (Burslu)",
"Gastronomi ve Mutfak Sanatları (İngilizce) (%50 İndirimli)",
"İletişim ve Tasarımı (İngilizce) (Burslu)",
"İletişim ve Tasarımı (İngilizce) (%50 İndirimli)",
"Diş Hekimliği (Burslu)",
"Diş Hekimliği (%25 İndirimli)",
"Eczacılık (İngilizce) (%25 İndirimli)",
"Radyo, Televizyon ve Sinema (Burslu)",
"Radyo, Televizyon ve Sinema (%50 İndirimli)",
"Takı Tasarımı (Burslu)",
"Hukuk (UOLP-Köln Üniversitesi) (Burslu)",
"Hukuk (UOLP-Köln Üniversitesi) (%50 İndirimli)",
"Ekonomi (İngilizce) (Burslu)",
"Ekonomi (İngilizce) (%50 İndirimli)",
"Siyaset Bilimi ve Kamu Yönetimi (İngilizce) (Burslu)",
"Siyaset Bilimi ve Kamu Yönetimi (İngilizce) (%50 İndirimli)",
"Sosyoloji (İngilizce) (Burslu)",
"Sosyoloji (İngilizce) (%50 İndirimli)",
"Uluslararası İlişkiler (İngilizce) (Burslu)",
"Uluslararası İlişkiler (İngilizce) (%50 İndirimli)",
"Lojistik Yönetimi (İngilizce) (Burslu)",
"Lojistik Yönetimi (İngilizce) (%50 İndirimli)",
"Uluslararası Ticaret ve Finansman (İngilizce) (Burslu)",
"Uluslararası Ticaret ve Finansman (İngilizce) (%50 İndirimli)",
"İç Mimarlık ve Çevre Tasarımı (Burslu)",
"İç Mimarlık ve Çevre Tasarımı (%50 İndirimli)",
"İç Mimarlık ve Çevre Tasarımı (İngilizce) (Burslu)",
"İç Mimarlık ve Çevre Tasarımı (İngilizce) (%50 İndirimli)",
"Yönetim Bilişim Sistemleri (Burslu)",
"Yönetim Bilişim Sistemleri (%50 İndirimli)",
"Film Tasarımı ve Yönetimi (İngilizce) (Burslu)",
"Film Tasarımı ve Yönetimi (İngilizce) (%50 İndirimli)",
"İç Mimarlık ve Çevre Tasarımı (İngilizce) (Ücretli)",
"Yeni Medya ve İletişim (İngilizce) (Burslu)",
"Yeni Medya ve İletişim (İngilizce) (%50 İndirimli)",
"İngilizce Mütercim ve Tercümanlık (Ücretli)",
"İngilizce Mütercim ve Tercümanlık (Burslu)",
"İngilizce Mütercim ve Tercümanlık (%50 İndirimli)",
"Yönetim Bilişim Sistemleri (Ücretli)",
"Yönetim Bilişim Sistemleri (İngilizce) (Ücretli)",
"Yönetim Bilişim Sistemleri (İngilizce) (Burslu)",
"Yönetim Bilişim Sistemleri (İngilizce) (%50 İndirimli)",
"Bilgisayar Mühendisliği (Ücretli)",
"Bilgisayar Mühendisliği (Burslu)",
"Bilgisayar Mühendisliği (%50 İndirimli)",
"Bilişim Sistemleri Mühendisliği (İngilizce) (Ücretli)",
"Bilişim Sistemleri Mühendisliği (İngilizce) (Burslu)",
"Bilişim Sistemleri Mühendisliği (İngilizce) (%50 İndirimli)",
"Diş Hekimliği (Ücretli)",
"Diş Hekimliği (%50 İndirimli)",
"Diş Hekimliği (İngilizce) (Ücretli)",
"Diş Hekimliği (İngilizce) (Burslu)",
"Diş Hekimliği (İngilizce) (%50 İndirimli)",
"Eczacılık (Ücretli)",
"Eczacılık (Burslu)",
"Eczacılık (%50 İndirimli)",
"Eczacılık (İngilizce) (%50 İndirimli)",
"Uluslararası Ticaret ve Finansman (Burslu)",
"Uluslararası Ticaret ve Finansman (%50 İndirimli)",
"Halkla İlişkiler ve Reklamcılık (Burslu)",
"Halkla İlişkiler ve Reklamcılık (%50 İndirimli)",
"Beslenme ve Diyetetik (%50 İndirimli)",
"Dil ve Konuşma Terapisi (Burslu)",
"Dil ve Konuşma Terapisi (%50 İndirimli)",
"Ebelik (Burslu)",
"Ebelik (%50 İndirimli)",
"Ergoterapi (Burslu)",
"Ergoterapi (%50 İndirimli)",
"Odyoloji (Burslu)",
"Odyoloji (%50 İndirimli)",
"Tıp (Ücretli)",
"Tıp (Burslu)",
"Tıp (%50 İndirimli)",
"Tıp (İngilizce) (Ücretli)",
"Uluslararası Ticaret ve İşletmecilik (Burslu)",
"Uluslararası Ticaret ve İşletmecilik (%50 İndirimli)",
"Yazılım Mühendisliği (Ücretli)",
"Yazılım Mühendisliği (Burslu)",
"Yazılım Mühendisliği (%50 İndirimli)",
"Gastronomi ve Mutfak Sanatları (Ücretli)",
"İç Mimarlık ve Çevre Tasarımı (Ücretli)",
"İletişim ve Tasarımı (Burslu)",
"İletişim ve Tasarımı (%50 İndirimli)",
"Pilotaj (Ücretli)",
"Pilotaj (Burslu)",
"İngiliz Dili ve Edebiyatı (İngilizce) (Burslu)",
"İngiliz Dili ve Edebiyatı (İngilizce) (%50 İndirimli)",
"İngilizce Mütercim ve Tercümanlık (%25 İndirimli)",
"Matematik (İngilizce) (Burslu)",
"Matematik (İngilizce) (%50 İndirimli)",
"Endüstriyel Tasarım (Burslu)",
"Endüstriyel Tasarım (%50 İndirimli)",
"Grafik Tasarımı (Burslu)",
"Grafik Tasarımı (%50 İndirimli)",
"İç Mimarlık ve Çevre Tasarımı (%25 İndirimli)",
"Mimarlık (Burslu)",
"Mimarlık (%50 İndirimli)",
"Tekstil ve Moda Tasarımı (Burslu)",
"Tekstil ve Moda Tasarımı (%50 İndirimli)",
"Halkla İlişkiler ve Reklamcılık (İngilizce) (Burslu)",
"Halkla İlişkiler ve Reklamcılık (İngilizce) (%50 İndirimli)",
"İktisat (Burslu)",
"İktisat (%50 İndirimli)",
"İşletme (Burslu)",
"İşletme (%50 İndirimli)",
"Maliye (Burslu)",
"Maliye (%50 İndirimli)",
"Siyaset Bilimi ve Kamu Yönetimi (Burslu)",
"Siyaset Bilimi ve Kamu Yönetimi (%50 İndirimli)",
"Bilgisayar Mühendisliği (İngilizce) (%25 İndirimli)",
"Elektrik-Elektronik Mühendisliği (İngilizce) (%25 İndirimli)",
"Enerji Sistemleri Mühendisliği (İngilizce) (Burslu)",
"Enerji Sistemleri Mühendisliği (İngilizce) (%50 İndirimli)",
"Kimya Mühendisliği (İngilizce) (Burslu)",
"Kimya Mühendisliği (İngilizce) (%50 İndirimli)",
"Mekatronik Mühendisliği (İngilizce) (Burslu)",
"Mekatronik Mühendisliği (İngilizce) (%50 İndirimli)",
"Metalurji ve Malzeme Mühendisliği (İngilizce) (Burslu)",
"Metalurji ve Malzeme Mühendisliği (İngilizce) (%50 İndirimli)",
"Otomotiv Mühendisliği (İngilizce) (Burslu)",
"Otomotiv Mühendisliği (İngilizce) (%50 İndirimli)",
"Yazılım Mühendisliği (İngilizce) (%25 İndirimli)",
"Beslenme ve Diyetetik (İngilizce) (%50 İndirimli)",
"Çocuk Gelişimi (Burslu)",
"Çocuk Gelişimi (%50 İndirimli)",
"Fizyoterapi ve Rehabilitasyon (İngilizce) (Burslu)",
"Fizyoterapi ve Rehabilitasyon (İngilizce) (%50 İndirimli)",
"Odyoloji (İngilizce) (Burslu)",
"Odyoloji (İngilizce) (%50 İndirimli)",
"Havacılık Yönetimi (İngilizce) (Burslu)",
"Havacılık Yönetimi (İngilizce) (%50 İndirimli)",
"Pilotaj (İngilizce) (Ücretli)",
"Pilotaj (İngilizce) (Burslu)",
"Uçak Elektrik ve Elektroniği (İngilizce) (Burslu)",
"Uçak Elektrik ve Elektroniği (İngilizce) (%25 İndirimli)",
"Uçak Gövde ve Motor Bakımı (İngilizce) (Burslu)",
"Uçak Gövde ve Motor Bakımı (İngilizce) (%25 İndirimli)",
"Tıp (İngilizce) (%25 İndirimli)",
"İngiliz Dili ve Edebiyatı (Burslu)",
"İngiliz Dili ve Edebiyatı (%50 İndirimli)",
"Türk Dili ve Edebiyatı (Burslu)",
"Türk Dili ve Edebiyatı (%50 İndirimli)",
"Görsel İletişim Tasarımı (Burslu)",
"Görsel İletişim Tasarımı (%50 İndirimli)",
"Yeni Medya ve İletişim (Burslu)",
"Yeni Medya ve İletişim (%50 İndirimli)",
"İnşaat Mühendisliği (Burslu)",
"İnşaat Mühendisliği (%50 İndirimli)",
"Ebelik (Ücretli)",
"Hemşirelik (Ücretli)",
"Okul Öncesi Öğretmenliği (İngilizce) (Ücretli)",
"Okul Öncesi Öğretmenliği (İngilizce) (Burslu)",
"Okul Öncesi Öğretmenliği (İngilizce) (%50 İndirimli)",
"Rehberlik ve Psikolojik Danışmanlık (İngilizce) (Burslu)",
"Rehberlik ve Psikolojik Danışmanlık (İngilizce) (%50 İndirimli)",
"Ekonomi (İngilizce) (Ücretli)",
"Lojistik Yönetimi (İngilizce) (Ücretli)",
"Sosyoloji (İngilizce) (Ücretli)",
"Uluslararası Finans (İngilizce) (Ücretli)",
"Uluslararası Finans (İngilizce) (Burslu)",
"Uluslararası Finans (İngilizce) (%50 İndirimli)",
"Uluslararası Ticaret ve İşletmecilik (İngilizce) (Ücretli)",
"Uluslararası Ticaret ve İşletmecilik (İngilizce) (Burslu)",
"Uluslararası Ticaret ve İşletmecilik (İngilizce) (%50 İndirimli)",
"Dijital Oyun Tasarımı (İngilizce) (Ücretli)",
"Dijital Oyun Tasarımı (İngilizce) (Burslu)",
"Dijital Oyun Tasarımı (İngilizce) (%50 İndirimli)",
"Halkla İlişkiler ve Tanıtım (İngilizce) (Ücretli)",
"Halkla İlişkiler ve Tanıtım (İngilizce) (Burslu)",
"Halkla İlişkiler ve Tanıtım (İngilizce) (%50 İndirimli)",
"İletişim ve Tasarımı (İngilizce) (Ücretli)",
"Reklamcılık (İngilizce) (Ücretli)",
"Reklamcılık (İngilizce) (Burslu)",
"Reklamcılık (İngilizce) (%50 İndirimli)",
"Sinema ve Televizyon (İngilizce) (Ücretli)",
"Sinema ve Televizyon (İngilizce) (Burslu)",
"Sinema ve Televizyon (İngilizce) (%50 İndirimli)",
"Yeni Medya (İngilizce) (Ücretli)",
"Yeni Medya (İngilizce) (Burslu)",
"Yeni Medya (İngilizce) (%50 İndirimli)",
"Endüstriyel Tasarım (İngilizce) (Ücretli)",
"Endüstriyel Tasarım (İngilizce) (Burslu)",
"Endüstriyel Tasarım (İngilizce) (%50 İndirimli)",
"İç Mimarlık ve Çevre Tasarımı (İngilizce) (%25 İndirimli)",
"Biyomedikal Mühendisliği (İngilizce) (Ücretli)",
"Enerji Sistemleri Mühendisliği (İngilizce) (Ücretli)",
"İşletme Mühendisliği (İngilizce) (Ücretli)",
"İşletme Mühendisliği (İngilizce) (Burslu)",
"İşletme Mühendisliği (İngilizce) (%50 İndirimli)",
"Mekatronik Mühendisliği (İngilizce) (Ücretli)",
"Moleküler Biyoloji ve Genetik (İngilizce) (Ücretli)",
"Yapay Zeka Mühendisliği (İngilizce) (Ücretli)",
"Yapay Zeka Mühendisliği (İngilizce) (Burslu)",
"Yapay Zeka Mühendisliği (İngilizce) (%50 İndirimli)",
"Beslenme ve Diyetetik (Ücretli)",
"Beslenme ve Diyetetik (İngilizce) (Ücretli)",
"Dil ve Konuşma Terapisi (Ücretli)",
"Gastronomi ve Mutfak Sanatları (İngilizce) (Ücretli)",
"İlköğretim Matematik Öğretmenliği (Ücretli)",
"İlköğretim Matematik Öğretmenliği (Burslu)",
"İlköğretim Matematik Öğretmenliği (%50 İndirimli)",
"Okul Öncesi Öğretmenliği (Ücretli)",
"Okul Öncesi Öğretmenliği (Burslu)",
"Okul Öncesi Öğretmenliği (%50 İndirimli)",
"Sınıf Öğretmenliği (Ücretli)",
"Sınıf Öğretmenliği (Burslu)",
"Sınıf Öğretmenliği (%50 İndirimli)",
"Türkçe Öğretmenliği (Ücretli)",
"Türkçe Öğretmenliği (Burslu)",
"Türkçe Öğretmenliği (%50 İndirimli)",
"Amerikan Kültürü ve Edebiyatı (Ücretli)",
"Amerikan Kültürü ve Edebiyatı (Burslu)",
"Amerikan Kültürü ve Edebiyatı (%50 İndirimli)",
"Moleküler Biyoloji ve Genetik (Ücretli)",
"Moleküler Biyoloji ve Genetik (Burslu)",
"Moleküler Biyoloji ve Genetik (%50 İndirimli)",
"Türk Dili ve Edebiyatı (Ücretli)",
"Çizgi Film ve Animasyon (Ücretli)",
"Film Tasarımı ve Yönetimi (Ücretli)",
"Film Tasarımı ve Yönetimi (Burslu)",
"Film Tasarımı ve Yönetimi (%50 İndirimli)",
"Gastronomi ve Mutfak Sanatları (%25 İndirimli)",
"Görsel İletişim Tasarımı (Ücretli)",
"Mimarlık (Ücretli)",
"İktisat (Ücretli)",
"İşletme (Ücretli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (Ücretli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (Burslu)",
"Siyaset Bilimi ve Uluslararası İlişkiler (%50 İndirimli)",
"Teknoloji ve Bilgi Yönetimi (Ücretli)",
"Teknoloji ve Bilgi Yönetimi (Burslu)",
"Teknoloji ve Bilgi Yönetimi (%50 İndirimli)",
"Halkla İlişkiler ve Tanıtım (Ücretli)",
"Halkla İlişkiler ve Tanıtım (Burslu)",
"Halkla İlişkiler ve Tanıtım (%50 İndirimli)",
"İletişim ve Tasarımı (Ücretli)",
"Radyo, Televizyon ve Sinema (Ücretli)",
"Bilgisayar Mühendisliği (%25 İndirimli)",
"Biyomedikal Mühendisliği (Burslu)",
"Biyomedikal Mühendisliği (%50 İndirimli)",
"Elektrik-Elektronik Mühendisliği (Ücretli)",
"Elektrik-Elektronik Mühendisliği (Burslu)",
"Elektrik-Elektronik Mühendisliği (%25 İndirimli)",
"Endüstri Mühendisliği (Ücretli)",
"Endüstri Mühendisliği (Burslu)",
"Endüstri Mühendisliği (%50 İndirimli)",
"Makine Mühendisliği (Ücretli)",
"Makine Mühendisliği (Burslu)",
"Makine Mühendisliği (%50 İndirimli)",
"Fizyoterapi ve Rehabilitasyon (Ücretli)",
"Odyoloji (Ücretli)",
"Sosyal Hizmet (Ücretli)",
"Sosyal Hizmet (Burslu)",
"Sosyal Hizmet (%50 İndirimli)",
"Muhasebe ve Finans Yönetimi (Burslu)",
"Muhasebe ve Finans Yönetimi (%50 İndirimli)",
"Sigortacılık (Burslu)",
"Sigortacılık (%50 İndirimli)",
"Turizm İşletmeciliği (Burslu)",
"Turizm İşletmeciliği (%50 İndirimli)",
"Uluslararası Finans ve Bankacılık (Burslu)",
"Uluslararası Finans ve Bankacılık (%50 İndirimli)",
"Uluslararası Ticaret ve Finansman (Ücretli)",
"Dijital Oyun Tasarımı (Burslu)",
"Dijital Oyun Tasarımı (%50 İndirimli)",
"Eczacılık (%25 İndirimli)",
"Sağlık Yönetimi (Ücretli)",
"Diş Hekimliği (İngilizce) (%25 İndirimli)",
"Elektrik-Elektronik Mühendisliği (%50 İndirimli)",
"Dil ve Konuşma Terapisi (İngilizce) (Burslu)",
"Dil ve Konuşma Terapisi (İngilizce) (%50 İndirimli)",
"Tıp (%25 İndirimli)",
"Uluslararası Finans ve Bankacılık (İngilizce) (Burslu)",
"Uluslararası Finans ve Bankacılık (İngilizce) (%50 İndirimli)",
"Uluslararası İşletme Yönetimi (İngilizce) (Burslu)",
"Uluslararası İşletme Yönetimi (İngilizce) (%50 İndirimli)",
"Şehir ve Bölge Planlama (İngilizce) (Burslu)",
"Şehir ve Bölge Planlama (İngilizce) (%50 İndirimli)",
"İletişim Bilimleri (Burslu)",
"İletişim Bilimleri (%50 İndirimli)",
"Grafik (Burslu)",
"Grafik (%50 İndirimli)",
"Grafik (İngilizce) (Burslu)",
"Grafik (İngilizce) (%50 İndirimli)",
"İç Mimarlık (Burslu)",
"İç Mimarlık (%50 İndirimli)",
"Arapça Öğretmenliği (Burslu)",
"Arapça Öğretmenliği (%50 İndirimli)",
"Bilim Tarihi (Ücretli)",
"Bilim Tarihi (Burslu)",
"Bilim Tarihi (%50 İndirimli)",
"Tarih (Burslu)",
"Tarih (%50 İndirimli)",
"İslami İlimler (Arapça) (Burslu)",
"İslami İlimler (Arapça) (%50 İndirimli)",
"Kültür Varlıklarını Koruma ve Onarım (Burslu)",
"Kültür Varlıklarını Koruma ve Onarım (%50 İndirimli)",
"Matematik (Burslu)",
"Matematik (%50 İndirimli)",
"İngilizce Öğretmenliği (İngilizce) (%25 İndirimli)",
"Okul Öncesi Öğretmenliği (%25 İndirimli)",
"Özel Eğitim Öğretmenliği (%25 İndirimli)",
"Rehberlik ve Psikolojik Danışmanlık (%25 İndirimli)",
"Sınıf Öğretmenliği (%25 İndirimli)",
"Uluslararası Ticaret ve Lojistik (Ücretli)",
"Uluslararası Ticaret ve Lojistik (Burslu)",
"Uluslararası Ticaret ve Lojistik (%50 İndirimli)",
"Dijital Oyun Tasarımı (Ücretli)",
"Endüstriyel Tasarım (Ücretli)",
"Sinema ve Televizyon (Ücretli)",
"Sinema ve Televizyon (Burslu)",
"Sinema ve Televizyon (%50 İndirimli)",
"Tarih (İngilizce) (Burslu)",
"İslami İlimler (Burslu)",
"Fizik (İngilizce) (Ücretli)",
"Fizik (İngilizce) (Burslu)",
"Fizik (İngilizce) (%50 İndirimli)",
"Kimya (İngilizce) (Ücretli)",
"Kimya (İngilizce) (Burslu)",
"Kimya (İngilizce) (%50 İndirimli)",
"Matematik (İngilizce) (Ücretli)",
"Grafik Tasarımı (İngilizce) (Ücretli)",
"Grafik Tasarımı (İngilizce) (Burslu)",
"Grafik Tasarımı (İngilizce) (%50 İndirimli)",
"Kentsel Tasarım ve Peyzaj Mimarlığı (İngilizce) (Burslu)",
"Kentsel Tasarım ve Peyzaj Mimarlığı (İngilizce) (%50 İndirimli)",
"Siyaset Bilimi ve Kamu Yönetimi (İngilizce) (Ücretli)",
"Uluslararası İlişkiler (İngilizce) (Ücretli)",
"Amerikan Kültürü ve Edebiyatı (İngilizce) (Burslu)",
"Amerikan Kültürü ve Edebiyatı (İngilizce) (%50 İndirimli)",
"Arkeoloji (İngilizce) (Burslu)",
"Arkeoloji (İngilizce) (%50 İndirimli)",
"Felsefe (İngilizce) (Burslu)",
"İngilizce, Fransızca Mütercim ve Tercümanlık (Ücretli)",
"İngilizce, Fransızca Mütercim ve Tercümanlık (Burslu)",
"İngilizce, Fransızca Mütercim ve Tercümanlık (%50 İndirimli)",
"Bilişim Sistemleri ve Teknolojileri (İngilizce) (Ücretli)",
"Bilişim Sistemleri ve Teknolojileri (İngilizce) (Burslu)",
"Bilişim Sistemleri ve Teknolojileri (İngilizce) (%50 İndirimli)",
"Turizm ve Otel İşletmeciliği (İngilizce) (Burslu)",
"Turizm ve Otel İşletmeciliği (İngilizce) (%50 İndirimli)",
"Arapça Mütercim ve Tercümanlık (Burslu)",
"Arapça Mütercim ve Tercümanlık (%50 İndirimli)",
"Bilgi ve Belge Yönetimi (Burslu)",
"Bilgi ve Belge Yönetimi (%50 İndirimli)",
"Felsefe (Burslu)",
"Felsefe (%50 İndirimli)",
"İlahiyat (%50 İndirimli)",
"Finans ve Bankacılık (Ücretli)",
"Finans ve Bankacılık (Burslu)",
"Finans ve Bankacılık (%50 İndirimli)",
"Havacılık Yönetimi (Ücretli)",
"Havacılık Yönetimi (Burslu)",
"Havacılık Yönetimi (%50 İndirimli)",
"Lojistik Yönetimi (Burslu)",
"Lojistik Yönetimi (%50 İndirimli)",
"Uluslararası Ticaret ve Finansman (İngilizce) (Ücretli)",
"Gazetecilik (Burslu)",
"Gazetecilik (%50 İndirimli)",
"Halkla İlişkiler ve Reklamcılık (Ücretli)",
"Yeni Medya ve İletişim (Ücretli)",
"İç Mimarlık (Ücretli)",
"Çocuk Gelişimi (Ücretli)",
"Hemşirelik (%25 İndirimli)",
"Ekonomi ve Finans (Burslu)",
"Ekonomi ve Finans (%50 İndirimli)",
"Kamu Yönetimi (Burslu)",
"Kamu Yönetimi (%50 İndirimli)",
"Reklamcılık (Burslu)",
"Reklamcılık (%50 İndirimli)",
"Televizyon Haberciliği ve Programcılığı (Burslu)",
"Televizyon Haberciliği ve Programcılığı (%50 İndirimli)",
"Gıda Mühendisliği (Burslu)",
"Gıda Mühendisliği (%50 İndirimli)",
"Yazılım Geliştirme (İngilizce) (Burslu)",
"Yazılım Geliştirme (İngilizce) (%50 İndirimli)",
"Rusça Mütercim ve Tercümanlık (Burslu)",
"Rusça Mütercim ve Tercümanlık (%50 İndirimli)",
"Radyo, Televizyon ve Sinema (İngilizce) (Burslu)",
"Radyo, Televizyon ve Sinema (İngilizce) (%50 İndirimli)",
"Finans ve Bankacılık (İngilizce) (Burslu)",
"Finans ve Bankacılık (İngilizce) (%50 İndirimli)",
"Sermaye Piyasası (Burslu)",
"Sermaye Piyasası (%50 İndirimli)",
"Medya ve İletişim (Burslu)",
"Medya ve İletişim (%50 İndirimli)",
"Yeni Medya (Burslu)",
"Yeni Medya (%50 İndirimli)",
"Kimya Mühendisliği (Burslu)",
"Kimya Mühendisliği (%50 İndirimli)",
"Görsel İletişim Tasarımı (İngilizce) (Ücretli)",
"Görsel İletişim Tasarımı (İngilizce) (Burslu)",
"Görsel İletişim Tasarımı (İngilizce) (%50 İndirimli)",
"İletişim Tasarımı ve Yönetimi (İngilizce) (Burslu)",
"İletişim Tasarımı ve Yönetimi (İngilizce) (%50 İndirimli)",
"Radyo, Televizyon ve Sinema (İngilizce) (Ücretli)",
"Sanat ve Kültür Yönetimi (İngilizce) (Burslu)",
"Sanat ve Kültür Yönetimi (İngilizce) (%50 İndirimli)",
"Televizyon Haberciliği ve Programcılığı (İngilizce) (Burslu)",
"Televizyon Haberciliği ve Programcılığı (İngilizce) (%50 İndirimli)",
"Pazarlama (İngilizce) (Ücretli)",
"Pazarlama (İngilizce) (Burslu)",
"Pazarlama (İngilizce) (%50 İndirimli)",
"Genetik ve Biyomühendislik (İngilizce) (Burslu)",
"Genetik ve Biyomühendislik (İngilizce) (%50 İndirimli)",
"Karşılaştırmalı Edebiyat (İngilizce) (Burslu)",
"Karşılaştırmalı Edebiyat (İngilizce) (%50 İndirimli)",
"Tarih (İngilizce) (%50 İndirimli)",
"Elektronik Ticaret ve Yönetimi (Ücretli)",
"Elektronik Ticaret ve Yönetimi (Burslu)",
"Elektronik Ticaret ve Yönetimi (%50 İndirimli)",
"Metalurji ve Malzeme Mühendisliği (Burslu)",
"Metalurji ve Malzeme Mühendisliği (%50 İndirimli)",
"Turizm Rehberliği (Burslu)",
"Turizm Rehberliği (%50 İndirimli)",
"Mekatronik Mühendisliği (Burslu)",
"Mekatronik Mühendisliği (%50 İndirimli)",
"Uçak Mühendisliği (Ücretli)",
"Uçak Mühendisliği (Burslu)",
"Uçak Mühendisliği (%25 İndirimli)",
"Uçak Mühendisliği (İngilizce) (Ücretli)",
"Uçak Mühendisliği (İngilizce) (Burslu)",
"Uçak Mühendisliği (İngilizce) (%25 İndirimli)",
"Yazılım Mühendisliği (%25 İndirimli)",
"Çocuk Gelişimi (İngilizce) (Burslu)",
"Çocuk Gelişimi (İngilizce) (%50 İndirimli)",
"Perfüzyon (Burslu)",
"Perfüzyon (%50 İndirimli)",
"Bankacılık ve Sigortacılık (Burslu)",
"Bankacılık ve Sigortacılık (%50 İndirimli)",
"Havacılık Elektrik ve Elektroniği (Burslu)",
"Havacılık Elektrik ve Elektroniği (%50 İndirimli)",
"Uçak Bakım ve Onarım (Burslu)",
"Uçak Bakım ve Onarım (%50 İndirimli)",
"Siyaset Bilimi ve Kamu Yönetimi (Ücretli)",
"Fizyoterapi ve Rehabilitasyon (İngilizce) (Ücretli)",
"Kentsel Tasarım ve Peyzaj Mimarlığı (Ücretli)",
"Kentsel Tasarım ve Peyzaj Mimarlığı (Burslu)",
"Kentsel Tasarım ve Peyzaj Mimarlığı (%50 İndirimli)",
"Matematik ve Bilgisayar Bilimleri (Ücretli)",
"Matematik ve Bilgisayar Bilimleri (Burslu)",
"Matematik ve Bilgisayar Bilimleri (%50 İndirimli)",
"İletişim Sanatları (Ücretli)",
"İletişim Sanatları (Burslu)",
"İletişim Sanatları (%50 İndirimli)",
"Medya ve Görsel Sanatlar (Burslu)",
"Medya ve Görsel Sanatlar (%50 İndirimli)",
"İnsan Kaynakları Yönetimi (Burslu)",
"İnsan Kaynakları Yönetimi (%50 İndirimli)",
"Ortez ve Protez (Burslu)",
"Ortez ve Protez (%50 İndirimli)",
"Sağlık Yönetimi (İngilizce) (Burslu)",
"Sağlık Yönetimi (İngilizce) (%50 İndirimli)",
"Havacılık Yönetimi (İngilizce) (Ücretli)",
"İngiliz Dili ve Edebiyatı (İngilizce) (Ücretli)",
"Sosyoloji (Ücretli)",
"Yeni Medya ve İletişim (İngilizce) (Ücretli)",
"Havacılık Elektrik ve Elektroniği (Ücretli)",
"Uçak Bakım ve Onarım (Ücretli)",
"Turizm Rehberliği (Ücretli)",
"Uluslararası Ticaret ve İşletmecilik (Ücretli)",
"Çince Mütercim ve Tercümanlık (Burslu)",
"Çince Mütercim ve Tercümanlık (%50 İndirimli)",
"Uluslararası İlişkiler (Burslu)",
"Uluslararası İlişkiler (%50 İndirimli)",
"Uluslararası Ticaret (Burslu)",
"Uluslararası Ticaret (%50 İndirimli)",
"Uluslararası Ticaret (İngilizce) (Burslu)",
"Uluslararası Ticaret (İngilizce) (%50 İndirimli)",
"Bilişim Sistemleri ve Teknolojileri (Burslu)",
"Bilişim Sistemleri ve Teknolojileri (%50 İndirimli)",
"İngilizce Öğretmenliği (%25 İndirimli)",
"Hukuk (%25 İndirimli)",
"İslami İlimler (%50 İndirimli)",
"İslam İktisadı ve Finans (Burslu)",
"İslam İktisadı ve Finans (%50 İndirimli)",
"İslam İktisadı ve Finans (İngilizce) (Burslu)",
"İslam İktisadı ve Finans (İngilizce) (%50 İndirimli)",
"Endüstri Mühendisliği (%25 İndirimli)",
"Endüstriyel Tasarım Mühendisliği (Burslu)",
"İstatistik (Burslu)",
"İstatistik (%50 İndirimli)",
"Uluslararası Ticaret (İngilizce) (Ücretli)",
"Ekonomi (Burslu)",
"Ekonomi (%50 İndirimli)",
"İş Sağlığı ve Güvenliği (Burslu)",
"İş Sağlığı ve Güvenliği (%50 İndirimli)",
"Mimarlık (İngilizce) (%25 İndirimli)",
"Tekstil ve Moda Tasarımı (İngilizce) (Burslu)",
"Tekstil ve Moda Tasarımı (İngilizce) (%50 İndirimli)",
"Sinema ve Dijital Medya (İngilizce) (Burslu)",
"Sinema ve Dijital Medya (İngilizce) (%50 İndirimli)",
"Şehir ve Bölge Planlama (Burslu)",
"Şehir ve Bölge Planlama (%50 İndirimli)",
"Dil ve Konuşma Terapisi (%25 İndirimli)",
"Ebelik (%25 İndirimli)",
"Havacılık Elektrik ve Elektroniği (%25 İndirimli)",
"Uçak Gövde ve Motor Bakımı (Burslu)",
"Uçak Gövde ve Motor Bakımı (%25 İndirimli)",
"Fizyoterapi ve Rehabilitasyon (%25 İndirimli)",
"Arkeoloji ve Sanat Tarihi (İngilizce) (Burslu)",
"Arkeoloji ve Sanat Tarihi (İngilizce) (%50 İndirimli)",
"Felsefe (İngilizce) (%50 İndirimli)",
"Karşılaştırmalı Edebiyat (İngilizce) (Ücretli)",
"Medya ve Görsel Sanatlar (İngilizce) (Ücretli)",
"Medya ve Görsel Sanatlar (İngilizce) (Burslu)",
"Kimya-Biyoloji Mühendisliği (İngilizce) (Ücretli)",
"Kimya-Biyoloji Mühendisliği (İngilizce) (Burslu)",
"Kimya-Biyoloji Mühendisliği (İngilizce) (%50 İndirimli)",
"Bitkisel Üretim ve Teknolojileri (İngilizce) (Burslu)",
"Geleneksel Türk Sanatları (Burslu)",
"Geleneksel Türk Sanatları (%50 İndirimli)",
"Grafik Tasarımı (Ücretli)",
"Enerji Yönetimi (Ücretli)",
"Enerji Yönetimi (Burslu)",
"Enerji Yönetimi (%50 İndirimli)",
"İslam İktisadı ve Finans (Ücretli)",
"Sigortacılık ve Sosyal Güvenlik (Ücretli)",
"Sigortacılık ve Sosyal Güvenlik (Burslu)",
"Sigortacılık ve Sosyal Güvenlik (%50 İndirimli)",
"Tarih (Ücretli)",
"Arapça Mütercim ve Tercümanlık (Ücretli)",
"İlköğretim Matematik Öğretmenliği (%25 İndirimli)",
"Gazetecilik (Ücretli)",
"Gazetecilik (%25 İndirimli)",
"Görsel İletişim Tasarımı (%25 İndirimli)",
"Halkla İlişkiler ve Tanıtım (%25 İndirimli)",
"Halkla İlişkiler ve Tanıtım (İngilizce) (%25 İndirimli)",
"Radyo, Televizyon ve Sinema (%25 İndirimli)",
"Sosyal Hizmet (%25 İndirimli)",
"Sosyoloji (%25 İndirimli)",
"İşletme (%25 İndirimli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (%25 İndirimli)",
"Uluslararası Ticaret ve Lojistik (%25 İndirimli)",
"Uluslararası Ticaret ve Lojistik (İngilizce) (%25 İndirimli)",
"Gemi ve Yat Tasarımı (Ücretli)",
"Gemi ve Yat Tasarımı (Burslu)",
"Gemi ve Yat Tasarımı (%25 İndirimli)",
"İç Mimarlık (%25 İndirimli)",
"Endüstri Mühendisliği (İngilizce) (%25 İndirimli)",
"İlköğretim Matematik Öğretmenliği (İngilizce) (Burslu)",
"İlköğretim Matematik Öğretmenliği (İngilizce) (%50 İndirimli)",
"Malzeme Bilimi ve Mühendisliği (İngilizce) (Burslu)",
"Malzeme Bilimi ve Mühendisliği (İngilizce) (%50 İndirimli)",
"Yapay Zeka Mühendisliği (Ücretli)",
"Yapay Zeka Mühendisliği (Burslu)",
"Yapay Zeka Mühendisliği (%50 İndirimli)",
"Girişimcilik (İngilizce) (Burslu)",
"Girişimcilik (İngilizce) (%50 İndirimli)",
"Yönetim Bilişim Sistemleri (İngilizce) (%25 İndirimli)",
"Otel Yöneticiliği (İngilizce) (Burslu)",
"Otel Yöneticiliği (İngilizce) (%50 İndirimli)",
"Mühendislik ve Doğa Bilimleri Programları (İngilizce) (Ücretli)",
"Mühendislik ve Doğa Bilimleri Programları (İngilizce) (Burslu)",
"Mühendislik ve Doğa Bilimleri Programları (İngilizce) (%50 İndirimli)",
"Sanat ve Sosyal Bilimler Programları (İngilizce) (Ücretli)",
"Sanat ve Sosyal Bilimler Programları (İngilizce) (Burslu)",
"Sanat ve Sosyal Bilimler Programları (İngilizce) (%50 İndirimli)",
"Yönetim Bilimleri Programları (İngilizce) (Ücretli)",
"Yönetim Bilimleri Programları (İngilizce) (Burslu)",
"Yönetim Bilimleri Programları (İngilizce) (%50 İndirimli)",
"Sınıf Öğretmenliği (İngilizce) (Burslu)",
"Sınıf Öğretmenliği (İngilizce) (%50 İndirimli)",
"İktisadi ve İdari Bilimler Programları (İngilizce) (Burslu)",
"İktisadi ve İdari Bilimler Programları (İngilizce) (%50 İndirimli)",
"Mühendislik Programları (İngilizce) (Ücretli)",
"Mühendislik Programları (İngilizce) (Burslu)",
"Mühendislik Programları (İngilizce) (%25 İndirimli)",
"Uluslararası Girişimcilik (Ücretli)",
"Uluslararası Girişimcilik (Burslu)",
"Uluslararası Girişimcilik (%50 İndirimli)",
"Biyomedikal Mühendisliği (Ücretli)",
"Malzeme Bilimi ve Nanoteknoloji Mühendisliği (Ücretli)",
"Malzeme Bilimi ve Nanoteknoloji Mühendisliği (Burslu)",
"Malzeme Bilimi ve Nanoteknoloji Mühendisliği (%50 İndirimli)",
"Uzay Mühendisliği (İngilizce) (Ücretli)",
"Uzay Mühendisliği (İngilizce) (Burslu)",
"Uzay Mühendisliği (İngilizce) (%50 İndirimli)",
"Adli Bilimler (Burslu)",
"Adli Bilimler (%50 İndirimli)",
"Biyomühendislik (İngilizce) (Burslu)",
"Biyomühendislik (İngilizce) (%50 İndirimli)",
"Halkla İlişkiler ve Reklamcılık (İngilizce) (Ücretli)",
"Tarım Ekonomisi (İngilizce) (Ücretli)",
"Tarım Ekonomisi (İngilizce) (Burslu)",
"Tarım Ekonomisi (İngilizce) (%50 İndirimli)",
"Tarım Makineleri ve Teknolojileri Mühendisliği (İngilizce) (Ücretli)",
"Tarım Makineleri ve Teknolojileri Mühendisliği (İngilizce) (Burslu)",
"Tarım Makineleri ve Teknolojileri Mühendisliği (İngilizce) (%50 İndirimli)",
"Turizm Rehberliği (İngilizce) (Ücretli)",
"Turizm Rehberliği (İngilizce) (Burslu)",
"Turizm Rehberliği (İngilizce) (%50 İndirimli)",
"Bilgi Güvenliği Teknolojisi (İngilizce) (Ücretli)",
"Bilgi Güvenliği Teknolojisi (İngilizce) (Burslu)",
"Bilgi Güvenliği Teknolojisi (İngilizce) (%50 İndirimli)",
"Yazılım Geliştirme (İngilizce) (Ücretli)",
"Özel Eğitim Öğretmenliği (İngilizce) (Burslu)",
"Özel Eğitim Öğretmenliği (İngilizce) (%50 İndirimli)",
"Antropoloji (İngilizce) (Burslu)",
"Antropoloji (İngilizce) (%50 İndirimli)",
"Çeviribilimi (İngilizce) (Ücretli)",
"Çeviribilimi (İngilizce) (Burslu)",
"Çeviribilimi (İngilizce) (%50 İndirimli)",
"Fizik (İngilizce) (UOLP-Coe College) (Ücretli)",
"Rus Dili ve Edebiyatı (Rusça) (Burslu)",
"Rus Dili ve Edebiyatı (Rusça) (%50 İndirimli)",
"Ekonomi (İngilizce) (UOLP-Batı Kanada Huron Üniversitesi) (Ücretli)",
"Elektronik Ticaret ve Yönetimi (İngilizce) (Ücretli)",
"Elektronik Ticaret ve Yönetimi (İngilizce) (Burslu)",
"Elektronik Ticaret ve Yönetimi (İngilizce) (%50 İndirimli)",
"İşletme (İngilizce) (UOLP- North Carolina Wilmington Üniversitesi (UNCW)) (Ücretli)",
"Kamu Yönetimi (İngilizce) (Burslu)",
"Kamu Yönetimi (İngilizce) (%50 İndirimli)",
"Siyaset Bilimi ve Uluslararası İlişkiler (Fransızca) (Burslu)",
"Siyaset Bilimi ve Uluslararası İlişkiler (Fransızca) (%50 İndirimli)",
"Tarım Ticareti ve İşletmeciliği (İngilizce) (Burslu)",
"Tarım Ticareti ve İşletmeciliği (İngilizce) (%50 İndirimli)",
"Uluslararası İşletme Yönetimi (Almanca) (Burslu)",
"Uluslararası İşletme Yönetimi (Almanca) (%50 İndirimli)",
"Uluslararası İşletme Yönetimi (Almanca) (UOLP-FH KIEL Uygulamalı Bilimler Üniversitesi) (Ücretli)",
"Gazetecilik (İngilizce) (Burslu)",
"Gazetecilik (İngilizce) (%50 İndirimli)",
"Reklam Tasarımı ve İletişimi (İngilizce) (Ücretli)",
"Reklam Tasarımı ve İletişimi (İngilizce) (Burslu)",
"Reklam Tasarımı ve İletişimi (İngilizce) (%50 İndirimli)",
"Genetik ve Biyomühendislik (İngilizce) (Ücretli)",
"Gıda Mühendisliği (İngilizce) (Burslu)",
"Gıda Mühendisliği (İngilizce) (%50 İndirimli)",
"Malzeme Bilimi ve Nanoteknoloji Mühendisliği (İngilizce) (Burslu)",
"Malzeme Bilimi ve Nanoteknoloji Mühendisliği (İngilizce) (%50 İndirimli)",
"Hemşirelik (İngilizce) (Ücretli)",
"Arkeoloji (İngilizce) (Ücretli)",
"Film Tasarımı ve Yönetimi (İngilizce) (Ücretli)",
"Kentsel Tasarım ve Peyzaj Mimarlığı (İngilizce) (Ücretli)",
"Bilişim Sistemleri ve Teknolojileri (Ücretli)",
"Diş Hekimliği (UOLP-Sağlık Bilimleri Üniversitesi) (Ücretli)",
"Diş Hekimliği (UOLP-Sağlık Bilimleri Üniversitesi) (Burslu)",
"Diş Hekimliği (UOLP-Sağlık Bilimleri Üniversitesi) (%50 İndirimli)",
"Bilgisayar ve Öğretim Teknolojileri Öğretmenliği (İngilizce) (Burslu)",
"İngilizce Öğretmenliği-Elt (İngilizce) (Ücretli)",
"İngilizce Öğretmenliği-Elt (İngilizce) (Burslu)",
"İngilizce Öğretmenliği-Elt (İngilizce) (%50 İndirimli)",
"Aktüerya Bilimleri (İngilizce) (Burslu)",
"İstatistik ve Bilgisayar Bilimleri (İngilizce) (Ücretli)",
"İstatistik ve Bilgisayar Bilimleri (İngilizce) (Burslu)",
"İstatistik ve Bilgisayar Bilimleri (İngilizce) (%50 İndirimli)",
"Matematik ve Bilgisayar Bilimleri (İngilizce) (Ücretli)",
"Matematik ve Bilgisayar Bilimleri (İngilizce) (Burslu)",
"Matematik ve Bilgisayar Bilimleri (İngilizce) (%50 İndirimli)",
"Bankacılık ve Sigortacılık (İngilizce) (Burslu)",
"İnsan Kaynakları Yönetimi (İngilizce) (Burslu)",
"Kamu Yönetimi (İngilizce) (Ücretli)",
"Muhasebe ve Finans Yönetimi (İngilizce) (Burslu)",
"Siyaset Bilimi (İngilizce) (Burslu)",
"Uluslararası Finans ve Bankacılık (İngilizce) (Ücretli)",
"Elektronik ve Haberleşme Mühendisliği (İngilizce) (Burslu)",
"Egzersiz ve Spor Bilimleri (Ücretli)",
"Egzersiz ve Spor Bilimleri (Burslu)",
"Egzersiz ve Spor Bilimleri (%50 İndirimli)",
"Tıp (İngilizce) (UOLP-Marmara Üniversitesi) (Ücretli)",
"Tıp (İngilizce) (UOLP-Marmara Üniversitesi) (Burslu)",
"Tıp (İngilizce) (UOLP-Marmara Üniversitesi) (%50 İndirimli)",
"Turizm İşletmeciliği (İngilizce) (Ücretli)",
"Amerikan Kültürü ve Edebiyatı (İngilizce) (Ücretli)",
"Çin Dili ve Edebiyatı (Ücretli)",
"Çin Dili ve Edebiyatı (Burslu)",
"Çin Dili ve Edebiyatı (%50 İndirimli)",
"Basın ve Yayın (Ücretli)",
"Basın ve Yayın (Burslu)",
"Basın ve Yayın (%50 İndirimli)",
"İnsan Kaynakları Yönetimi (İngilizce) (Ücretli)",
"İnsan Kaynakları Yönetimi (İngilizce) (%50 İndirimli)",
"Sigortacılık (İngilizce) (Ücretli)",
"Sigortacılık (İngilizce) (Burslu)",
"Sigortacılık (İngilizce) (%50 İndirimli)",
"Uluslararası İşletme Yönetimi (İngilizce) (Ücretli)",
"Acil Yardım ve Afet Yönetimi (İngilizce) (Ücretli)",
"Acil Yardım ve Afet Yönetimi (İngilizce) (Burslu)",
"Acil Yardım ve Afet Yönetimi (İngilizce) (%50 İndirimli)",
"Ergoterapi (İngilizce) (Ücretli)",
"Ergoterapi (İngilizce) (Burslu)",
"Ergoterapi (İngilizce) (%50 İndirimli)",
"Sağlık Yönetimi (İngilizce) (Ücretli)",
"Finans ve Bankacılık (İngilizce) (Ücretli)",
"Muhasebe ve Finans Yönetimi (İngilizce) (Ücretli)",
"Muhasebe ve Finans Yönetimi (İngilizce) (%50 İndirimli)",
"Balıkçılık Teknolojisi Mühendisliği (İngilizce) (Burslu)",
"Uçak Mühendisliği (İngilizce) (%50 İndirimli)",
"Bilişim Sistemleri Mühendisliği (Ücretli)",
"Bilişim Sistemleri Mühendisliği (Burslu)",
"Bilişim Sistemleri Mühendisliği (%50 İndirimli)",
"Elektronik ve Haberleşme Mühendisliği (İngilizce) (%50 İndirimli)",
"Bahçe Bitkileri (İngilizce) (Burslu)",
"Bahçe Bitkileri (İngilizce) (%50 İndirimli)",
"Çevre Mühendisliği (İngilizce) (Burslu)",
"Petrol ve Doğalgaz Mühendisliği (İngilizce) (Burslu)",
"Sosyal Hizmet (İngilizce) (Burslu)",
"Bitkisel Üretim ve Teknolojileri (İngilizce) (%50 İndirimli)",
"Sosyal Bilgiler Öğretmenliği (Burslu)",
"Sosyal Bilgiler Öğretmenliği (%50 İndirimli)",
"Çevre Mühendisliği (İngilizce) (%50 İndirimli)",
"Petrol ve Doğalgaz Mühendisliği (İngilizce) (%50 İndirimli)",
"Veteriner (Burslu)",
"Veteriner (%50 İndirimli)",
"Veteriner (İngilizce) (Burslu)",
"Veteriner (İngilizce) (%50 İndirimli)",
"Peyzaj Mimarlığı (İngilizce) (Burslu)",
"Peyzaj Mimarlığı (İngilizce) (%50 İndirimli)",
"Psikolojik Danışmanlık ve Rehberlik Öğretmenliği (Ücretli)",
"Uluslararası İlişkiler (Ücretli)",
"Tıp Fakültesi (Ücretli)",
"İktisat (%25 İndirimli)",
"Uluslararası Ticaret ve Finans (Burslu)",
"Uluslararası Ticaret ve Finans (%50 İndirimli)",
"Uluslararası Ticaret ve Finans(%25 İndirimli)",
"Mimarlık (%25 İndirimli)",
"Görsel Sanatlar ve İletişim Tasarımı (Burslu)",
"Görsel Sanatlar ve İletişim Tasarımı (%50 İndirimli)",
"Görsel Sanatlar ve İletişim Tasarımı(%25 İndirimli)",
"İngiliz Dili ve Edebiyatı (%25 İndirimli)",
"Psikolojik Danışmanlık ve Rehberlik (Ücretli)",
"Psikolojik Danışmanlık ve Rehberlik (%50 İndirimli)",
"Psikolojik Danışmanlık ve Rehberlik (%25 İndirimli)",
"Türkçe Öğretmenliği (%25 İndirimli)",
"Siyasal Bilimler (Ücretli)",
"Siyasal Bilimler (%50 İndirimli)",
"Siyasal Bilimler (%25 İndirimli)",
"Bankacılık ve Finans (Ücretli)",
"Bankacılık ve Finans (%50 İndirimli)",
"Bankacılık ve Finans (%25 İndirimli)",
"Uluslararası Ekonomi (Ücretli)",
"Uluslararası Ekonomi (%50 İndirimli)",
"Uluslararası Ekonomi (%25 İndirimli)",
"Halkla İlişkiler ve Reklamcılık (%25 İndirimli)",
"Yeni Medya ve İletişim (%25 İndirimli)",
"Görsel Sanatlar (Ücretli)",
"Görsel Sanatlar (%50 İndirimli)",
"Görsel Sanatlar (%25 İndirimli)",
"Grafik Tasarımı (%25 İndirimli)"
];
const NoDepartments = [
    "Acil Yardım ve Afet Yönetimi",
"Acil Yardım ve Afet Yönetimi (İngilizce)",
"Adli Bilişim Mühendisliği",
"Ağaç İşleri Endüstri Mühendisliği",
"Aile ve Tüketici Bilimleri",
"Aktüerya",
"Aktüerya Bilimleri",
"Aktüerya ve Risk Yönetimi",
"Alman Dili ve Edebiyatı",
"Almanca Öğretmenliği",
"Amerikan Kültürü ve Edebiyatı",
"Amerikan Kültürü ve Edebiyatı (İngilizce)",
"Animasyon",
"Antropoloji",
"Antropoloji (İngilizce)",
"Arap Dili ve Edebiyatı",
"Arapça Öğretmenliği",
"Arkeoloji",
"Arkeoloji (İngilizce)",
"Arkeoloji ve Sanat Tarihi (İngilizce)",
"Arnavut Dili ve Edebiyatı",
"Astronomi ve Uzay Bilimleri",
"Avrupa Birliği İlişkileri (İngilizce)",
"Azerbaycan Dili ve Edebiyatı",
"Azerbaycan Dili ve Edebiyatı Öğretmenliği",
"Azerbaycan Türkçesi ve Edebiyatı",
"Bahçe Bitkileri",
"Bahçe Bitkileri Üretimi ve Pazarlaması (İngilizce)",
"Bahçe ve Tarla Bitkileri",
"Balıkçılık Teknolojisi",
"Balıkçılık Teknolojisi Mühendisliği (İngilizce)",
"Bankacılık",
"Bankacılık ve Finans",
"Bankacılık ve Finans (İngilizce)",
"Bankacılık ve Finansman (İngilizce)",
"Bankacılık ve Sigortacılık",
"Bankacılık ve Sigortacılık (İngilizce)",
"Basım Teknolojileri",
"Basım Yayın ve Redakte",
"Basın ve Yayın",
"Batı Dilleri (İngiliz Dili ve Edebiyatı)",
"Beslenme ve Diyetetik",
"Beslenme ve Diyetetik (İngilizce)",
"Bilgi ve Belge Yönetimi",
"Bilim Tarihi",
"Bitki Koruma",
"Bitkisel Üretim ve Teknolojileri (İngilizce)",
"Biyoenformatik ve Genetik (İngilizce)",
"Biyokimya",
"Biyoloji",
"Biyoloji (İngilizce)",
"Biyoloji (İngilizce) (UOLP-ABD State University of New York University at Albany)",
"Biyoloji Öğretmenliği",
"Biyoteknoloji ve Moleküler Biyoloji",
"Boşnak Dili ve Edebiyatı",
"Bulgar Dili ve Edebiyatı",
"Canlandırma Filmi Tasarım ve Yönetimi",
"Cerrahpaşa Tıp",
"Cerrahpaşa Tıp (İngilizce)",
"Cevher Hazırlama Mühendisliği",
"Coğrafya",
"Coğrafya (Açıköğretim)",
"Coğrafya (İngilizce)",
"Coğrafya Öğretmenliği",
"Çağdaş Türk Lehçeleri ve Edebiyatları",
"Çağdaş Yunan Dili ve Edebiyatı",
"Çerkez Dili ve Edebiyatı",
"Çeviribilim (Almanca)",
"Çeviribilim (İngilizce)",
"Çin Dili ve Edebiyatı",
"Çocuk Gelişimi",
"Çocuk Gelişimi %25 Burslu)",
"Çocuk Gelişimi (İngilizce)",
"Deniz İşletmeciliği ve Yönetimi (İngilizce)",
"Deniz Ulaştırma İşletme Mühendisliği",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (KKTC Vatandaşları)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (UOLP-Maine Maritime Academy)",
"Deniz Ulaştırma İşletme Mühendisliği (İngilizce) (UOLP-SUNY Maritime)",
"Denizcilik İşletmeleri Yönetimi",
"Denizcilik İşletmeleri Yönetimi (İngilizce)",
"Denizcilik İşletmeleri Yönetimi (İngilizce) (UOLP-Maine Maritime Academy)",
"Deri Mühendisliği",
"Dijital Oyun Tasarımı (İngilizce)",
"Dil ve Konuşma Terapisi",
"Dilbilim",
"Dilbilim (İngilizce)",
"Dilbilimi",
"Dini İlimler",
"Doğu Dilleri (Çin Dili ve Edebiyatı)",
"Doğu Dilleri (Rus Dili ve Edebiyatı)",
"Ebelik",
"El Sanatları",
"Ergoterapi",
"Ergoterapi (İngilizce)",
"Ermeni Dili ve Edebiyatı",
"Eski Yunan Dili ve Edebiyatı",
"Eşit Ağırlıklı Programlar",
"Fars Dili ve Edebiyatı",
"Felsefe",
"Felsefe (Açıköğretim)",
"Felsefe (İngilizce)",
"Felsefe Grubu Öğretmenliği",
"Fen Bilgisi Öğretmenliği",
"Fen Bilgisi Öğretmenliği (İngilizce)",
"Film Tasarım ve Yazarlık",
"Film Tasarım ve Yönetmenliği",
"Film Tasarımı",
"Finans ve Bankacılık",
"Fizik",
"Fizik (İngilizce)",
"Fizik Mühendisliği",
"Fizik Mühendisliği (İngilizce)",
"Fizik Öğretmenliği",
"Fizik Öğretmenliği (İngilizce)",
"Fizyoterapi ve Rehabilitasyon",
"Fizyoterapi ve Rehabilitasyon (İngilizce)",
"Fotoğraf ve Video",
"Fransız Dili ve Edebiyatı",
"Fransız Dili ve Edebiyatı (Fransızca)",
"Fransızca Öğretmenliği",
"Fransızca Öğretmenliği (Fransızca)",
"Gastronomi",
"Gastronomi (İngilizce)",
"Gastronomi ve Mutfak Sanatları",
"Gastronomi ve Mutfak Sanatları (İngilizce)",
"Gayrimenkul ve Varlık Değerleme",
"Gazetecilik",
"Gazetecilik (İngilizce)",
"Gazetecilik (Uzaktan Öğretim)",
"Gazetecilik ve Kitle İletişim",
"Geleneksel Türk Sanatları",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (İngilizce)",
"Gemi İnşaatı ve Gemi Makineleri Mühendisliği (İngilizce) (KKTC Vatandaşları)",
"Gemi Makineleri İşletme Mühendisliği",
"Gemi Makineleri İşletme Mühendisliği (İngilizce)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (KKTC Vatandaşları)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (UOLP-Maine Maritime Academy)",
"Gemi Makineleri İşletme Mühendisliği (İngilizce) (UOLP-SUNY Maritime)",
"Gemi ve Deniz Teknolojisi Mühendisliği",
"Gemi ve Yat Tasarımı",
"Genetik ve Biyomühendislik",
"Genetik ve Biyomühendislik (İngilizce)",
"Geomatik Mühendisliği",
"Geomatik Mühendisliği (İngilizce)",
"Gerontoloji",
"Gıda Mühendisliği",
"Gıda Mühendisliği (İngilizce)",
"Gıda Teknolojisi",
"Girişimcilik",
"Girişimcilik (İngilizce)",
"Görme Engelliler Öğretmenliği",
"Görsel İletişim",
"Görsel İletişim Tasarımı",
"Görsel İletişim Tasarımı (İngilizce)",
"Görsel Sanatlar",
"Görsel Sanatlar ve Görsel İletişim Tasarımı (İngilizce)",
"Görsel Sanatlar ve İletişim Tasarımı",
"Grafik Tasarım",
"Grafik Tasarımı",
"Gümrük İşletme",
"Gürcü Dili ve Edebiyatı",
"Güverte (İngilizce)",
"Halkbilim",
"Harita Mühendisliği",
"Harita Mühendisliği (İngilizce)",
"Havacılık Elektrik ve Elektroniği",
"Havacılık İşletmeciliği",
"Havacılık İşletmeciliği (Açıköğretim)",
"Havacılık ve Uzay Mühendisliği (İngilizce)",
"Hayvansal Üretim",
"Hemşirelik",
"Hemşirelik (İngilizce)",
"Hemşirelik ve Sağlık Hizmetleri",
"Hemşirelik Yüksekokulu",
"Hemşirelik Yüksekokulu (Erkek)",
"Hemşirelik Yüksekokulu (Kız)",
"Hidrojeoloji Mühendisliği",
"Hindoloji",
"Hititoloji",
"Hukuk",
"Hungaroloji",
"İbrani Dili ve Edebiyatı",
"İç Mimarlık",
"İç Mimarlık (İngilizce)",
"İç Mimarlık ve Çevre Tasarımı",
"İç Mimarlık ve Çevre Tasarımı (İngilizce)",
"İlahiyat",
"İlahiyat (Arapça)",
"İlahiyat (İngilizce)",
"İlköğretim Matematik Öğretmenliği",
"İlköğretim Matematik Öğretmenliği (İngilizce)",
"İngiliz Dil Bilimi (İngilizce)",
"İngiliz Dili ve Edebiyatı",
"İngiliz Dili ve Edebiyatı (İngilizce)",
"İngiliz Dili ve Karşılaştırmalı Edebiyat (İngilizce)",
"İngiliz ve Rus Dilleri ve Edebiyatları (Öğretmenlik)",
"İngilizce Öğretmenliği",
"İngilizce Öğretmenliği (İngilizce)",
"İngilizce Öğretmenliği (İngilizce) (UOLP-SUNY Cortland)",
"İngilizce Öğretmenliği (İngilizce) (UOLP-SUNY New Paltz)",
"İngilizce Öğretmenliği-ELT (İngilizce)",
"İnsan ve Toplum Bilimleri (İngilizce)",
"İnşaat Mühendisliği",
"İnşaat Mühendisliği (İngilizce)",
"İnşaat Mühendisliği (İngilizce) (UOLP-Leeds Beckett Üniversitesi)",
"İnşaat Mühendisliği (İngilizce) (UOLP-Montana State Üniversitesi)",
"İnşaat Mühendisliği (İngilizce) (UOLP-SUNY Buffalo)",
"İslam Bilimleri",
"İslam ve Din Bilimleri",
"İslami İlimler",
"İslami İlimler (Arapça)",
"İspanyol Dili ve Edebiyatı",
"İstanbul Tıp",
"İşitme Engelliler Öğretmenliği",
"İtalyan Dili ve Edebiyatı",
"Japon Dili ve Edebiyatı",
"Japonca Öğretmenliği",
"Jeofizik Mühendisliği",
"Jeoloji Mühendisliği",
"Jeoloji Mühendisliği (İngilizce)",
"Kanatlı Hayvan Yetiştiriciliği",
"Karşılaştırmalı Edebiyat",
"Karşılaştırmalı Edebiyat (İngilizce)",
"Kazak Dili ve Edebiyatı",
"Kentsel Tasarım ve Peyzaj Mimarisi (İngilizce)",
"Kentsel Tasarım ve Peyzaj Mimarlığı",
"Kimya",
"Kimya (İngilizce)",
"Kimya (İngilizce) (UOLP-ABD State University of New York University at Albany)",
"Kimya Öğretmenliği",
"Kimya Öğretmenliği (İngilizce)",
"Kimya ve Süreç Mühendisliği",
"Klasik Arkeoloji",
"Konaklama İşletmeciliği",
"Konaklama İşletmeciliği (Açıköğretim)",
"Konaklama İşletmeciliği (İngilizce)",
"Konaklama ve Turizm İşletmeciliği (İngilizce)",
"Kore Dili ve Edebiyatı",
"Kurgu-Ses ve Görüntü Yönetimi",
"Kuyumculuk ve Mücevher Tasarımı",
"Kültür Varlıklarını Koruma ve Onarım",
"Küresel Siyaset ve Uluslararası İlişkiler (İngilizce) (UOLP-SUNY Binghamton)",
"Kürt Dili ve Edebiyatı",
"Latin Dili ve Edebiyatı",
"Leh Dili ve Edebiyatı",
"Lif ve Polimer Mühendisliği",
"Maden Mühendisliği",
"Maden Mühendisliği (İngilizce)",
"Matematik",
"Matematik (İngilizce)",
"Matematik Mühendisliği",
"Matematik Mühendisliği (İngilizce)",
"Matematik Öğretmenliği",
"Matematik Öğretmenliği (İngilizce)",
"Matematik ve Bilgisayar Bilimleri",
"Matematik ve Bilgisayar Bilimleri (İngilizce)",
"Meteoroloji Mühendisliği",
"Mimarlık",
"Mimarlık (İngilizce)",
"Mobilya Üretimi ve İç Mekan Tasarımı",
"Moda Tasarımı",
"Moda ve Tekstil Tasarımı",
"Moleküler Biyoloji ve Genetik",
"Moleküler Biyoloji ve Genetik (İngilizce)",
"Mücevherat Mühendisliği (İngilizce)",
"Mütercim-Tercümanlık (Almanca)",
"Mütercim-Tercümanlık (Arapça)",
"Mütercim-Tercümanlık (Bulgarca)",
"Mütercim-Tercümanlık (Çince)",
"Mütercim-Tercümanlık (Farsça)",
"Mütercim-Tercümanlık (Fransızca)",
"Mütercim-Tercümanlık (İng-Fra-Türkçe)",
"Mütercim-Tercümanlık (İngilizce)",
"Mütercim-Tercümanlık (İngilizce, Fransızca, Türkçe)",
"Mütercim-Tercümanlık (Kırgızca-Türkçe)",
"Mütercim-Tercümanlık (Rusça)",
"Mütercim-Tercümanlık (Türkçe/İngilizce/Fransızca)",
"Mütercim-Tercümanlık (Türkçe-Rusça)",
"Müzecilik",
"Nanoteknoloji Mühendisliği (İngilizce)",
"Nükleer Enerji Mühendisliği",
"Nükleer Enerji Mühendisliği (İngilizce)",
"Odyoloji",
"Okul Öncesi Öğretmenliği",
"Okul Öncesi Öğretmenliği (İngilizce)",
"Optik ve Akustik Mühendisliği (İngilizce)",
"Organik Tarım İşletmeciliği",
"Orman Endüstrisi Mühendisliği",
"Orman Mühendisliği",
"Ortez-Protez",
"Otel Yöneticiliği (İngilizce)",
"Otomotiv Mühendisliği",
"Otomotiv Mühendisliği (İngilizce)",
"Öğretmenlik Programları (İngilizce)",
"Özel Eğitim Öğretmenliği",
"Perfüzyon",
"Petrol ve Doğalgaz Mühendisliği",
"Petrol ve Doğalgaz Mühendisliği (İngilizce)",
"Peyzaj Mimarlığı",
"Peyzaj Mimarlığı (İngilizce)",
"Pilot Eğitimi (İngilizce)",
"Pilotaj",
"Pilotaj (İngilizce)",
"Polimer Mühendisliği",
"Polonya Dili ve Kültürü",
"Protohistorya ve Ön Asya Arkeolojisi",
"Psikolojik Danışmanlık ve Rehberlik Öğretmenliği",
"Radyo ve Televizyon (İngilizce)",
"Radyo, Televizyon ve Sinema",
"Radyo, Televizyon ve Sinema (İngilizce)",
"Radyo, Televizyon ve Sinema (Uzaktan Öğretim)",
"Rehberlik ve Psikolojik Danışmanlık",
"Rehberlik ve Psikolojik Danışmanlık (İngilizce)",
"Reklamcılık",
"Reklamcılık (İngilizce)",
"Reklamcılık ve Halkla İlişkiler (İngilizce)",
"Rekreasyon",
"Rekreasyon Yönetimi",
"Restorasyon ve Konservasyon",
"Rus Dili Öğretmenliği",
"Rus Dili ve Edebiyatı",
"Rus Dili ve Edebiyatı (Rusça)",
"Rus Dili ve Edebiyatı Öğretmenliği",
"Rus Dili ve Öğretmenliği",
"Rus ve İngiliz Dilleri ve Edebiyatları (Öğretmenlik)",
"Sağlık İdaresi",
"Sağlık Yönetimi",
"Sağlık Yönetimi (Açıköğretim)",
"Sağlık Yönetimi (İngilizce)",
"Sağlık Yönetimi (Uzaktan Öğretim)",
"Sanat Tarihi",
"Sanat ve Kültür Yönetimi (İngilizce)",
"Sanat ve Sosyal Bilimler Programları (İngilizce)",
"Sanat Yönetimi",
"Sermaye Piyasaları ve Portföy Yönetim i",
"Sermaye Piyasaları ve Portföy Yönetimi",
"Sermaye Piyasası",
"Seyahat İşletmeciliği",
"Seyahat İşletmeciliği ve Turizm Rehberliği",
"Seyahat İşletmeciliği ve Turizm Rehberliği (İngilizce)",
"Sınıf Öğretmenliği",
"Sigortacılık",
"Sigortacılık ve Risk Yönetimi",
"Sigortacılık ve Risk Yönetimi (İngilizce)",
"Sigortacılık ve Sosyal Güvenlik",
"Sinema ve Dijital Medya (İngilizce)",
"Sinema ve Televizyon",
"Sinema ve Televizyon (İngilizce)",
"Sinoloji",
"Sivil Hava Ulaştırma İşletmeciliği",
"Sivil Hava Ulaştırma İşletmeciliği (İngilizce)",
"Sivil Havacılık İşletmeciliği",
"Siyasal Bilimler",
"Sosyal Bilgiler Öğretmenliği",
"Sosyal Hizmet",
"Sosyal Hizmet (Açıköğretim)",
"Sosyal Hizmet (İngilizce)",
"Sosyal ve Siyasal Bilimler",
"Sosyoloji",
"Sosyoloji (Açıköğretim)",
"Sosyoloji (İngilizce)",
"Sosyoloji (İngilizce) (UOLP-ABD State University of New York University at Albany)",
"Spor Yöneticiliği",
"Su Ürünleri Mühendisliği",
"Sümeroloji",
"Süt Teknolojisi",
"Şehir ve Bölge Planlama",
"Şehir ve Bölge Planlama (İngilizce)",
"Takı Tasarımı",
"Tapu Kadastro",
"Tarım Ekonomisi",
"Tarım Makineleri ve Teknolojileri Mühendisliği",
"Tarımsal Biyoteknoloji",
"Tarımsal Genetik Mühendisliği (İngilizce)",
"Tarımsal Yapılar ve Sulama",
"Tarih",
"Tarih (Açıköğretim)",
"Tarih (İngilizce)",
"Tarih Öğretmenliği",
"Tarih Öncesi Arkeolojisi",
"Tarla Bitkileri",
"Teknoloji ve Bilgi Yönetimi",
"Tekstil Mühendisliği",
"Tekstil Mühendisliği (İngilizce)",
"Tekstil Tasarımı",
"Tekstil ve Moda Tasarımı",
"Televizyon Haberciliği ve Programcılığı",
"Televizyon Haberciliği ve Programcılığı (İngilizce)",
"Tıp",
"Tıp (İngilizce)",
"Toprak Bilimi ve Bitki Besleme",
"Turizm İşletmeciliği",
"Turizm İşletmeciliği (İngilizce)",
"Turizm İşletmeciliği ve Otelcilik",
"Turizm Rehberliği",
"Turizm Rehberliği (İngilizce)",
"Turizm ve Otel İşletmeciliği",
"Turizm ve Otel İşletmeciliği (İngilizce)",
"Turizm ve Otel Yöneticiliği",
"Turizm ve Otelcilik",
"Türk Dili ve Edebiyatı",
"Türk Dili ve Edebiyatı (Açıköğretim)",
"Türk Dili ve Edebiyatı (İngilizce)",
"Türk Dili ve Edebiyatı Öğretmenliği",
"Türk Halkbilimi",
"Türk İslam Arkeolojisi",
"Türkçe Öğretmenliği",
"Türkoloji",
"Uçak Elektrik-Elektronik",
"Uçak Elektrik-Elektronik (İngilizce)",
"Uçak Gövde - Motor (İngilizce)",
"Uçak Gövde - Motor Bakım",
"Uçak Gövde-Motor",
"Uçak Gövde-Motor Bakım",
"Uçak Mühendisliği",
"Uçak Mühendisliği (İngilizce)",
"Uçak ve Uzay Mühendisliği",
"Uçak ve Uzay Mühendisliği (İngilizce)",
"Ulaştırma Mühendisliği",
"Urdu Dili ve Edebiyatı",
"Uzay Bilimleri ve Teknolojileri",
"Uzay Mühendisliği (İngilizce)",
"Üstün Zekalılar Öğretmenliği",
"Yaban Hayatı Ekolojisi ve Yönetimi",
"Yeni Medya",
"Yeni Medya (İngilizce)",
"Yeni Medya ve Gazetecilik",
"Yerel Yönetimler",
"Yiyecek ve İçecek İşletmeciliği",
"Yunan Dili ve Edebiyatı",
"Zaza Dili ve Edebiyatı",
"Zihin Engelliler Öğretmenliği",
"Zootekni"
]
function IsValidDept(dept){    
    let valid = true;
    if (dept == "") valid = false;
    if (dept != "" && NoDepartments.find(nd => dept.includes(nd))) valid = false;
    console.log("dept", dept, valid);
    return valid;
}
export {Departments, IsValidDept}