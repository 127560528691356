import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import {Grid} from '@mui/material';
import { Link } from '@mui/material';

export default function Thanks() {
    return(
        <Grid container flexDirection={'column'} xs={4} item paddingX={"2em"} gap={'2em'}>
            <img width={'250em'} src={'/pp_logo.png'} alt={"PeoplePartners"} style={{marginBottom: "1em"}}/> 
            <div>
                <Typography gutterBottom variant='h6'>
                Bilgileriniz güncellendi.
                </Typography>
                <Typography mt={'1.5em'} fontSize={'0.8em'} gutterBottom>
                    PeoplePartners olarak bilgilerinizi güncellediğiniz için teşekkür ederiz. Sizlerle daha önce tanışmış olmanın 
                    bize verdiği memnuniyetle, yeni iş fırsatlarını birlikte değerlendirmek için sabırsızlanıyoruz.                     
                </Typography>
                <Typography mt={'1.5em'} fontSize={'0.8em'} gutterBottom>
                    Size en kısa sürede dönüş yapacak ve ilerleyen adımlar hakkında bilgi vereceğiz.
                </Typography>
                <Grid container>
                    <Typography mt={'1.5em'} fontSize={'0.8em'} mr={'1em'}>
                        İletişim için : 
                    </Typography>
                    <Typography mt={'1.5em'} fontSize={'0.8em'} gutterBottom component={Link} href="mailto:info@peoplepartners-tr.com">
                            info@peoplepartners-tr.com
                    </Typography>
                </Grid>
            </div>
        </Grid>
    )
}